import { select } from '@angular-redux/store'
import { Component, ViewChildren, ViewEncapsulation } from '@angular/core'
import { ENV } from '@app/env'
import firebase from 'firebase/compat/app'
import { AlertController, ModalController } from '@ionic/angular'
import { Observable } from 'rxjs'
import { Subscription } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { QueryList } from '@angular/core'
import moment from 'moment'

import { ConfigureSensorsComponent } from '../../components/configure-sensors/configure-sensors.component'
import { SetPasswordModalComponent } from '../../components/set-password-modal/set-password-modal.component'
import { ReauthModalComponent } from '../../components/reauth-modal/reauth-modal.component'

import { AuthProvider } from '../../services/auth/auth.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { SettingsProvider } from '../../services/settings/settings.service'
import { DeviceProvider } from '../../services/device/device.service'
import { UpdateMateProvider } from '../../services/update-mate/update-mate.service'
import { CloudFunctionsProvider } from '../../services/cloud-functions/cloud-functions.service'

import {
  AppVersion,
  EnvironmentProvider,
} from '../../services/environment/environment.service'
import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'

import { BackendDeviceSettings } from '../../models-shared/backend-device-settings.model'
import { DeviceSettings } from '../../models-shared/device-settings.model'
import { UserSettings } from '../../models-shared/user-settings.model'
import { WirelessSensor } from '../../models-shared/wireless-sensor.model'
import { MateSettings } from '../../models-shared/mate-settings.model'

import { delay, isBlueDevice, unixNow } from '../../util'
import { SensorTypes } from '../../models-shared/sensor-types'
import { SensorUnitOptions } from '../../models-shared/sensor-unit-options.model'
import { SettingsAddDeviceModal } from '../../components/settings-add-device-modal/settings-add-device-modal.component'
import { AddServiceModal } from '../../components/add-service-modal/add-service-modal.component'
import { ChangeServiceModal } from '../../components/change-service-modal/change-service-modal.component'
import { DeviceTransferModal } from '../../components/device-transfer-modal/device-transfer-modal.component'
import { SatModemSettings } from '../../models-shared/satellite-modem-settings.model'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { DeleteAccountModalComponent } from 'app/components/delete-account-modal/delete-account-modal.component'
import { NavDataService } from 'app/services/navigation/navigation.service'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsPage {
  hasChanged: boolean = false
  tab: string = 'sensors' // default tab
  email: string = ''
  vesselName: string
  versionNumber: string
  isProd: boolean = ENV.IS_PROD
  canChangePassword = false
  currentUser: firebase.User
  tabLockout$: Observable<boolean>
  deviceId$: Observable<string>
  backendSettings$: Observable<BackendDeviceSettings>
  email$: Observable<string>
  isMobileDevice: boolean
  isWirelessKeyfobConnected: boolean = false
  sensorTypes = SensorTypes
  mateId$: Observable<string>
  isSatSubscribed: boolean
  isSatSuspended: boolean
  satModemId$: Observable<string>
  satellitePlan: string
  lastSatSignal: string
  serialTestStatus: string
  isIOSDevice: boolean

  // The settings that the user sees and updates
  userSettings: UserSettings
  deviceSettings: DeviceSettings
  mateSettings: MateSettings
  satelliteSettings: SatModemSettings
  backendSettings: BackendDeviceSettings
  userTab: string = 'users-list'
  users: any[]
  user: any
  pendingInvites: any[]
  selectedUser: any
  selectedInvite: any
  inviteEmail: string

  // The most update to date settings from the database
  private dbUserSettings: UserSettings
  private dbDeviceSettings: DeviceSettings
  private dbMateSettings: MateSettings
  private dbSatelliteSettings: SatModemSettings

  private subscriptions: Subscription[] = []

  deviceSettings$: Observable<DeviceSettings>
  satelliteSettings$: Observable<SatModemSettings>

  currentUser$: Observable<firebase.User>

  @select(['currentUserSettings'])
  userSettings$: Observable<UserSettings>
  accountOwner: boolean

  @ViewChildren(ConfigureSensorsComponent)
  configSensors: QueryList<ConfigureSensorsComponent>

  // TODO type this boi
  userProfileEntries: any[] = [
    {
      label: 'First name',
      key: 'firstName',
      type: 'text',
    },
    {
      label: 'Last name',
      key: 'lastName',
      type: 'text',
    },
    {
      label: 'Phone number',
      key: 'phone',
      type: 'tel',
    },
  ]
  unitOptions: SensorUnitOptions = {
    [SensorTypes.Temp]: ['C', 'F'],
  }
  appVersion: string = AppVersion

  subscriptionParams: { deviceId: string; email: string }
  constructor(
    private auth: AuthProvider,
    private settingsProvider: SettingsProvider,
    private alertCtrl: AlertController,
    private helpers: HelpersProvider,
    private modalCtrl: ModalController,
    private environmentProvider: EnvironmentProvider,
    private bluetoothProvider: BluetoothLEProvider,
    private device: DeviceProvider,
    private updateMateProvider: UpdateMateProvider,
    private cloudFunctions: CloudFunctionsProvider,
    private iap: InAppBrowser,
    private navData: NavDataService
  ) {
    this.isMobileDevice = this.environmentProvider.isNativeApp()
    this.deviceId$ = device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.deviceId),
      tap((deviceId) => {
        this.subscriptionParams = {
          deviceId,
          email: '',
        }
      })
    )
    this.mateId$ = device.currentBRNKLandMateId$.pipe(map((ids) => ids.mateId))
    this.satModemId$ = device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.satModemId)
    )
    this.backendSettings$ = settingsProvider.getBackendSettings()
    this.email$ = this.auth.user.pipe(
      map((user) => (user ? user.email : '')),
      tap((email) => {
        this.subscriptionParams = { ...this.subscriptionParams, email }
      })
    )
    this.deviceSettings$ = this.settingsProvider.deviceSettings$
    this.satelliteSettings$ = this.settingsProvider.satelliteSettings$
    this.currentUser$ = this.auth.user
    this.isIOSDevice = this.environmentProvider.isIOSApp()
  }

  ngOnInit() {
    this.subscribe()
    if (this.navData.get('settings-tab')) {
      this.tab = this.navData.get('settings-tab')
    }

    if (isBlueDevice(this.device.currentBRNKLandMateId$.value.deviceId)) {
      this.tab = 'vessel'
      this.refreshSettings()
    }
    this.fetchDeviceUsers()
  }

  ngOnDestory() {
    this.unsubscribe()
  }

  ionViewWillEnter() {
    // When the user enters a page
    // ensure the database settings are shown
    this.refreshSettings()
    this.updateMateProvider.checkForMateUpdate(false)
  }

  logout(): void {
    this.auth.logout()
  }

  async deleteUserAccount(): Promise<void> {
    const uid = await this.auth.currentUserUID()

    this.helpers.showModal(
      DeleteAccountModalComponent,
      {
        uid: uid,
        deviceId: this.device.currentBRNKLandMateId$.value.deviceId,
        userEmail: this.currentUser.email,
      },
      true,
      false,
      'setup-modal-container'
    )
  }

  handleCellularSubscription() {
    this.showAddServiceModal('cellular')
  }

  async handleDeviceTransfer() {
    const authedUser = await this.authenticateCustomer()
    if (!authedUser) {
      this.helpers.showToast(
        'Only the owner of the BRNKL subscription is able to transfer device settings.',
        10
      )
      return
    } else {
      this.showDeviceTransferModal()
    }
  }

  showChangeSubscriptionToast() {
    this.helpers.showToast(
      'Contact BRNKL Support to change this subscription.\nahoy@brnkl.io  |  1-877-552-7655',
      10
    )
  }

  async copySerialNumber() {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    if (navigator.clipboard) {
      try {
        // Use Clipboard API to write text
        await navigator.clipboard.writeText(deviceId)
        this.helpers.showToast('Copied!')
      } catch (err) {
        this.helpers.showToast('Failed to copy.')
      }
    } else {
      // Fallback for browsers without clipboard API support
      this.helpers.showToast('Failed to copy.')
    }
  }

  async authenticateCustomer() {
    try {
      const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
      const userEmail = this.currentUser.email
      const authedCustomer: boolean = await this.cloudFunctions
        .authedGetPromise<any>(
          `stripe/authenticate-customer/${deviceId}/${userEmail}`
        )
        .then((res) => res.success)

      return authedCustomer
    } catch {
      return false
    }
  }

  async stripeCustomerPortal() {
    const authedUser = await this.authenticateCustomer()
    if (!authedUser) {
      this.showChangeSubscriptionToast()
      return
    }
    // check the users email against deviceId>CustomerId>email and authenticate
    // redirect if valid - toast if not valid - alternatively store customer id on user when they own the account
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    const userEmail = this.currentUser.email
    const currentUrl = window.location.href
    const domain = this.isProd ? 'app' : 'app-staging'

    const redirectUrl = this.environmentProvider.isNativeApp()
      ? `https://${domain}.brnkl.io/app-deeplink`
      : currentUrl

    this.cloudFunctions
      .authedPost<any>(`create-customer-portal-session/${deviceId}`, {
        userEmail,
        redirectUrl,
      })
      .then((res) => {
        if (res.url) {
          if (this.isMobileDevice) {
            this.iap.create(res.url, '_system')
          } else {
            window.open(res.url, '_blank')
          }
        } else {
          this.showChangeSubscriptionToast()
        }
      })
  }

  openBRNKLUI() {
    const url = this.deviceSettings.tunnelUrl
    if (this.isMobileDevice) {
      this.iap.create(`http://${url}`, '_system')
    } else {
      window.open(`http://${url}`, '_blank')
    }
  }

  async showChangePasswordModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: SetPasswordModalComponent,
    })
    await modal.present()
  }

  isMateLinked() {
    return this.device.isMateLinked()
  }

  isSatelliteLinked() {
    return this.device.isSatelliteLinked()
  }

  getSerialTestStatus(latestSatTest: { datetime: number; val: number } | null) {
    if (latestSatTest && latestSatTest.datetime > unixNow() - 300) {
      // most recent satellite signal was a test
      if (latestSatTest.val === 0) {
        // test passed
        this.serialTestStatus = 'Connected'
      } else {
        // test failed
        this.serialTestStatus = 'Not connected'
      }
    } else {
      this.serialTestStatus = 'Test'
    }
    // setInterval( () => {
    //   this.serialTestStatus = "Test"
    // }, 7000)
  }

  handleSatelliteSubscription() {
    if (this.isSatSubscribed) {
      // this.showChangeServiceModal('satellite', this.satellitePlan)
      this.helpers.showToast(
        'Contact BRNKL Support to change this subscription.\nahoy@brnkl.io  |  1-877-552-7655',
        10
      )
    } else {
      this.showAddServiceModal('satellite')
    }
  }

  async updateMate() {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To update the Mate, use the BRNKL iOS or Android app.'
      )
      return
    }

    await this.updateMateProvider.checkForMateUpdate(false)

    if (!this.updateMateProvider.mateUpdateAvailable$.value) {
      this.helpers.showToast('Mate is up to date.')
      return
    }

    this.updateMateProvider.openUpdateMateModal()
  }

  async restartMate() {
    const restartMateAlert = this.alertCtrl.create({
      header: 'Restart Mate?',
      message: 'Restart Mate connection?',
      cssClass: 'photo-modal_confirm-hide',
      buttons: [
        {
          text: 'Confirm',
          handler: () => {
            this.restartMateConnection()
          },
        },
        {
          text: 'Cancel',
        },
      ],
    })

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    } else if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To restart the Mate, use the BRNKL iOS or Android app.'
      )
      return
    } else {
      restartMateAlert.then((alert) => alert.present())
    }
  }

  async restartMateConnection() {
    if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To restart the Mate, use the BRNKL iOS or Android app.'
      )
      return
    }

    const mateId = this.device.currentBRNKLandMateId$.value.mateId
    this.helpers.startLoading('Restarting Mate')

    if (!this.bluetoothProvider.connected.value) {
      await this.bluetoothProvider.connectToMate(mateId).then(() => {
        this.bluetoothProvider.restartMateBRNKLConnection()
      })
    } else {
      this.bluetoothProvider.restartMateBRNKLConnection()
    }

    await delay(6000)

    if (this.bluetoothProvider.connected.value) {
      this.helpers.stopLoading()
      this.helpers.showInfiniteDangerToast(
        'Failed to restart the Mate. Please try again!'
      )
      return
    } else {
      await this.bluetoothProvider.connectToMate(mateId)
      this.helpers.stopLoading()
      this.helpers.showInfiniteToast('Mate successfully restarted!')
    }
  }

  async removeMate() {
    const mateId = this.device.currentBRNKLandMateId$.value.mateId
    const unlinkMateAlert = this.alertCtrl.create({
      header: 'Remove Mate?',
      message: 'Remove Mate from BRNKL?',
      cssClass: 'photo-modal_confirm-hide',
      buttons: [
        {
          text: 'Confirm',
          handler: () => {
            this.unlinkMate()
          },
        },
        {
          text: 'Cancel',
        },
      ],
    })

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    } else if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To remove the Mate, use the BRNKL iOS or Android app.'
      )
    } else if (!this.bluetoothProvider.connected.value) {
      await this.bluetoothProvider.connectToMate(mateId).then(() => {
        unlinkMateAlert.then((alert) => alert.present())
      })
    } else {
      unlinkMateAlert.then((alert) => alert.present())
    }
  }

  async unlinkMate() {
    if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To remove the Mate, use the BRNKL iOS or Android app.'
      )
      return
    }

    this.helpers.startLoading('Removing Mate')
    try {
      const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
      // av call to change serial device back to camera
      this.device.setSerialDevice(deviceId, 'cam')
      const unlinkMate = await this.device.unlinkMateFromBrnklDB(deviceId)
      if (!unlinkMate) {
        this.helpers.stopLoading()
        this.helpers.showInfiniteDangerToast(
          'Failed to remove the Mate. Please try again!'
        )
        return
      }
    } catch {
      this.helpers.stopLoading()
      this.helpers.showInfiniteDangerToast(
        'Failed to remove the Mate. Please try again!'
      )
      return
    }
    try {
      await this.bluetoothProvider.disconnectFromMate(false)
    } catch {
      this.helpers.stopLoading()
      this.helpers.showInfiniteDangerToast(
        'Failed to disconnect Mate. Please try again!'
      )
      // tslint:disable-next-line
      console.log('Failed to disconnect Mate. Please try again!')
      return
    }

    // Delay to allow Firebase to propagate changes.
    await delay(1000)
    this.refreshSettings()

    this.helpers.stopLoading()
    this.helpers.showInfiniteToast('Mate successfully removed from your BRNKL!')
    this.refreshSettings()
  }

  async testSatSignal() {
    if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To test the satellite device signal, use the BRNKL iOS or Android app.'
      )
      return
    }

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    if (this.isMateLinked()) {
      const mateId = this.device.currentBRNKLandMateId$.value.mateId
      try {
        // test for when sat is connected to mate
        this.helpers.startLoading('Sending test signal.')
        let connected: boolean
        if (!this.bluetoothProvider.connected.value) {
          await this.bluetoothProvider
            .connectToMate(mateId)
            .then(async () => {
              connected = true
              await this.bluetoothProvider.sendSatelliteSignal()
            })
            .catch(() => {
              connected = false
              this.helpers.stopLoading()
              this.helpers.showDangerToast(
                'You must connect to the Mate to test the satellite signal.'
              )
            })
        } else {
          connected = true
          await this.bluetoothProvider.sendSatelliteSignal()
        }
        if (!connected) {
          return
        }
        this.helpers.stopLoading()
        delay(3000)
        this.helpers.showInfiniteToast(
          'Signal sent\nCheck Last signal for update.\nAllow a few minutes.'
        )
      } catch {
        this.helpers.stopLoading()
        this.helpers.showInfiniteToast(
          'Problem sending test signal, please try again.'
        )
      }
    } else {
      // test for when sat is connected to BRNKL
      try {
        this.helpers.startLoading('Sending test signal')
        const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
        await this.cloudFunctions
          .authedPost<any>(`satModem/sendSignal/${deviceId}`)
          .then((res) => {
            if (res.success) {
              this.helpers.stopLoading()
              this.helpers.showInfiniteToast(
                'Signal sent.\nCheck Last signal for update.\nAllow a few minutes.'
              )
            } else {
              this.helpers.stopLoading()
              this.helpers.showInfiniteToast(
                'Problem sending satellite signal, please try again.'
              )
            }
          })
      } catch (err) {
        this.helpers.stopLoading()
        this.helpers.showInfiniteToast(
          `Problem sending satellite signal, please try again: ${err}`
        )
      }
    }
  }

  async testSatCableConnection() {
    if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To test the satellite device connection, use the BRNKL iOS or Android app.'
      )
      return
    }

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    if (this.isMateLinked()) {
      const mateId = this.device.currentBRNKLandMateId$.value.mateId
      let response: string
      try {
        // test for when satellite is connected to mate
        this.helpers.startLoading('Testing satellite device cable connection.')
        if (!this.bluetoothProvider.connected.value) {
          await this.bluetoothProvider
            .connectToMate(mateId)
            .then(async () => {
              response =
                await this.bluetoothProvider.testMateSatelliteConnection()
            })
            .catch(() => {
              this.helpers.stopLoading()
              this.helpers.showDangerToast(
                'You must connect to the Mate to test the satellite device cable connection.'
              )
            })
        } else {
          response = await this.bluetoothProvider.testMateSatelliteConnection()
        }
        this.helpers.stopLoading()
        if (!response) {
          return
        }
        if (response === '0') {
          this.helpers.showInfiniteToast(
            'Connected\nSatellite device connected.'
          )
        } else {
          this.helpers.showInfiniteDangerToast(
            `No connection.\nCheck cable plug and power connections and test again.\n\nErr ${response}`
          )
        }
        await this.settingsProvider.saveDeviceSettings({
          latestSatTest: {
            datetime: unixNow(),
            val: +response,
          },
        })
      } catch {
        this.helpers.stopLoading()
        this.helpers.showInfiniteToast(
          'Problem running satellite connection test, please try again.'
        )
      }
    } else {
      // test for when sat is connected to BRNKL
      try {
        this.helpers.startLoading('Testing satellite device cable connection.')
        const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
        await this.cloudFunctions
          .authedPost<any>(`satModem/serialTest/${deviceId}`)
          .then((res) => {
            if (res.success) {
              this.helpers.stopLoading()
              this.helpers.showInfiniteToast(
                'Test sent.\nCheck settings page for results.\nAllow a few minutes.'
              )
            } else {
              this.helpers.stopLoading()
              this.helpers.showInfiniteToast(
                'Problem testing satellite connection, please try again.'
              )
            }
          })
      } catch (err) {
        this.helpers.stopLoading()
        this.helpers.showInfiniteToast(
          `Problem testing satellite connection, please try again: ${err}`
        )
      }
    }
  }

  removeSatModem() {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    } else if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To remove the satellite device, use the BRNKL iOS or Android app.'
      )
    } else {
      this.alertCtrl
        .create({
          header: 'Remove satellite device?',
          message: 'Remove satellite device from BRNKL?',
          cssClass: 'photo-modal_confirm-hide',
          buttons: [
            {
              text: 'Confirm',
              handler: () => {
                this.unlinkSatModem()
              },
            },
            {
              text: 'Cancel',
            },
          ],
        })
        .then((alert) => alert.present())
    }
  }

  async unlinkSatModem() {
    if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'To remove the satellite device, use the BRNKL iOS or Android app.'
      )
      return
    }

    this.helpers.startLoading('Removing satellite device')
    try {
      const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
      if (!this.isMateLinked()) {
        // av call to change serial device back to camera
        this.device.setSerialDevice(deviceId, 'cam')
      }
      const unlinkSatModem = await this.device.unlinkSatModemFromBrnklDB(
        deviceId
      )
      if (!unlinkSatModem) {
        this.helpers.stopLoading()
        this.helpers.showInfiniteDangerToast(
          'Failed to remove the satellite device. Please try again!'
        )
        return
      }
    } catch {
      this.helpers.stopLoading()
      this.helpers.showInfiniteDangerToast(
        'Failed to remove the satellite device. Please try again!'
      )
      return
    }
    // Delay to allow Firebase to propagate changes.
    await delay(1000)
    this.refreshSettings()

    this.helpers.stopLoading()
    this.helpers.showInfiniteToast(
      'Satellite device successfully removed from your BRNKL! Please contact support at Ahoy@brnkl.io or 1-877-55-BRNKL to cancel your satellite subscription.'
    )
    this.refreshSettings()
  }

  subscribe(): void {
    this.subscriptions.push(
      this.settingsProvider.userSettings$.subscribe(
        (userSettings: UserSettings) => {
          this.dbUserSettings = userSettings
        }
      )
    )

    this.subscriptions.push(
      this.settingsProvider.deviceSettings$.subscribe(
        (deviceSettings: DeviceSettings) => {
          this.dbDeviceSettings = deviceSettings
          this.lastSatSignal = deviceSettings.lastSatSignal
            ? moment.unix(deviceSettings.lastSatSignal).fromNow()
            : 'No signal'
          this.getSerialTestStatus(deviceSettings.latestSatTest)
        }
      )
    )

    this.subscriptions.push(
      this.currentUser$.subscribe((user: firebase.User) => {
        this.currentUser = user
        this.authenticateCustomer()
      })
    )

    this.subscriptions.push(
      this.settingsProvider.mateSettings$.subscribe(
        (mateSettings: MateSettings) => {
          this.dbMateSettings = mateSettings
        }
      )
    )

    this.subscriptions.push(
      this.settingsProvider.satelliteSettings$.subscribe(
        (satelliteSettings: SatModemSettings) => {
          this.dbSatelliteSettings = satelliteSettings
        }
      )
    )

    this.subscriptions.push(
      this.backendSettings$.subscribe(
        (backendSettings: BackendDeviceSettings) => {
          this.backendSettings = backendSettings
          if (backendSettings) {
            this.satellitePlan = backendSettings.satellitePlan
            this.isSatSubscribed = this.satellitePlan != null
            this.isSatSuspended = backendSettings.satelliteSuspended
          }
        }
      )
    )

    this.subscriptions.push(
      this.email$.subscribe((email: string) => {
        this.email = email
      })
    )
  }

  unsubscribe(): void {
    while (this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe()
    }
  }

  deviceSettingsHandler(deviceSettings: DeviceSettings): void {
    const { deviceName } = this.deviceSettings
    this.deviceSettings = {
      ...deviceSettings,
      deviceName,
    }
  }

  mateSettingsHandler(mateSettings: MateSettings): void {
    this.mateSettings = mateSettings
  }

  private checkIfWirelessKeyfobConnected(mateSettings: MateSettings): void {
    if (mateSettings == null) {
      return
    }

    if (mateSettings.sensorConfig == null) {
      return
    }

    const sensorConfigKeys = Object.keys(mateSettings.sensorConfig)
    this.isWirelessKeyfobConnected = false
    sensorConfigKeys.forEach((key) => {
      if (
        [SensorTypes.WirelessKeyfob, SensorTypes.WirelessKeypad].includes(
          mateSettings.sensorConfig[key].type
        )
      ) {
        this.isWirelessKeyfobConnected = true
      }
    })
  }

  isNativeApp(): boolean {
    return this.environmentProvider.isNativeApp()
  }

  async save(isAutoSave: boolean = false): Promise<void> {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Saving settings is not available in demo mode.')
      return
    }

    if (
      this.settingsProvider.isViewer() &&
      (this.tab === 'sensors' || this.tab === 'vessel')
    ) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    this.configSensors.forEach((component) => component.emit())

    const msg: string = isAutoSave
      ? 'Auto-saving settings...'
      : 'Saving settings...'
    await this.helpers.startLoading(msg)
    this.hasChanged = false
    try {
      await this.saveEmail(msg)
      await this.saveSettings()
      await this.helpers.stopLoading()
      this.helpers.showToast('Success!')
    } catch (err) {
      await this.helpers.stopLoading()
      this.helpers.showDangerToast(`Unable to save: ${err.message}`)
    }
  }

  async saveEmail(loadingMsg: string): Promise<any> {
    if (this.email === this.currentUser.email) {
      return
    }

    try {
      return await this.currentUser.updateEmail(this.email)
    } catch (err) {
      if (err.code == 'auth/requires-recent-login') {
        this.helpers.stopLoading()

        await this.openReauthModal(this.currentUser.email)
        this.helpers.startLoading(loadingMsg)
        await this.currentUser.updateEmail(this.email)
      } else {
        throw err
      }
    }
  }

  async saveSettings(): Promise<any> {
    await Promise.all([
      this.settingsProvider.saveDeviceSettings(this.deviceSettings),
      this.settingsProvider.saveUserSettings(this.userSettings),
    ])

    if (this.mateSettings != null) {
      const connected = this.bluetoothProvider.connected.getValue()
      const newDelay =
        this.mateSettings.entryExitDelay ||
        this.mateSettings.entryExitDelay === 0
          ? this.mateSettings.entryExitDelay
          : null

      const oldDelay = this.dbMateSettings
        ? this.dbMateSettings.entryExitDelay ||
          this.dbMateSettings.entryExitDelay === 0
          ? this.dbMateSettings.entryExitDelay
          : null
        : null

      if (newDelay != oldDelay) {
        if (!connected) {
          throw "You must connect to the Mate to change the keypad's entry/exit delay!"
        }
        await this.bluetoothProvider.changeEntryExitDelay(newDelay)
      }
      await this.settingsProvider.saveMateSettings(this.mateSettings)
    }
  }

  abandon(): void {}

  notSaved(msg: string): void {
    this.alertCtrl
      .create({
        header: 'Abandon Changes?',
        subHeader: msg,
        buttons: [
          {
            text: 'Save',
            handler: () => {
              this.save()
            },
          },
          {
            text: 'Dismiss',
            handler: () => {
              this.abandon()
            },
          },
          {
            text: 'Cancel',
          },
        ],
      })
      .then((alert) => alert.present())
  }

  async changeDevice(): Promise<void> {
    this.settingsProvider.changeDevice()

    if (
      this.environmentProvider.isNativeApp() &&
      this.bluetoothProvider.connected
    ) {
      // Disconnect from mate if device changes
      await this.bluetoothProvider.disconnectFromMate(false)
    }
  }

  // makes a shallow copy of mateSettings so that the entryExitDelay value set by the user does not overwrite the dbMateSettings
  public refreshSettings = () => {
    this.deviceSettings = this.dbDeviceSettings
    this.userSettings = this.dbUserSettings
    this.mateSettings = this.dbMateSettings ? { ...this.dbMateSettings } : null
    this.satelliteSettings = this.dbSatelliteSettings
    if (this.mateSettings != null) {
      this.checkIfWirelessKeyfobConnected(this.mateSettings)
    }
  }

  showAddDeviceModal = () => {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    this.helpers.showModal(
      SettingsAddDeviceModal,
      {
        refreshSettings: this.refreshSettings,
      },
      true,
      false,
      'setup-modal-container'
    )
  }

  showAddServiceModal = (serviceType: string) => {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    this.helpers.showModal(
      AddServiceModal,
      {
        refreshSettings: this.refreshSettings,
        serviceType,
      },
      true,
      false,
      'setup-modal-container'
    )
  }

  showChangeServiceModal = (serviceType: string, currentPlan: string) => {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }
    this.helpers.showModal(
      ChangeServiceModal,
      {
        refreshSettings: this.refreshSettings,
        serviceType,
        currentPlan,
      },
      true,
      false,
      'setup-modal-container'
    )
  }

  showDeviceTransferModal = () => {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    this.helpers.showModal(
      DeviceTransferModal,
      {
        refreshSettings: this.refreshSettings,
      },
      true,
      false,
      'setup-modal-container'
    )
  }

  removeWirelessSensor = (sensor: WirelessSensor) => {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    if (!this.bluetoothProvider.connected.value) {
      this.helpers.showDangerToast(
        'Mate must be connected to the app to remove a wireless sensor'
      )
      return
    }

    const removeSensor = async () => {
      this.helpers.startLoading('Removing Sensor')
      try {
        await this.bluetoothProvider.removeWirelessSensorFromNetwork(
          sensor.wirelessConfig.macAddress
        )

        await this.settingsProvider.removeWirelessSensor(
          this.device.currentBRNKLandMateId$.value.mateId,
          sensor.key
        )

        // Wait for backend to propagate change to app
        await delay(2000)
        this.refreshSettings()
        await this.settingsProvider.removeWirelessSensorFromMateSettings()
        this.helpers.stopLoading()
        this.helpers.showToast('Successfully removed sensor.')
      } catch (err) {
        this.helpers.stopLoading()
        this.helpers.showDangerToast(`Failed to delete sensor: ${err}`)
      }
    }

    this.alertCtrl
      .create({
        header: 'Remove Sensor?',
        message: `Are you sure you want to remove ${sensor.name}, (${sensor.slotName}) from the Mate?`,
        cssClass: 'settings-page_confirm-delete-sensor',
        buttons: [
          {
            text: 'Confirm',
            handler: () => {
              removeSensor()
            },
          },
          {
            text: 'Cancel',
          },
        ],
      })
      .then((alert) => alert.present())
  }

  async openReauthModal(currentEmail: string) {
    const callback = new Promise<void>(async (resolve, reject) => {
      const params = {
        resolve,
        reject,
        modalCtrl: this.modalCtrl,
        userEmail: this.currentUser.email,
      }
      // CHANGE -- This might not work
      const modal = await this.modalCtrl.create({
        component: ReauthModalComponent,
        componentProps: params,
        cssClass: 'reauth-modal',
      })
      const { data } = await modal.onDidDismiss()
      if (data) {
        resolve()
      } else {
        reject(new Error('Authentication failed.'))
      }

      modal.present()
    })

    return callback
  }

  get mateUpdateAvailable$(): Observable<boolean> {
    return this.updateMateProvider.mateUpdateAvailable$
  }

  isBlueDevice(): boolean {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    return isBlueDevice(deviceId)
  }

  async fetchDeviceUsers() {
    const { usersList, pendingInvites } = await this.device.getDeviceUsers(
      this.device.currentBRNKLandMateId$.value.deviceId
    )
    this.pendingInvites = pendingInvites ?? []
    this.users = usersList
    usersList.forEach((u) => {
      if (u.uid === this.currentUser.uid) {
        this.user = u
      }
    })
  }

  handleManageUser(user: any) {
    this.selectedUser = user
    this.userTab = 'users-manage'
  }
  handleManageInvite(invite: any) {
    this.selectedInvite = invite
    this.userTab = 'users-manage-invite'
  }

  handleUserTabChange(tab: string) {
    console.log(tab)
    this.userTab = tab
  }

  handleRemoveUser() {
    this.users = this.users.filter((u) => u.email !== this.selectedUser.email)
    this.device.removeSharedUserFromDevice(
      this.device.currentBRNKLandMateId$.value.deviceId,
      this.selectedUser.uid
    )
    this.helpers.showToast(
      `User ${
        this.selectedUser.firstName
          ? this.selectedUser.firstName + ' ' + this.selectedUser.lastName
          : this.selectedUser.email
      } has been removed.`,
      10
    )
    this.userTab = 'users-list'
    this.selectedUser = null
  }

  async handleInviteUser() {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    const invitePermission =
      this.user.permission === 1 && this.pendingInvites.length === 0 ? 0 : 2
    const inviteBody = {
      invitingUser: {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
      },
      email: this.inviteEmail,
      permission: invitePermission,
    }
    const response: any = await this.cloudFunctions.authedPost(
      `invite/${deviceId}`,
      inviteBody
    )
    if (response.success) {
      this.pendingInvites.push(inviteBody)
      this.helpers.showToast(`Invite has been sent to ${this.inviteEmail}.`, 10)
    } else {
      this.helpers.showDangerToast(
        `There was an error, we were unable to send an invite to ${this.inviteEmail}.`,
        10
      )
    }

    this.userTab = 'users-list'
    this.inviteEmail = ''
  }

  async handleResendInvite() {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    const response: any = await this.cloudFunctions.authedPost(
      `invite/${deviceId}`,
      {
        email: this.selectedInvite.email,
        permission: this.selectedInvite.permission,
        invitingUser: {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
        },
      }
    )
    if (response.success) {
      this.helpers.showToast(
        `Another invite has been sent to ${this.selectedInvite.email}.`,
        10
      )
    } else {
      this.helpers.showDangerToast(
        `There was an error, we were unable to send another invite to ${this.selectedInvite.email}.`,
        10
      )
    }
  }

  async handleCancelInvite() {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    const response: any = await this.cloudFunctions.authedDel(
      `invite/${deviceId}`,
      { email: this.selectedInvite.email }
    )
    if (response.success) {
      this.pendingInvites = this.pendingInvites.filter(
        (i) => i.email !== this.selectedInvite.email
      )
      this.userTab = 'users-list'
      this.helpers.showToast(
        `Invite to ${this.selectedInvite.email} has been cancelled.`,
        10
      )
    } else {
      this.helpers.showDangerToast(
        `There was an error, we were unable to cancel the invite to ${this.selectedInvite.email}.`,
        10
      )
    }
  }
}
