import { DeviceSettings } from '../models-shared/device-settings.model'
import { SensorTypes } from '../models-shared/sensor-types'
import {
  AnalogSensor,
  AnalogSensorTypes,
} from '../models-shared/analog-sensor.model'

export function ValidateDeviceSettings(settings: DeviceSettings) {
  if (settings.sensorConfig) {
    for (const key in settings.sensorConfig) {
      if (settings.sensorConfig[key].type === SensorTypes.Analog) {
        const analogSensor = <AnalogSensor>settings.sensorConfig[key]
        if (
          analogSensor.analogType === AnalogSensorTypes.Ignition &&
          analogSensor.engineHours
        ) {
          // Round engine hours to the nearest 0.1 H
          analogSensor.engineHours =
            Math.round(analogSensor.engineHours * 10) / 10
        }
      }
    }
  }

  return settings
}
