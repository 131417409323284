<ion-content class="delete-service-content" [ngSwitch]="currentStep.name">
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <!-- LOADING-->
    <div *ngSwitchCase="'loading'">
      <div class="center-container">
        <ion-spinner name="crescent" class="loading-spinner"></ion-spinner>
        <p>Loading...</p>
      </div>
    </div>

    <!-- HAS SUBSCRIPTION PAGE -->
    <div *ngSwitchCase="'subscription'">
      <ion-list lines="none">
        <ion-item *ngFor="let device of hasSubscription">
          <div class="delete-add-service_header">
            {{ device }}
          </div>
        </ion-item>
      </ion-list>
      <div class="delete-account-label">
        Deleting your user account will cancel your subscriptions and delete all
        BRNKL data.
      </div>
      <div class="delete-account-label">
        To transfer your BRNKL to another user, contact
        <a href="https://www.brnkl.io/help/">BRNKL Support</a>
        before continuing.
      </div>
      <ion-button
        color="light"
        class="action-button"
        (click)="pushStep(deletePage)"
        >Continue</ion-button
      >
    </div>

    <!-- DELETE MAIN PAGE -->
    <div *ngSwitchCase="'delete'">
      <div class="delete-account-title">
        Deleting your account will do the following:
      </div>
      <ion-list class="delete-list" lines="none">
        <ion-item>
          <ion-icon slot="start" name="close-circle"></ion-icon>
          Cancel your BRNKL subscription and services.
        </ion-item>
        <ion-item>
          <ion-icon slot="start" name="close-circle"></ion-icon>
          Remove access to shared BRNKL devices.
        </ion-item>
        <ion-item>
          <ion-icon slot="start" name="close-circle"></ion-icon>
          Delete your user account information.
        </ion-item>
      </ion-list>
      <ion-item lines="none">
        I understand that this action can't be undone.
        <ion-checkbox
          class="checkbox"
          slot="start"
          mode="md"
          [(ngModel)]="validateChecked"
        ></ion-checkbox>
      </ion-item>
      <ion-button
        *ngIf="!validateChecked"
        disabled
        class="action-button btn-valid"
        color="light"
        slot="end"
        >Confirm</ion-button
      >
      <ion-button
        *ngIf="validateChecked"
        class="action-button btn-valid"
        color="light"
        slot="end"
        (click)="pushStep(authPage)"
        >Confirm</ion-button
      >
    </div>

    <!-- DELETE NO SUBSCRIPTION PAGE -->
    <div *ngSwitchCase="'delete-no-subscription'" class="list-container">
      <div class="delete-account-title">
        Deleting your account will do the following:
      </div>
      <ion-list class="delete-list" lines="none">
        <ion-item padding>
          <ion-icon slot="start" name="close-circle"></ion-icon>
          <ion-label>Remove your access to:</ion-label>
        </ion-item>
        <ion-item>
          <ion-list lines="none">
            <ion-item *ngFor="let device of allDevices$ | async">
              <div class="delete-add-service_header">
                {{ device.settings?.deviceName }}
              </div>
            </ion-item>
          </ion-list>
        </ion-item>
        <ion-item>
          <ion-icon slot="start" name="close-circle"></ion-icon>
          Delete your user account information.
        </ion-item>
      </ion-list>
      <ion-item lines="none">
        I understand that this action can't be undone.
        <ion-checkbox
          class="checkbox"
          slot="start"
          mode="md"
          [(ngModel)]="validateChecked"
        ></ion-checkbox>
      </ion-item>
      <ion-button
        *ngIf="!validateChecked"
        disabled
        class="action-button"
        color="light"
        slot="end"
        >Continue</ion-button
      >
      <ion-button
        *ngIf="validateChecked"
        class="action-button btn-valid"
        color="light"
        slot="end"
        (click)="pushStep(authPage)"
        >Continue</ion-button
      >
    </div>

    <!-- TAKE INPUT TO VALIDATE DELETE-->
    <div class="delete-add-service_container" *ngSwitchCase="'input'">
      <div *ngIf="hasSubscription">
        <div class="delete-add-service_header">Subscribed Devices</div>
        <ion-list class="delete-list" lines="none">
          <ion-item *ngFor="let device of hasSubscription">
            {{ device }}
          </ion-item>
        </ion-list>
        <ion-item>
          <ion-label position="stacked"
            >Please type in the name of device to confirm:</ion-label
          >
          <form [formGroup]="inputForm">
            <ion-input
              formControlName="input"
              required
              [pattern]="validatePattern"
              placeholder="Enter name of device"
            ></ion-input>
          </form>
        </ion-item>
        <ion-item lines="none">
          <ion-button
            [disabled]="!inputForm.controls.input.valid"
            slot="end"
            (click)="pushStep(finishPage)"
          >
            Delete User
          </ion-button>
        </ion-item>
      </div>
    </div>

    <!-- AUTH PAGE -->
    <div *ngSwitchCase="'auth'" class="auth-input">
      <div class="delete-account-title">
        Enter your user password to delete your account.
      </div>
      <form [formGroup]="passwordForm">
        <ion-item class="password-container">
          <ion-input
            class="password-input"
            type="password"
            formControlName="password"
            placeholder="Password"
          ></ion-input>
        </ion-item>
      </form>
      <ion-button
        class="action-button"
        color="light"
        slot="end"
        (click)="reauthAndDelete()"
        >Delete</ion-button
      >
    </div>

    <!-- DISPLAY FINISH-->
    <div *ngSwitchCase="'finish'">
      <ion-icon
        class="delete-icon"
        slot="start"
        name="checkmark-circle"
      ></ion-icon>
      <div class="delete-account-title">Your account has been deleted.</div>
      <div class="device-transfer-label">Thanks for using BRNKL!</div>
      <ion-button
        class="action-button btn-close"
        slot="end"
        (click)="onExitPressed()"
        >Close</ion-button
      >
    </div>
  </setup-container>
</ion-content>
