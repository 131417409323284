import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'top-panel',
  templateUrl: './top-panel.component.html',
  styleUrls: ['./top-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopPanelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
