import { select } from '@angular-redux/store'
import { Component, EventEmitter, ViewChild, ElementRef } from '@angular/core'
import { NavParams, NavController } from '@ionic/angular'
import { Observable } from 'rxjs'

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import { SettingsProvider } from '../../services/settings/settings.service'
import { BackendDeviceSettings } from '../../models-shared/backend-device-settings.model'

import { Subscription } from 'rxjs'

import firebase from 'firebase/compat/app'

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.page.html',
  styleUrls: ['./subscription.page.scss'],
})
export class SubscriptionPage {
  @select(['currentUser'])
  currentUser$: Observable<firebase.User>

  @ViewChild('scrollPoint') scrollPoint: ElementRef

  isMobileDevice: boolean

  payment$ = new EventEmitter<boolean>()
  deviceId: string
  subscriptions: Subscription[] = []
  popped = false
  paid = false

  constructor(
    public navParams: NavParams,
    private environment: EnvironmentProvider,
    private iap: InAppBrowser,
    private settings: SettingsProvider,
    private navCtrl: NavController
  ) {
    this.deviceId = navParams.get('deviceId')
    this.isMobileDevice = this.environment.isNativeApp()
  }

  ngOnInit() {
    this.subscribe()
  }

  ngOnDestory() {
    this.unsubscribe()
  }

  private subscribe() {
    if (this.isMobileDevice) {
      this.subscriptions.push(
        this.settings
          .getBackendSettings()
          .subscribe((settings: BackendDeviceSettings) => {
            //removed canGoBack()
            if (settings.subscribed && !this.popped) {
              this.navCtrl.pop()
              this.popped = true
            }
          })
      )
    } else {
      this.subscriptions.push(
        this.payment$.subscribe((success: boolean) => {
          this.paid = success
          this.scrollPoint.nativeElement.scrollIntoView(true)
        })
      )
    }
  }

  private unsubscribe() {
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  launchWebApp() {
    if (this.isMobileDevice) {
      const url = this.environment.webUrl
      this.iap.create(url, '_system')
    }
  }

  goBack() {
    // removed can go back()
    this.navCtrl.pop()
  }
}
