<div class="setup-confirm_center-align">
  <div class="setup-confirm_black-box setup-confirm_top-space">
    <ion-icon
      [name]="confirmationIconName"
      class="setup-container_icon setup-confirm_right-space"
    >
    </ion-icon>
    <span>
      {{ confirmationText }}
    </span>
  </div>
</div>
<div class="setup-container_btn-align setup-confirm_top-space">
  <button class="setup-container_btn-action" (click)="next.emit()">
    {{ nextButtonText }}
  </button>
</div>
