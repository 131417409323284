<ion-header>
  <ion-toolbar class="toolbar-background">
    <!-- Map Nav Bar-->
    <ion-buttons class="tabbar-tile-start" slot="start">
      <ion-button
        class="toolbar-button"
        *ngIf="currentStep != 'selectCamera'"
        (click)="goToSelectCamera()"
      >
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
      <arm-disarm-button
        *ngIf="currentStep == 'selectCamera'"
      ></arm-disarm-button>
    </ion-buttons>
    <ion-buttons class="tabbar-col-center">
      <top-bar-title
        *ngIf="currentStep == 'selectCamera'"
        class="tabbar-title-overflow toolbar-title"
      ></top-bar-title>
      <ion-title
        *ngIf="currentStep != 'selectCamera'"
        class="tabbar-title-overflow toolbar-title"
      >
        {{ currentCamera.camera.camName }}
      </ion-title>
    </ion-buttons>
    <ion-buttons class="tabbar-tile-end" slot="end">
      <ion-button
        class="toolbar-button"
        *ngIf="currentStep != 'selectCamera' && currentCamera.camera.ptz"
        (click)="handleTogglePTZControls()"
      >
        PTZ
      </ion-button>
      <!-- Must wrap signal-indicator in buttons to get correct position-->
      <ion-button fill="clear" class="top-bar_signal toolbar-button">
        <signal-indicator></signal-indicator>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" class="tabbar-tile-end">
      <notification-button></notification-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  *ngIf="thumbnails$ | async as thumbnails"
  [scrollY]="currentStep !== 'displayWebRTC'"
  style="padding: 10px; margin: 0"
>
  <div
    [ngSwitch]="currentStep"
    [ngStyle]="{'padding-top': currentStep !== 'displayWebRTC' ? '10px' : '0'}"
    style="width: 100%; height: 100%"
  >
    <!-- Show a spinner if it is loading -->
    <div *ngSwitchCase="'selectCamera'">
      <ion-list *ngIf="cameras?.length > 0; else noCameraTemplate">
        <ion-item
          button
          *ngFor="let item of cameras"
          (click)="displayStream(item.camera.uuid)"
        >
          <div class="thumbnail-container">
            <ng-container *ngIf="item.thumbnail; else loadingSpinner">
              <img
                [src]="thumbnails[item.camera.uuid]"
                class="thumbnail rounded"
              />
            </ng-container>
            <ng-template #loadingSpinner>
              <ion-spinner></ion-spinner>
            </ng-template>
          </div>
          <ion-label>
            <h2 class="cam-id">{{ item.camera.camName }}</h2>
            <p2 class="camera-type">{{ item.camera.cameraType }}</p2>
          </ion-label>
          <div class="actions">
            <p class="timestamp">{{ item.camera.timestamp | date:'short' }}</p>
          </div>
        </ion-item>
      </ion-list>

      <ng-template #noCameraTemplate>
        <ion-list>
          <ion-item>No cameras available</ion-item>
        </ion-list>
      </ng-template>
    </div>

    <!-- Display WebRTC video in an iframe when a camera is selected -->
    <div *ngSwitchCase="'displayWebRTC'" style="width: 100%; height: 100%">
      <video-player
        [camId]="currentCamera.camera.uuid"
        [thumbnail]="thumbnails[currentCamera.camera.uuid]"
        [keepStreamAlive]="keepStreamAlive"
      ></video-player>
    </div>
  </div>
</ion-content>
<ion-content
  *ngIf="showPTZControls"
  style="
    max-height: 270px;
    --background: black;
    display: flex;
    flex-direction: column;
  "
  scrollY="false"
>
  <div class="ptz-control-panel">
    <div class="zoom-buttons">
      <ion-button
        fill="clear"
        (mousedown)="handleMouseDown('zoom', 'out')"
        (mouseup)="handleMouseUp('zoom', 'out')"
        (touchstart)="handleTouchStart($event, 'zoom', 'out')"
        (touchend)="handleTouchEnd($event, 'zoom', 'out')"
      >
        <ion-icon name="remove"></ion-icon>
      </ion-button>
    </div>
    <div class="control-buttons">
      <ion-button
        fill="clear"
        (mousedown)="handleMouseDown('panTilt', 'up')"
        (mouseup)="handleMouseUp('panTilt', 'up')"
        (touchstart)="handleTouchStart($event, 'panTilt', 'up')"
        (touchend)="handleTouchEnd($event, 'panTilt', 'up')"
      >
        <ion-icon name="chevron-up"></ion-icon>
      </ion-button>
      <div class="middle-buttons">
        <ion-button
          fill="clear"
          (mousedown)="handleMouseDown('panTilt', 'left')"
        (mouseup)="handleMouseUp('panTilt', 'left')"
        (touchstart)="handleTouchStart($event, 'panTilt', 'left')"
        (touchend)="handleTouchEnd($event, 'panTilt', 'left')"
        >
          <ion-icon name="chevron-back"></ion-icon>
        </ion-button>
        <ion-button
          fill="clear"
          (mousedown)="handleMouseDown('panTilt', 'right')"
        (mouseup)="handleMouseUp('panTilt', 'right')"
        (touchstart)="handleTouchStart($event, 'panTilt', 'right')"
        (touchend)="handleTouchEnd($event, 'panTilt', 'right')"
        >
          <ion-icon name="chevron-forward"></ion-icon>
        </ion-button>
      </div>
      <ion-button
        fill="clear"
        (mousedown)="handleMouseDown('panTilt', 'down')"
        (mouseup)="handleMouseUp('panTilt', 'down')"
        (touchstart)="handleTouchStart($event, 'panTilt', 'down')"
        (touchend)="handleTouchEnd($event, 'panTilt', 'down')"
      >
        <ion-icon name="chevron-down"></ion-icon>
      </ion-button>
    </div>
    <div class="zoom-buttons">
      <ion-button
        fill="clear"
        (mousedown)="handleMouseDown('zoom', 'in')"
        (mouseup)="handleMouseUp('zoom', 'in')"
        (touchstart)="handleTouchStart($event, 'zoom', 'in')"
        (touchend)="handleTouchEnd($event, 'zoom', 'in')"
      >
        <ion-icon name="add"></ion-icon>
      </ion-button>
    </div>
  </div>
  <div class="preset-menu">
    <ion-select
      interface="action-sheet"
      aria-label="fruit"
      placeholder="Select preset"
      class="preset-select"
      (ionChange)="handlePresetChange($event)"
    >
      <ng-container *ngFor="let preset of currentCamera.camera.presets">
        <ion-select-option [value]="preset"
          >{{ preset.name }}</ion-select-option
        >
      </ng-container>
    </ion-select>
  </div>
</ion-content>
