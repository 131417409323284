<ion-grid padding>
  <ion-row style="justify-content: center">
    <ion-col class="set-anchor_col" size="4" size-md="3">
      <div class="set-anchor_label">Latitude</div>
      <div class="set-anchor_value">
        {{
          anchorConfig?.lat | convertValue | convertLatitudeToDegrees: gpsUnits
        }}
      </div>
    </ion-col>
    <ion-col class="set-anchor_col" size="4" size-md="3">
      <div class="set-anchor_label">Longitude</div>
      <div class="set-anchor_value">
        {{
          anchorConfig?.long
            | convertValue
            | convertLongitudeToDegrees: gpsUnits
        }}
      </div>
    </ion-col>
    <ion-col class="set-anchor_col" size="4" size-md="3">
      <div class="set-anchor_label">Radius</div>
      <div class="set-anchor_value">
        {{
          anchorConfig?.rad
            | convertValue: 'm':anchorConfig?.preferredUnit
            | number: '1.0-0'
        }}
        {{ anchorConfig?.preferredUnit || 'm' }}
      </div>
      <div class="set-anchor_unit"></div>
    </ion-col>
  </ion-row>
  <ion-row style="justify-content: center">
    <ion-col size="12" size-md="4" size-lg="3">
      <div class="set-anchor_label toggle">
        <span class="label">Monitor</span>
        <ion-toggle
          [(ngModel)]="anchorEnabled"
          (ngModelChange)="emitToggle()"
        ></ion-toggle>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
