import { Injectable } from '@angular/core'
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class FirebaseProvider {
  public hasPermission(): Promise<{ isEnabled: boolean }> {
    return new Promise((resolve, reject) => {
      try {
        FCM.hasPermission().then((hasPermission) => {
          resolve({ isEnabled: hasPermission })
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  public grantPermission(): Promise<any> {
    return new Promise((resolve, reject) => {
      FCM.requestPushPermission().then((hasPermission) => {
        resolve(hasPermission)
      })
    })
  }

  public getToken(): Promise<null | string> {
    return new Promise((resolve, reject) => {
      FCM.getToken().then((token) => {
        resolve(token)
      })
    })
  }

  public onTokenRefresh(): Observable<any> {
    return new Observable((observer) => {
      FCM.onTokenRefresh().subscribe((token) => [
        observer.next(token)
      ])
    })
  }

  public onNotificationOpen(): Observable<any> {
    return new Observable((observer) => {
      FCM.onNotification().subscribe((response) => {
        observer.next(response)
      })
    })
  }
}
