<ion-button
  *ngIf="isNativeApp && isMateLinked"
  class="bluetooth-button"
  (click)="isBLEConnected ? disconnectBLE() : connectBLE()"
  fill="clear"
  size="small"
>
  <ion-icon
    *ngIf="isBLEConnected"
    name="custom-bluetooth-connected"
    class="bluetooth-connected"
    slot="end"
  ></ion-icon>
  <ion-icon
    *ngIf="!isBLEConnected"
    name="bluetooth"
    class="bluetooth-disconnected"
    slot="end"
  ></ion-icon>
</ion-button>
