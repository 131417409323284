import { select } from '@angular-redux/store'
import { Component, ViewEncapsulation } from '@angular/core'
import {
  Subscription,
  from,
  map,
  mergeAll,
  scan,
  startWith,
  switchMap,
  tap,
} from 'rxjs'
import { Observable } from 'rxjs'

import { AppAlert } from '../../models/app-alert.model'
import { DeviceSettings } from '../../models-shared/device-settings.model'

import { AlertsPopoverComponent } from '../../components/alerts-popover/alerts-popover.component'

import { AlertsProvider } from '../../services/alerts/alerts.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { AuthProvider } from '../../services/auth/auth.service'
import { SettingsProvider } from '../../services/settings/settings.service'

import { formatDate } from '../../reducers/root-reducer/datetime-reducer'
import { NavController } from '@ionic/angular'
import { ViewChild } from '@angular/core'
import { IonContent } from '@ionic/angular'
import { VideosProvider } from 'app/services/videos/videos.service'
import { DeviceProvider } from 'app/services/device/device.service'
import { CameraConfig } from 'app/models-shared/camera-config.model'

const sortAsending = 'Oldest to Newest'
const sortDescending = 'Newest to Oldest'

@Component({
  selector: 'page-alerts',
  templateUrl: './alerts.page.html',
  styleUrls: ['./alerts.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertsPage {
  @select(['currentDevice', 'alerts'])
  visibleAlerts$: Observable<AppAlert[]>
  sortAscending: boolean = false
  sortType: string = sortDescending
  subscriptions: Subscription[] = []
  cameras: { [cameraId: string]: CameraConfig }
  thumbnails$: Observable<{ [alertId: string]: string }>

  @ViewChild(IonContent) content: IonContent

  constructor(
    private alertsProviders: AlertsProvider,
    private navCtrl: NavController,
    private auth: AuthProvider,
    private device: DeviceProvider,
    private helpers: HelpersProvider,
    private settingsProvider: SettingsProvider,
    private videosProvider: VideosProvider
  ) {}

  trackByAlertId(index: number, alert: AppAlert): string {
    return alert.dbKey
  }

  trackByVideoId(index: number, video: any): string {
    return video.datetime
  }

  async showClearAllAlertsDialog(): Promise<void> {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    const clearAll = this.clearAll.bind(this)
    this.helpers.showSimpleAlert(
      'Clear All Alerts?',
      'Are you sure you would like to clear all alerts?',
      undefined,
      undefined,
      clearAll
    )
  }

  async clearAll(): Promise<void> {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Alerts cannot be cleared in demo mode.')
      return
    }

    this.helpers.startLoading('Clearing alerts...')
    try {
      await this.alertsProviders.clearAll()
    } catch {
      this.helpers.showDangerToast('Failed to clear all alerts')
    }

    this.helpers.stopLoading()
  }

  async scrollDown(scroll): Promise<void> {
    await this.alertsProviders.appendToVisibleAlerts()
    scroll.target.complete()
  }

  formatAlertDate = (datetime: number) => formatDate(datetime)

  async saveSortAscending(): Promise<void> {
    await this.settingsProvider.saveDeviceSettings({
      alertsConfig: {
        sortAscending: true,
      },
    })
  }

  async saveSortDescending(): Promise<void> {
    await this.settingsProvider.saveDeviceSettings({
      alertsConfig: {
        sortAscending: false,
      },
    })
  }

  async ionViewDidEnter() {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId

    // Initialize thumbnails$
    this.thumbnails$ = this.visibleAlerts$.pipe(
      switchMap((alerts: any) => {
        const thumbnailObservables = alerts
          .filter((alert) => alert.video || alert.videos) // Filter alerts with either single video or multiple videos
          .flatMap((alert: any) => {
            // Handle `videos` array case
            if (alert.videos && Array.isArray(alert.videos)) {
              return alert.videos.map((video, index) => {
                const cameraName = this.cameras[video.uuid]?.cameraName || 'Unknown Camera';
                return this.videosProvider
                  .getThumbnail(deviceId, video.uuid, video.fileName)
                  .pipe(
                    map((thumbnail) => ({
                      alertId: `${alert.alertId}-${index}`,
                      thumbnail: { url: thumbnail, loading: true, cameraName }
                    })),
                    tap((thumbnailData) => {
                      thumbnailData.thumbnail.loading = false; // Set loading to false after image is loaded
                    })
                  );
              });
            } 
            // Handle single `video` object
            else if (alert.video) {
              const cameraName = this.cameras[alert.video.uuid]?.cameraName || 'Unknown Camera';
              return [
                this.videosProvider
                  .getThumbnail(deviceId, alert.video.uuid, alert.video.fileName)
                  .pipe(
                    map((thumbnail) => ({
                      alertId: alert.alertId,
                      thumbnail: { url: thumbnail, loading: true, cameraName }
                    })),
                    tap((thumbnailData) => {
                      thumbnailData.thumbnail.loading = false; // Set loading to false after image is loaded
                    })
                  )
              ];
            }
            return [];
          });
    
        return from(thumbnailObservables).pipe(mergeAll());
      }),
      startWith({}),
      scan((acc, curr) => {
        return { ...acc, [curr.alertId]: curr.thumbnail }; // Accumulate thumbnails
      }, {})
    );
    
  }

  async ngOnInit(): Promise<void> {
    this.subscribe()
  }

  subscribe(): void {
    this.subscriptions.push(
      this.settingsProvider.deviceSettings$.subscribe(
        (deviceSettings: DeviceSettings) => {
          this.sortAscending = deviceSettings.alertsConfig
            ? deviceSettings.alertsConfig.sortAscending
            : false
          this.sortType = this.sortAscending ? sortAsending : sortDescending
          this.cameras = deviceSettings.cameraConfig
        }
      )
    )
  }

  unsubscribe(): void {
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  ngOnDestroy(): void {
    this.unsubscribe()
  }

  showDetails(alert: AppAlert, alertId: string, thumbnail: any, cameraName: string): void {
    this.navCtrl.navigateForward('alert-details', {
      state: {
        alert: alert,
        alertId: alertId,
        alertDate: this.formatAlertDate(alert.datetime),
        thumbnail: thumbnail,
        cameraName: cameraName,
      },
    })
  }

  presentPopover(event) {
    this.helpers.showPopover(AlertsPopoverComponent, { instance: this }, event)
  }
}
