import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, take, takeUntil } from 'rxjs/operators'
import { AngularFireDatabase } from '@angular/fire/compat/database'

import { DbPathsProvider } from '../db-paths/db-paths.service'
import { DeviceProvider } from '../device/device.service'
import { AuthProvider } from '../auth/auth.service'
import { EnvironmentProvider } from '../environment/environment.service'
import { FirebaseProvider } from '../firebase/firebase.service'

import { PushNotificationTokens } from '../../models-shared/push-notification-tokens.model'
import { AngularFireObject } from '@angular/fire/compat/database/interfaces'

@Injectable({
  providedIn: 'root',
})
export class NotificationsProvider {
  private deviceIds: string[] = []
  public notify$: Observable<any>
  constructor(
    private fb: FirebaseProvider,
    private paths: DbPathsProvider,
    private db: AngularFireDatabase,
    private device: DeviceProvider,
    private envionrment: EnvironmentProvider,
    private auth: AuthProvider
  ) {
    // Some view should subscribe to this
    // such that it can handle the alert either in the foreground
    // or background (see here
    // https://ionicframework.com/docs/native/fcm/#usage)
    this.notify$ = this.fb.onNotificationOpen()
  }

  public async registerAppDevice(deviceIds: string[]): Promise<void> {
    if (this.envionrment.isNativeApp() && !this.auth.isDemoAccount()) {
      if (this.envionrment.isIOSApp()) {
        const result = await this.fb.hasPermission()
        if (result.isEnabled) {
          // Already have push notification permissions
          await this.publishTokens(deviceIds)
        } else {
          // Grant push notification permissions
          this.fb
            .onTokenRefresh()
            .pipe(first())
            .subscribe(async (val) => await this.publishTokens(deviceIds))
          await this.fb.grantPermission()
        }
      } else {
        await this.publishTokens(deviceIds)
      }
    }
  }

  private async publishTokens(deviceIds: string[]) {
    try {
      this.deviceIds = deviceIds
      const deviceToken: string = await this.fb.getToken()
      const uid = await this.auth.currentUserUID()
      const registrations: Promise<void>[] = this.deviceIds.map((id) => {
        return this.registerAppDeviceWithUnit(id, uid, deviceToken)
      })
      return Promise.all(registrations)
    } catch (err) {}
  }

  private async removeDeprecatedTokens(deviceId: string, token: string) {
    const ref: AngularFireObject<any> = this.db.object(
      this.paths.deprecatedNotificationTokens(deviceId)
    )
    const tokens: PushNotificationTokens = await ref
      .valueChanges()
      .pipe(
        take<PushNotificationTokens>(1),
        takeUntil(this.device.deviceUnselected$)
      )
      .toPromise()

    if (tokens != null) {
      for (const key in tokens) {
        if (tokens[key] === token) {
          await ref.update({
            [key]: null,
          })

          return
        }
      }
    }
  }

  private async registerAppDeviceWithUnit(
    deviceId: string,
    uid: string,
    token: string
  ): Promise<void> {
    const ref = this.db.list(this.paths.notificationTokens(deviceId, uid))
    const tokens: { [key: string]: any } = await ref
      .valueChanges()
      .pipe(take(1), takeUntil(this.device.deviceUnselected$))
      .toPromise()

    const tokenList: string[] = Object.values(tokens)
    if (!tokenList.includes(token)) {
      await ref.push(token)
      await this.removeDeprecatedTokens(deviceId, token)
    }
  }
}
