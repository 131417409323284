import { select } from '@angular-redux/store'
import { Component } from '@angular/core'
import { AppDevice } from 'app/models/app-device.model'
import firebase from 'firebase/compat/app'

import { Observable } from 'rxjs'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.page.html',
  styleUrls: ['./loading.page.scss'],
})
export class LoadingPage {
  constructor() {}

  @select('allDevices') allDevices$: Observable<AppDevice[]>
  @select('currentUser') currentUser$: Observable<firebase.User>
  @select('currentDevice') currentDevice$: Observable<AppDevice>
}
