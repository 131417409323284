<ion-header>
  <ion-toolbar class="toolbar-background">
    <!-- Map Nav Bar-->
    <ion-buttons class="tabbar-tile-start" slot="start">
      <arm-disarm-button></arm-disarm-button>
    </ion-buttons>
    <ion-buttons class="tabbar-col-center">
      <top-bar-title
        class="tabbar-title-overflow toolbar-title"
      ></top-bar-title>
    </ion-buttons>

    <ion-buttons slot="end" class="tabbar-tile-end">
      <notification-button></notification-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content no-padding>
  <ion-list
    lines="none"
    class="custom-list"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    *ngIf="controls.length > 0"
  >
    <ion-item
      class="custom-list-item + {{dragging && i === draggingIndex ? ' dragging' : ''}}"
      lines="none"
      *ngFor="let control of controls; let i = index; trackBy: getStatKey"
      cdkDrag
      cdkDragBoundary=".custom-list"
      [cdkDragStartDelay]="800"
      (mousedown)="onPressStart(i)"
      (touchstart)="onPressStart(i)"
      (touchend)="onPressEnd(i)"
      (mouseup)="onPressEnd(i)"
    >
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      <ion-label class="list-item-label">{{control.name}}</ion-label>
      <ion-toggle
        [(ngModel)]="control.toggle"
        (click)="onToggleClick()"
        (ionChange)="setControlState($event, control)"
        slot="end"
      ></ion-toggle>
    </ion-item>
  </ion-list>
  <ion-list *ngIf="controls.length === 0">
    <div class="no-controls">No controls available</div>
  </ion-list>
</ion-content>
