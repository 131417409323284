import { Platform, AlertController } from '@ionic/angular'
import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'

import { FunctionsRes } from '../../models-shared/functions-res.model'
import { DeviceProvider } from '../device/device.service'
import { CloudFunctionsProvider } from '../cloud-functions/cloud-functions.service'
import { HelpersProvider } from '../helpers/helpers.service'
import { EnvironmentProvider } from '../environment/environment.service'

import { MateUpdateInfo } from '../../models-shared/mate-update-info.model'
import { MateUpdateReport } from '../../models-shared/mate-update-report.model'
import { UpdateMateModalParams } from '../../models/update-mate-modal-params.model'

import { UpdateMateModalComponent } from '../../components/update-mate-modal/update-mate-modal.component'

@Injectable({
  providedIn: 'root',
})
export class UpdateMateProvider {
  public mateUpdateInfo: MateUpdateInfo | null
  public mateUpdateAvailable$ = new BehaviorSubject(false)

  private updateFailedAlertOpen = false
  private updateMateModalOpen = false

  constructor(
    private platform: Platform,
    private device: DeviceProvider,
    private cloudFunctions: CloudFunctionsProvider,
    private alertsController: AlertController,
    private helpers: HelpersProvider,
    private environment: EnvironmentProvider
  ) {
    this.subscribe()
  }

  public subscribe = () => {
    this.device.currentBRNKLandMateId$.subscribe((currentIds) => {
      this.checkForMateUpdate(false)
    })

    if (
      this.environment.isNativeApp() &&
      this.platform &&
      this.platform.resume
    ) {
      this.platform.resume.subscribe(() => this.checkForMateUpdate(true))
    }
  }

  public checkForMateUpdate = async (showAlert: boolean): Promise<void> => {
    if (this.device.currentBRNKLandMateId$.value.mateId == null) {
      this.mateUpdateInfo = null
      this.mateUpdateAvailable$.next(false)
      return
    }

    const endpointURL = `mate/update/${this.device.currentBRNKLandMateId$.value.mateId}`
    const update = await this.cloudFunctions.authedGetPromise<MateUpdateInfo>(
      endpointURL
    )

    if (!update || !update.url || !update.version) {
      this.mateUpdateInfo = null
      this.mateUpdateAvailable$.next(false)

      return
    }

    this.mateUpdateInfo = update
    this.mateUpdateAvailable$.next(true)

    if (
      this.environment.isNativeApp() &&
      this.mateUpdateInfo.updateInProgress &&
      showAlert
    ) {
      this.showIncompleteUpdateAlert()
    }
  }

  public notifyStartMateUpdate = async (): Promise<boolean> => {
    const currentIds = this.device.currentBRNKLandMateId$.value
    if (!currentIds || !currentIds.deviceId || !currentIds.mateId) {
      return false
    }

    const { mateId } = currentIds
    const endpointURL = `mate/update/${mateId}`
    const res: FunctionsRes<void> = await this.cloudFunctions.authedPut<
      FunctionsRes<void>
    >(endpointURL)

    return res.success
  }

  public reportMateUpdate = async (version: string): Promise<boolean> => {
    const mateId = this.device.currentBRNKLandMateId$.value.mateId

    if (!mateId) {
      return false
    }

    const endpointURL = `mate/update/${mateId}`
    const body: MateUpdateReport = {
      updatedVersion: version,
    }
    const res: FunctionsRes<void> = await this.cloudFunctions.authedPost<
      FunctionsRes<void>
    >(endpointURL, body)

    return res.success
  }

  public openUpdateMateModal = () => {
    this.updateMateModalOpen = true

    const params: UpdateMateModalParams = {
      updateInfo: this.mateUpdateInfo,
      notifyStartMateUpdate: this.notifyStartMateUpdate,
      reportMateUpdate: this.reportMateUpdate,
      checkForMateUpdate: this.checkForMateUpdate,
      updateMateModalClosed: this.updateMateModalClosed,
    }

    this.helpers.showModal(
      UpdateMateModalComponent,
      {
        data: params,
      },
      true,
      false,
      'setup-modal-container'
    )
  }

  public updateMateModalClosed = () => {
    this.updateMateModalOpen = false
  }

  private showIncompleteUpdateAlert = () => {
    if (this.updateFailedAlertOpen || this.updateMateModalOpen) {
      return
    }

    this.updateFailedAlertOpen = true
    this.alertsController
      .create({
        header: 'Mate Update Failed',
        subHeader:
          'Mate features unavailable. Update again within 5 m of the Mate.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              this.updateFailedAlertOpen = false
            },
          },
          {
            text: 'Update',
            handler: () => {
              this.updateFailedAlertOpen = false
              this.openUpdateMateModal()
            },
          },
        ],
      })
      .then((alert) => alert.present())
  }
}
