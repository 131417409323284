<setup-device-box
  [iconName]="device.iconName"
  [header]="device.companyName"
  [deviceName]="device.name"
></setup-device-box>
<div class="setup-container_header setup-input_section">
  {{ header }}
</div>
<div class="setup-container_text setup-container_bottom-space">
  {{ description }}
</div>
<div>
  <input
    type="text"
    [(ngModel)]="input"
    class="setup-input_input setup-container_text"
  />
  <div class="setup-input_example-text setup-container_bottom-space">
    Example: {{ exampleInput }}
  </div>
</div>
<div
  *ngIf="input && input.length; else elseBlock"
  class="setup-container_btn-align"
>
  <button class="setup-container_btn-action" (click)="nextButtonPressed()">
    {{ nextButtonText }}
  </button>
</div>
<ng-template #elseBlock
  ><div class="setup-container_btn-action-placeholder"></div
></ng-template>
