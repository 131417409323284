import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { BaseSetupModalComponent } from '../base-setup-modal/base-setup-modal.component'
import { ServicePlanDisplay } from '../../models/service-plan-display.model'
import { ServicePlanInfo } from '../../models/service-plan-info.model'
import { SetupStep } from '../../models/setup-step.model'
import { ModalController, NavParams } from '@ionic/angular'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { IPLookupResponse } from '../../models/iplookup-response-model'
import { ENV } from '@app/env'

import { DeviceProvider } from '../../services/device/device.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { AuthProvider } from '../../services/auth/auth.service'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import { CloudFunctionsProvider } from '../../services/cloud-functions/cloud-functions.service'
import { SubscriptionProvider } from '../../services/subscription/subscription.service'
import { SubscriptionState } from '../../models-shared/subscription-state.model'

@Component({
  selector: 'add-service-modal',
  templateUrl: './add-service-modal.component.html',
  styleUrls: ['./add-service-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddServiceModal extends BaseSetupModalComponent {
  @Output() planSelect = new EventEmitter()
  serviceType: 'cellular' | 'satellite'
  newDeviceId: string
  deviceId$: Observable<string>
  deviceId: string = ''
  satModemId$: Observable<string>
  satModemId: string = ''
  email$: Observable<string>
  email: string = ''
  isNativeApp: boolean
  isMobileDevice: boolean
  isProd: boolean = ENV.IS_PROD
  plan: ServicePlanDisplay
  coupon: string
  monthlyCellPlan: ServicePlanInfo
  annualCellPlan: ServicePlanInfo
  liteSatPlan: ServicePlanInfo
  standardSatPlan: ServicePlanInfo
  _subscriptionState: SubscriptionState = {
    plans: [],
  }

  satelliteServicesList: ServicePlanDisplay[] = []
  cellularServicesList: ServicePlanDisplay[] = []

  plans: SetupStep = {
    name: 'plans',
    title: 'Add service',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: true,
  }

  planSelected: SetupStep = {
    name: 'planSelected',
    title: 'Add service',
    iconName: 'custom-add-device',
    backButton: true,
    exitButton: true,
  }

  satelliteServiceInfo = {
    name: 'Satellite Service',
    companyName: 'BRNKL',
    iconName: 'custom-satellite-service',
  }

  planList: ServicePlanDisplay[] = []

  private subscriptions: Subscription[] = []
  private refreshSettings: () => void

  constructor(
    private auth: AuthProvider,
    public viewCtrl: ModalController,
    private navParams: NavParams,
    private helpers: HelpersProvider,
    private device: DeviceProvider,
    private subscription: SubscriptionProvider,
    private environment: EnvironmentProvider,
    private cloudFunctions: CloudFunctionsProvider,
    private iap: InAppBrowser
  ) {
    super(viewCtrl)
    this.isNativeApp = this.environment.isNativeApp()
    this.isMobileDevice = this.environment.isMobileDevice()
    this.deviceId$ = this.device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.deviceId)
    )
    this.satModemId$ = this.device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.satModemId)
    )
    this.email$ = this.auth.user.pipe(map((user) => (user ? user.email : '')))
    this.refreshSettings = this.navParams.get('refreshSettings')
    this.serviceType = this.navParams.get('serviceType')
    this.newDeviceId = this.navParams.get('newDeviceId')
  }

  createSatelliteList(): ServicePlanDisplay[] {
    return [
      {
        companyName: 'BRNKL',
        title: this.liteSatPlan.title,
        iconName: 'custom-satellite-service',
        disabled: this.isSubscribed(),
        id: this.liteSatPlan.id,
        currency: this.liteSatPlan.currency,
        amount: this.liteSatPlan.amount,
        interval: this.liteSatPlan.interval,
      },
      {
        companyName: 'BRNKL',
        title: this.standardSatPlan.title,
        iconName: 'custom-satellite-service',
        disabled: this.isSubscribed(),
        id: this.standardSatPlan.id,
        currency: this.standardSatPlan.currency,
        amount: this.standardSatPlan.amount,
        interval: this.standardSatPlan.interval,
      },
    ]
  }

  createCellularList(): ServicePlanDisplay[] {
    return [
      {
        companyName: 'BRNKL',
        title: this.monthlyCellPlan.title,
        iconName: 'custom-subscribe',
        disabled: this.isSubscribed(),
        id: this.monthlyCellPlan.id,
        currency: this.monthlyCellPlan.currency,
        amount: this.monthlyCellPlan.amount,
        interval: this.monthlyCellPlan.interval,
      },
      {
        companyName: 'BRNKL',
        title: this.annualCellPlan.title,
        iconName: 'custom-subscribe',
        disabled: this.isSubscribed(),
        id: this.annualCellPlan.id,
        currency: this.annualCellPlan.currency,
        amount: this.annualCellPlan.amount,
        interval: this.annualCellPlan.interval,
      },
    ]
  }

  ngOnInit() {
    this.init()
    this.subscribe()
    this.configure()
  }

  async init() {
    try {
      this._subscriptionState = await this.subscription.getState()
      //get plan info for both satellite service options
      if (this.serviceType === 'satellite') {
        this.liteSatPlan = this.getPlanInfo('Lite Satellite')
        this.standardSatPlan = this.getPlanInfo('Standard Satellite')
        this.satelliteServicesList = this.createSatelliteList()
        this.planList = this.satelliteServicesList
        this.createSatelliteList()
      } else if (this.serviceType === 'cellular') {
        this.monthlyCellPlan = this.getPlanInfo('Cellular Monthly')
        this.annualCellPlan = this.getPlanInfo('Cellular Annually')
        this.cellularServicesList = this.createCellularList()
        this.planList = this.cellularServicesList
        this.createCellularList()
      }
    } catch (err) {
      throw new Error('Error fetching BRNKL subscriptions')
    }
  }

  subscribe(): void {
    this.subscriptions.push(
      this.deviceId$.subscribe((deviceId: string) => {
        this.deviceId = deviceId
      })
    )

    this.subscriptions.push(
      this.satModemId$.subscribe((satModemId: string) => {
        this.satModemId = satModemId
      })
    )

    this.subscriptions.push(
      this.email$.subscribe((email: string) => {
        this.email = email
      })
    )
  }

  ngOnDestory() {
    this.unsubscribe()
  }

  unsubscribe(): void {
    while (this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe()
    }
  }

  onExitPressed(): void {
    this.exit()
  }
  async onBackPressed(): Promise<void> {
    if (this.currentStep.name === 'plans') {
      await this.openAddServiceModal()
    } else {
      this.back()
    }
  }

  getPlanInfo(planTitle: string) {
    const planInfo = this._subscriptionState.plans
      .filter((plan) => plan.nickname === planTitle)
      .map(({ nickname: title, interval, amount, currency, id }) => {
        const rawAmount = amount / 100
        const prettyInterval = interval === 'month' ? 'Monthly' : 'Annually'
        const prettyCurrency = currency.toUpperCase()
        return {
          id,
          title,
          amount: rawAmount,
          interval: prettyInterval,
          currency: prettyCurrency,
        }
      })
    return planInfo[0]
  }

  isSubscribed() {
    return false
  }

  handleEnterCoupon(coupon: string) {
    this.coupon = coupon
  }

  openAddServiceModal = async () => {
    this.helpers.showModal(
      AddServiceModal,
      {
        refreshSettings: this.refreshSettings,
        serviceType: this.serviceType,
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }

  launchWebApp() {
    if (this.isNativeApp) {
      const url =
        this.environment.webUrl +
        `/add-${this.serviceType}-service/` +
        this.deviceId
      this.iap.create(url, '_system')
    }
    this.exit()
  }

  async serviceSelected(plan: ServicePlanDisplay) {
    this.planSelect.emit(plan)
    this.plan = plan
    this.pushStep(this.planSelected)
  }

  async ipLocation() {
    const url = `https://extreme-ip-lookup.com/json/?key=${ENV.IP_LOOKUP_KEY}`
    const response = await fetch(url)
    const data: IPLookupResponse = await response.json()

    if (data.status != 'success') {
      // Server request failed
      // tslint:disable-next-line
      console.error(`IP Lookup server returned error ${data.status}`)
    }
    return {
      country: data.countryCode,
      state: data.region,
    }
  }

  // Create a Checkout Session with the selected plan ID
  async createCheckoutSession(planId: string) {
    const imei = this.serviceType === 'satellite' ? this.satModemId : null
    const ipLocation = await this.ipLocation()
    const device = this.newDeviceId ? this.newDeviceId : this.deviceId
    const key = ENV.STRIPE_PUB_KEY
    const stripe = Stripe(key, {
      apiVersion: '2020-08-27',
    })

    const currentUrl = window.location.href
    const urlParams = currentUrl.includes('add-device') ? `/${device}` : ''
    const domain = this.isProd ? 'app' : 'app-staging'

    const successUrl = this.isMobileDevice
      ? `https://${domain}.brnkl.io/app-deeplink`
      : currentUrl + urlParams

    const cancelUrl = currentUrl + urlParams

    await this.cloudFunctions
      .authedPost<any>(`create-checkout-session/${device}`, {
        userEmail: this.email,
        planId,
        imei,
        coupon: this.coupon,
        serviceType: this.serviceType,
        ipLocation,
        successUrl,
        cancelUrl,
      })
      .then((res) => {
        if (res.success && res.sessionId) {
          stripe.redirectToCheckout({
            sessionId: res.sessionId,
          })
        } else {
          this.helpers.showDangerToast('Error directing to stripe checkout')
        }
      })
  }

  private configure() {
    this.planSelected.next = () => {
      this.createCheckoutSession(this.plan.id)
    }
    // Set first step
    this.pushStep(this.plans)
  }
}
