<ion-header>
  <ion-toolbar class="toolbar-background">
    <ion-buttons slot="start">
      <ion-back-button
        *ngIf="!isRoot()"
        class="toolbar-button"
      ></ion-back-button>
    </ion-buttons>
    <ion-title class="toolbar-title">Add to fleet</ion-title>
    <ion-buttons slot="primary">
      <ion-button
        *ngIf="isRoot()"
        (click)="logout()"
        class="toolbar-button"
        color="danger"
      >
        Logout
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="add-device_bg">
  <div class="add-device_container" *ngIf="(status$ | async) as status">
    <ion-card *ngIf="status === 'SEARCH'"class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header">Add BRNKL</ion-card-title>
      </ion-card-header>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <search-device
              [paramDeviceId]="paramDeviceId.trim().toUpperCase()"
              (deviceId$)="onValidDeviceId($event)"
            >
            </search-device>
          </ion-col>
        </ion-row>
      </ion-grid> </ion-card
    >`
    <ion-card *ngIf="status === 'SELECT_ROLE'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header">
          Are you the owner of this BRNKL or an installer setting it up for someone else?
        </ion-card-title>
      </ion-card-header>
    
      <ion-card-content>
        <ion-grid>
          <ion-col class="ion-justify-content-center">
            <!-- Owner Button -->
              <ion-button
                expand="block"
                color="dark"
                class="primary-button"
                (click)="userRole = 'owner'; handleNextStep('NAME_DEVICE')"
              >
                Owner
              </ion-button>
          
    
            <!-- Installer Button -->
              <ion-button
                expand="block"
                fill="outline"
                color="dark"
                class="secondary-button"
                (click)="userRole = 'installer'; handleNextStep('NAME_DEVICE')"
              >
                Installer
              </ion-button>
          </ion-col>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <ion-card *ngIf="status === 'NAME_DEVICE'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Enter a name for your BRNKL</ion-card-title
        >
      </ion-card-header>
      <form
        [formGroup]="addNewDeviceForm"
        formControlName="deviceName"
        class="add-device _form"
      >
        <ion-item>
          <ion-input formControlName="deviceName" type="text" placeholder="Enter name"></ion-input>
        </ion-item>
      </form>
      <ion-button class="add-service_button" (click)="showAddServiceModal()">
        Continue
      </ion-button>
    </ion-card>
    <ion-card *ngIf="status === 'INVITED'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >You've been invited to share {{ deviceStatus.deviceName }}.</ion-card-title
        >
      </ion-card-header>
      <div class="add-device-body">
        {{deviceStatus.invitingUser.firstName}} {{deviceStatus.invitingUser.lastName}} invited you to share. Do you want to add {{ deviceStatus.deviceName }} to your
        fleet?
      </div>
      <ion-button (click)="linkDevice(true)" icon-end> Add </ion-button>
    </ion-card>
    <ion-card *ngIf="status === 'RESTRICTED'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >This BRNKL is registered to someone else.</ion-card-title
        >
      </ion-card-header>
      <div>
        Contact the registered owner for permission to add to your fleet.
      </div>
      <ion-button (click)="handleClose()" icon-end>Close</ion-button>
    </ion-card>
    <ion-card *ngIf="status === 'REGISTER' && userRole ==='owner'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >BRNKL {{deviceType}} {{ deviceId }} is available to
          register.</ion-card-title
        >
      </ion-card-header>
      <ion-button (click)="linkDevice(true)" icon-end>Register</ion-button>
    </ion-card>
    <ion-card *ngIf="status === 'REGISTER' && userRole ==='installer'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >BRNKL {{deviceType}} {{ deviceId }} is available for temporary installer access.</ion-card-title
        >
        <div class="add-device-body">
          You will have access to this BRNKL for 7 days.
        </div>
      </ion-card-header>
      <ion-button (click)="linkDevice(true)" icon-end>Access</ion-button>
    </ion-card>
    <ion-card *ngIf="status === 'SUBSCRIBE'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Activate BRNKL</ion-card-title
        >
      </ion-card-header>
      <ion-button class="add-service_button" (click)="showAddServiceModal()">
        Add a BRNKL subscription
      </ion-button>
    </ion-card>
    <ion-card
      *ngIf="status === 'WEBAPP' && status !== 'SUBSCRIBE'"
      class="ion-card-bg"
    >
      <ion-card-header>
        <ion-card-title class="add-device-header">Pay Online</ion-card-title>
      </ion-card-header>
      <div class="add-device_webapp-btn-container">
        <ion-button icon-end (click)="launchWebApp()">
          Open BRNKL web app
        </ion-button>
      </div>
      <p class="add-device_webapp-text">
        Payments are only supported through the BRNKL web app.
      </p>
    </ion-card>
    <div #scrollPoint class="add-device_bottom"></div>
  </div>
</ion-content>
