import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard'
import { StateGuard } from './guards/state.guard'
import { AddDevicePage } from './pages/add-device/add-device.page'
import { ShareDevicePage } from './pages/share-device/share-device.page'
import { AlertsPage } from './pages/alerts/alerts.page'
import { DeviceGuard } from './guards/device.guard'

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login'])

const routes: Routes = [
  {
    path: '',
    redirectTo: '/loading',
    pathMatch: 'full',
  },
  {
    path: 'add-device',
    component: AddDevicePage,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'add-device/:deviceId',
    component: AddDevicePage,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'share-device',
    component: ShareDevicePage,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'share-device/:token',
    component: ShareDevicePage,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'alert-details',
    loadChildren: () =>
      import('./pages/alert-details/alert-details.module').then(
        (m) => m.AlertDetailsPageModule
      ),
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'alerts',
    component: AlertsPage,
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'create-account',
    loadChildren: () =>
      import('./pages/create-account/create-account.module').then(
        (m) => m.CreateAccountPageModule
      ),
  },
  {
    path: 'force-update',
    loadChildren: () =>
      import('./pages/force-update/force-update.module').then(
        (m) => m.ForceUpdatePageModule
      ),
  },
  {
    path: 'graphs',
    loadChildren: () =>
      import('./pages/graphs/graphs.module').then((m) => m.GraphsPageModule),
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'layout',
    loadChildren: () =>
      import('./pages/layout/layout.module').then((m) => m.LayoutPageModule),
  },
  {
    path: 'loading',
    loadChildren: () =>
      import('./pages/loading/loading.module').then((m) => m.LoadingPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'map',
    loadChildren: () =>
      import('./pages/map/map.module').then((m) => m.MapPageModule),
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'photos',
    loadChildren: () =>
      import('./pages/photos/photos.module').then((m) => m.PhotosPageModule),
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'sandbox',
    loadChildren: () =>
      import('./pages/sandbox/sandbox.module').then((m) => m.SandboxPageModule),
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'select-device',
    loadChildren: () =>
      import('./pages/select-device/select-device.module').then(
        (m) => m.SelectDevicePageModule
      ),
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
    canActivate: [AngularFireAuthGuard, StateGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./pages/subscription/subscription.module').then(
        (m) => m.SubscriptionPageModule
      ),
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AngularFireAuthGuard, StateGuard, DeviceGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    // Page that does not exist
    // redirect to layout page
    path: '**',
    redirectTo: '/layout',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
