<div>
  <div class="setup-container_header-icon-container">
    <div>
      <ion-icon *ngIf="iconName !== ''" [name]="iconName" class="setup-container_icon"> </ion-icon>
    </div>
    <div class="setup-container_title">{{ title }}</div>
  </div>
  <div class="setup-container_content">
    <ng-content></ng-content>
  </div>
</div>
<div class="setup-container_btn-container">
  <ion-fab-button
    *ngIf="backButton"
    class="setup-container_btn-back"
    (click)="back.emit()"
  >
    <ion-icon
      name="arrow-back-outline"
      class="setup-container_icon icon-black"
    ></ion-icon>
  </ion-fab-button>
  <ion-fab-button
    *ngIf="exitButton"
    class="setup-container_btn-exit"
    (click)="exit.emit()"
  >
    <ion-icon name="close" class="setup-container_icon"></ion-icon>
  </ion-fab-button>
</div>
