<ion-content *ngIf="isMobileDevice">
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <setup-info
      (next)="launchWebApp()"
      header="Open"
      description="Open BRNKL in your browser to change this subscription."
      nextButtonText="Open"
    ></setup-info>
  </setup-container>
</ion-content>
<ion-content
  *ngIf="!isMobileDevice"
  class="change-service-content"
  [ngSwitch]="currentStep.name"
>
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <div class="setup-change-service_container" *ngSwitchCase="'changeService'">
      <setup-device-box
        [iconName]="currentPlanInfo.iconName"
        [header]="currentPlanInfo.companyName"
        [deviceName]="currentPlanInfo.name"
      ></setup-device-box>
      <ion-icon
        class="setup-change-service_icon"
        name="custom-change-plan"
      ></ion-icon>
      <div class="setup-change-service_header">Change</div>
      <div class="setup-change-service_tagline">
        Change your subscription to one of the following.
      </div>
      <div class="setup-change-service_actions">
        <div
          class="setup-add-service_padbox"
          *ngFor="let plan of planOptions"
          (click)="switchPlans(plan)"
        >
          <setup-service-box
            [iconName]="plan.iconName"
            [header]="plan.companyName"
            [title]="plan.title"
            [currency]="plan.currency"
            [amount]="plan.amount"
            [interval]="plan.interval"
          ></setup-service-box>
        </div>
      </div>
    </div>
    <setup-payment
      *ngSwitchCase="'planSelected'"
      (next)="currentStep.next()"
      [plan]="plan"
      header="Payment"
      nextButtonText="Switch"
      [serviceType]="serviceType"
      [activeService]="true"
    ></setup-payment>
  </setup-container>
</ion-content>
