import { Component } from '@angular/core'

import { ModalController, NavParams } from '@ionic/angular'
import { Subscription } from 'rxjs'

import {
  BarcodeScannerOptions,
  BarcodeScanner,
  BarcodeScanResult,
} from '@ionic-native/barcode-scanner/ngx'
import { Insomnia } from '@ionic-native/insomnia/ngx'

import { SetupStep } from '../../models/setup-step.model'

import { BaseSetupModalComponent } from '../../components/base-setup-modal/base-setup-modal.component'
import { SettingsAddDeviceModal } from '../../components/settings-add-device-modal/settings-add-device-modal.component'

import { HelpersProvider } from '../../services/helpers/helpers.service'
import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'
import { DeviceProvider } from '../../services/device/device.service'
import { UpdateMateProvider } from '../../services/update-mate/update-mate.service'

import { delay } from '../../util'
const scannerOptions: BarcodeScannerOptions = {
  preferFrontCamera: false,
  formats: 'QR_CODE',
  resultDisplayDuration: 0,
}

@Component({
  selector: 'link-mate-modal',
  templateUrl: './link-mate-modal.component.html',
  styleUrls: ['./link-mate-modal.component.scss'],
})
export class LinkMateModal extends BaseSetupModalComponent {
  connect: SetupStep = {
    name: 'connect',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: true,
    exitButton: true,
  }

  scanMate: SetupStep = {
    name: 'scanMate',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: true,
    exitButton: true,
  }
  enterCode: SetupStep = {
    name: 'enterCode',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: true,
    exitButton: true,
  }
  find: SetupStep = {
    name: 'find',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }

  mateInfo = {
    name: 'Mate',
    companyName: 'BRNKL',
    iconName: 'custom-mate',
  }

  scanningBarcode: boolean = false
  progressStep: string = 'Finding'
  progressPercent: number = 20
  progressDoneButton: string = ''
  progressHeader: string = 'Adding'
  progressDescription: string = 'Adding the Mate.'
  progressIconName: string = 'custom-add-device'
  mateSerialNumber: string
  inputValue: string

  private refreshSettings: () => void
  private subscriptions: Subscription[] = []

  constructor(
    private barcodeScanner: BarcodeScanner,
    private helpers: HelpersProvider,
    private bluetoothProvider: BluetoothLEProvider,
    private updateMateProvider: UpdateMateProvider,
    public viewCtrl: ModalController,
    private navParams: NavParams,
    private device: DeviceProvider,
    private insomnia: Insomnia
  ) {
    super(viewCtrl)
    this.configure()
    this.refreshSettings = this.navParams.get('refreshSettings')
  }

  subscribeToDisconnection() {
    this.subscriptions.push(
      this.bluetoothProvider.connected.subscribe((isConnected: boolean) => {
        if (!isConnected) {
          // Mate disconnected
          this.exit()
        }
      })
    )
  }

  ngOnInit() {
    this.insomnia.keepAwake()
  }

  ngOnDestroy() {
    this.insomnia.allowSleepAgain()
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  onExitPressed(): void {
    this.exit()
  }

  async onBackPressed(): Promise<void> {
    if (this.currentStep.name === 'connect') {
      await this.openAddDeviceModal(false)
    } else {
      this.back()
    }
  }

  async scanDataMatrix(): Promise<BarcodeScanResult> {
    if (this.scanningBarcode) {
      return
    }
    this.scanningBarcode = true

    try {
      const result = await this.barcodeScanner.scan(scannerOptions)
      this.scanningBarcode = false
      return result
    } catch (err) {
      this.scanningBarcode = false
      throw err
    }
  }

  parseBarcodeScanResult(text: string): string {
    const mateRegex = /(M8-[A-Z0-9]+)$/gm
    const matches = mateRegex.exec(text)
    if (!matches) {
      throw new Error('QR Code is invalid!')
    }
    return matches[0]
  }

  onProgressCancel = async () => {
    this.onExitPressed()
    if (this.progressDoneButton === 'Next') {
      // The cancel button is actually a Next button in this case
      this.refreshSettings()
      await this.updateMateProvider.checkForMateUpdate(false)

      if (!this.updateMateProvider.mateUpdateAvailable$.value) {
        await this.openAddDeviceModal(false)
      } else {
        this.updateMateProvider.openUpdateMateModal()
      }
    }
  }

  setProgressStepDetails = (
    percent?: number,
    step?: string,
    iconName?: string,
    header?: string,
    description?: string,
    doneButtonText?: string
  ) => {
    this.progressPercent = percent
    this.progressStep = step
    this.progressIconName = iconName
    this.progressHeader = header
    this.progressDescription = description
    this.progressDoneButton = doneButtonText
  }

  onScanMate = async () => {
    let result: BarcodeScanResult
    try {
      result = await this.scanDataMatrix()
    } catch (e) {
      throw new Error(
        'BRNKL needs to use the camera on this device to scan. In the device Settings, allow BRNKL access to the camera.'
      )
    }

    if (result.cancelled) {
      return ''
    }

    return this.parseBarcodeScanResult(result.text)
  }

  handleEnterCode(input: string) {
    this.inputValue = input
  }

  openAddDeviceModal = async (disconnectMate: boolean) => {
    if (disconnectMate && this.bluetoothProvider.connected.value) {
      try {
        await this.bluetoothProvider.disconnectFromMate(false)
      } catch {}
    }

    this.helpers.showModal(
      SettingsAddDeviceModal,
      {
        refreshSettings: this.refreshSettings,
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }

  private connectToMate = async (mateId: string) => {
    this.setProgressStepDetails(
      20,
      'Finding',
      'custom-add-device',
      'Adding',
      `Adding the Mate.`
    )
    await delay(1000)

    await this.bluetoothProvider.connectToMate(mateId, async () => {
      // Mate has been found
      this.setProgressStepDetails(
        40,
        'Linking',
        'custom-add-device',
        'Adding',
        `Adding the Mate.`
      )
      await delay(1000)
    })
  }

  // private testConnectionToMate = async () => {
  //   this.subscribeToDisconnection()

  //   this.setProgressStepDetails(
  //     60,
  //     'Testing',
  //     'custom-add-device',
  //     'Adding',
  //     `Adding the Mate.`
  //   )
  // disable testMateBRNKLConnection for BRNKL v6.0.0 and higher

  // const numberOfTestingTries = 3
  // const delayMSBetweenTestingTries = 10 * 1000

  // for (let i = 0; i < numberOfTestingTries; i++) {
  //   await delay(delayMSBetweenTestingTries)
  //   const success = await this.bluetoothProvider.testMateBRNKLConnection()
  //   if (success) {
  //     return
  //   }
  // }

  // if (ENV.IS_PROD) {
  //   throw new Error('Mate cannot establish connection to BRNKL')
  // } else {
  //   this.helpers.showToast(
  //     'Test Mate connection with BRNKL failed...skipping'
  //   )
  // }
  // }

  private linkMate = async (mateId: string, deviceId: string) => {
    this.setProgressStepDetails(
      80,
      'Adding',
      'custom-add-device',
      'Adding',
      `Adding the Mate.`
    )
    const linkMate = await this.device.linkMateToBrnklDB(mateId, deviceId)
    if (!linkMate) {
      throw new Error('Database request did not return a success')
    }
  }

  private configure() {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    //Define Transitions
    this.connect.next = () => {
      this.pushStep(this.scanMate)
    }
    this.scanMate.option = () => {
      this.pushStep(this.enterCode)
    }

    this.scanMate.next = async () => {
      let mateId = ''

      try {
        mateId = await this.onScanMate()
        if (mateId == '') {
          // User cancelled
          return
        } else {
          this.pushStep(this.find)
        }
      } catch (err) {
        this.helpers.showInfiniteDangerToast(
          `An error occured when scanning: ${err.message}`
        )
        // reset process
        await this.openAddDeviceModal(false)
        return
      }

      // select mate as the serial device for the BRNKL
      this.device.setSerialDevice(deviceId, 'mate')

      try {
        await this.connectToMate(mateId)
        await delay(1000)

        this.setProgressStepDetails(
          60,
          'Testing',
          'custom-add-device',
          'Adding',
          `Adding the Mate.`
        )
        await delay(1000)
        await this.linkMate(mateId, deviceId)
        await delay(1000)

        this.setProgressStepDetails(
          undefined,
          undefined,
          'md-checkmark',
          'Added',
          `Mate added to BRNKL.`,
          'Next'
        )
        await delay(1000)
        this.refreshSettings()
        await delay(2000)
      } catch (err) {
        console.log('made it here, error: ', err)
        if (this.bluetoothProvider.connected.value) {
          await this.bluetoothProvider.disconnectFromMate(false)
        }
        this.helpers.showInfiniteDangerToast(
          `An error when adding the Mate occured: ${err.message}`
        )
        await this.openAddDeviceModal(true)
      }
    }

    this.enterCode.next = async () => {
      //check if input is of valid format
      if (!this.inputValue.startsWith('M8-')) {
        this.helpers.showInfiniteToast(
          `Invalid serial number. Please try again.`
        )
        return
      } else {
        this.pushStep(this.find)
      }

      let mateId = this.inputValue

      // select mate as the serial device for the BRNKL
      this.device.setSerialDevice(deviceId, 'mate')

      try {
        await this.connectToMate(mateId)
        await delay(1000)

        this.setProgressStepDetails(
          60,
          'Testing',
          'custom-add-device',
          'Adding',
          `Adding the Mate.`
        )
        await delay(1000)
        await this.linkMate(mateId, deviceId)
        await delay(1000)

        this.setProgressStepDetails(
          undefined,
          undefined,
          'md-checkmark',
          'Added',
          `Mate added to BRNKL.`,
          'Next'
        )
        await delay(1000)
        this.refreshSettings()
        await delay(2000)
      } catch (err) {
        console.log('made it here, error: ', err)
        if (this.bluetoothProvider.connected.value) {
          await this.bluetoothProvider.disconnectFromMate(false)
        }
        this.helpers.showInfiniteDangerToast(
          `An error when adding the Mate occured: ${err.message}`
        )
        await this.openAddDeviceModal(true)
      }
    }

    //Set first step
    this.pushStep(this.connect)
  }
}
