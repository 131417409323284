import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { AnchorConfig } from '../../models-shared/anchor-config.model'

@Component({
  selector: 'set-anchor',
  templateUrl: './set-anchor.component.html',
  styleUrls: ['./set-anchor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetAnchorComponent {
  nGpsDecimals: number = 6
  nRadiusDecimals: number = 0
  @Input() anchorConfig: AnchorConfig
  @Input() anchorEnabled: boolean
  @Input() gpsUnits: string
  @Output() anchorEnabledChange: EventEmitter<boolean> = new EventEmitter()

  emitToggle() {
    this.anchorEnabledChange.emit(this.anchorEnabled)
  }
}
