import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { WirelessSensorDisplay } from '../../models/wireless-sensor-display.model'

@Component({
  selector: 'setup-input',
  templateUrl: './setup-input.component.html',
  styleUrls: ['./setup-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupInputComponent {
  @Output() next = new EventEmitter()

  @Input() exampleInput: string
  @Input() header: string
  @Input() description: string
  @Input() nextButtonText: string
  @Input() device: WirelessSensorDisplay

  input: string = ''

  nextButtonPressed() {
    if (!this.input || !this.input.length) {
      return
    }
    this.next.emit(this.input)
  }
}
