<ion-content padding no-bounce>
  <div class="login-container">
    <div class="login-container_inner">
      <div class="login-icon">
        <img src="./assets/login-logo.png" alt="BRNKL logo" />
      </div>
      <form
        (submit)="loginWithEmail()"
        [formGroup]="loginForm"
        class="login_form"
      >
        <ion-item>
          <ion-label position="stacked">Email</ion-label>
          <ion-input
            class="login_input"
            type="email"
            formControlName="email"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Password</ion-label>
          <div class="login-password">
          <ion-input
            class="login_input"
            type="{{ showPassword ? 'text' : 'password' }}"
            formControlName="password"
          ></ion-input>
          <ion-button slot="end" fill="clear" (click)="handleShowPassword()" class="show-password-icon" align-self-center>
            <ion-icon
              *ngIf="showPassword==true"
              name="eye-outline"
            ></ion-icon>
            <ion-icon
              *ngIf="showPassword==false"
              name="eye-off-outline"
            ></ion-icon>
          </ion-button>
        </div>
        </ion-item>
        <ion-button
          type="submit"
          [disabled]="!loginForm.valid"
          class="login_btn login_btn_submit"
        >
          Login
        </ion-button>
      </form>
      <a
        href=""
        role="button"
        (click)="openForgetPasswordModal($event)"
        class="login_create-link"
      >
        Forgot Password
      </a>
      <hr class="login-divider" />
      <ion-button (click)="openCreatePage()" class="create_account_btn">
        Create Account
      </ion-button>
      <!-- <button ion-button (click)="demoLogin()" class="login_btn">
        Demo
      </button> -->
      <div class="login_version">
        <div>
          Version:
          <span>v{{appVersion}}</span>
          <span *ngIf="!isProd">(Staging)</span>
        </div>
      </div>
    </div>
  </div>
</ion-content>
