import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'

@Component({
  selector: 'setup-service-box',
  templateUrl: './setup-service-box.component.html',
  styleUrls: ['./setup-service-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupServiceBox {
  @Output() next = new EventEmitter()

  @Input() header: string
  @Input() title: string
  @Input() iconName: string
  @Input() currency: string
  @Input() amount: string
  @Input() interval: string
}
