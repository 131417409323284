import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent {
  public passwordForm: FormGroup
  minPasswordLen: number = 8
  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.passwordForm = this.formBuilder.group(
      {
        password: [
          '',
          [Validators.required, Validators.minLength(this.minPasswordLen)],
        ],
        confirmPassword: ['', Validators.required],
      },
      { validator: (group: FormGroup) => this.validateGroup(group) }
    )
  }

  validateGroup(group: FormGroup): any {
    return !this.passwordForm
      ? null
      : this.passwordForm.controls.password.value ===
        this.passwordForm.controls.confirmPassword.value
      ? null
      : { notSame: true }
  }

  get showNoMatch(): boolean {
    return !this.passwordForm
      ? false
      : this.passwordForm.hasError('notSame') &&
          this.passwordForm.controls.password.dirty &&
          this.passwordForm.controls.confirmPassword.dirty
  }

  get showTooShort(): boolean {
    return !this.passwordForm
      ? false
      : this.passwordForm.controls.password.hasError('minlength')
  }
}
