import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { HelpersProvider } from '../../services/helpers/helpers.service'
import { SettingsProvider } from '../../services/settings/settings.service'

import { Stat } from '../../models-shared/stat.model'
import { ZigbeeData } from '../../models/zigbee-data.model'
import { WirelessSensorPairingStatus } from '../../models/wireless-sensor-pairing-status.model'

import { extractZigbeeData, isAlarm1Active } from './zigbee-data.service'
import { BluetoothLEAdapter } from './ble-adapter.service'
import { CliResponseQueue } from './cli-response-queue.service'

import { delay, debugLog } from '../../util/util'
import { DfuResult } from '../../models/dfu-result.model'
import { DfuPlugin } from '../../models/dfu-plugin.model'
import { EntryExitDelay } from '../../models-shared/entry-exit-delay.model'

const mateServiceBaseUUID = (id: string) =>
  `BDB7${id}-202B-11EA-A5E8-2E728CE88125`
const mateServiceUUID: string = mateServiceBaseUUID('0001')
const dataCharacteristicUUID: string = mateServiceBaseUUID('0002')
const armedCharacteristicUUID: string = mateServiceBaseUUID('0003')
const armedControlCharacteristicUUID: string = mateServiceBaseUUID('0004')

const armedCharacteristicOpCode = 0x1
const mateAllZonesArmed = 0x3
const mateAllZonesDisarmed = 0x0

const nordicBaseServiceUUID = (id: string) =>
  `6E40${id}-B5A3-F393-E0A9-E50E24DCCA9E`
const nordicServiceUUID: string = nordicBaseServiceUUID('0001')
const nordicWriteCharactertisticUUID = nordicBaseServiceUUID('0002')
const nordicReadCharactertisticUUID = nordicBaseServiceUUID('0003')
const nullInstallCode: string = '000000000000000000000000000000000000'

declare var DfuUpdate: DfuPlugin
const dfuServiceUUID = 'FE59'

@Injectable({
  providedIn: 'root',
})
export class BluetoothLEProvider {
  public connected: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public sensorData: BehaviorSubject<{
    [macAddress: string]: Stat<any>
  }> = new BehaviorSubject({})
  private address: string
  private cachedData: { [macAddress: string]: Stat<any> } = {}

  private cliResponseQueue = new CliResponseQueue()
  private armedControlReponseQueue: Uint8Array[] = []

  constructor(
    private bluetoothAdapter: BluetoothLEAdapter,
    private helpers: HelpersProvider,
    private settings: SettingsProvider
  ) {}

  public connectToMate = async (
    mateId: string,
    mateFoundCallback?: () => Promise<void>
  ) => {
    this.cliResponseQueue.clear()

    await this.bluetoothAdapter.init()
    debugLog(`Initialized BLE`)

    this.address = await this.bluetoothAdapter.scanForMate(mateId, [
      mateServiceUUID,
    ])

    console.log('address', this.address)

    if (!this.address) {
      console.log('Unable to find mate')
    }

    debugLog(`Scanned and found mate: ${this.address}`)

    if (mateFoundCallback) {
      await mateFoundCallback()
    }

    await this.bluetoothAdapter.connect(this.address, () =>
      this.handleDisconnection(false)
    )

    debugLog(`Connected to mate`)
    await this.bluetoothAdapter.discover(this.address)
    debugLog(`Discovered characteristics for mate`)

    await this.readArmedState()
    debugLog(`Read mate's current armed state`)

    try {
      await this.subscribeToCharacteristics()
    } catch (err) {
      debugLog(`Failed to subscribe to characteristics`)
      try {
        await this.disconnectFromMate(false)
      } catch (err) {
        debugLog(err.message)
        // Fail silently if disconnect fails
      }
      throw err
    }

    this.clearCacheData()
    debugLog('Updated stats grid')

    this.connected.next(true)
  }

  public disconnectFromMate = async (showErrorToast: boolean) => {
    await this.bluetoothAdapter.disconnect(this.address)
    this.handleDisconnection(showErrorToast)
  }

  public changeKeypadPin = async (pin: number): Promise<void> => {
    await this.writeToMateCLI(`mate pin ${pin}\r`)

    const response = await this.getMateCliResponse()
    if (response !== 'Done') {
      throw new Error(`Unable to change wireless keypad pin ${response}`)
    }
  }

  public changeEntryExitDelay = async (
    delay: EntryExitDelay
  ): Promise<void> => {
    await this.writeToMateCLI(`mate cie delay ${delay}\r`)

    const response = await this.getMateCliResponse()
    if (response !== 'Done') {
      throw new Error(
        `Unable to change wireless keypad entry/exit delay ${response}`
      )
    }
  }

  public testMateBRNKLConnection = async (): Promise<boolean> => {
    await this.writeToMateCLI(`mate brnkl\r`)
    const response = await this.getMateCliResponse()
    if (response === 'Connected') {
      const doneResponse = await this.getMateCliResponse()
      if (doneResponse === 'Done') {
        return true
      }
    }

    this.cliResponseQueue.clear()
    return false
  }

  public testMateSatelliteConnection = async (): Promise<string> => {
    this.writeToMateCLI(`mate sat check\r`)
    await delay(8000)
    await this.writeToMateCLI(`mate sat status\r`)
    let response = await this.getMateCliResponse()
    do {
      await this.writeToMateCLI(`mate sat status\r`)
      response = await this.getMateCliResponse()
    } while (!response.includes('Modem check complete'))
    const result = response.split('res:')[1]
    return result
  }

  public sendSatelliteSignal = async (): Promise<void> => {
    this.writeToMateCLI(`mate sat tx\r`)
  }

  // Restart the Mate - does not give a response and disconnects mate bluetooth connection
  public restartMateBRNKLConnection = (): void => {
    this.writeToMateCLI(`mate system restart\r`)
  }

  // Step 1 of adding a wireless sensor
  public addWirelessSensorReadyNetwork = async (
    installCode: string,
    macAddress: string
  ) => {
    //The first batch of leedarson sensors don't have install codes. We're going to
    //create data matrices for them that return an install code of only zeros.
    //For those sensors, we skip the add step in the pairing process.
    if (installCode !== nullInstallCode) {
      debugLog(`Add Wireless Sensor: IS -  ${installCode} MAC - ${macAddress}`)
      await this.writeToMateCLI(`bdb ic add ${installCode} ${macAddress}\r`)

      const addResponse = await this.getMateCliResponse()
      if (addResponse !== 'Done') {
        throw new Error(
          `Unable to add wireless sensor to Mate '${addResponse}'`
        )
      }
      await delay(1000)
    }

    debugLog(`Add Wireless Sensor: Opening Network`)
    await this.writeToMateCLI(`bdb open\r`)
    const openResponse = await this.getMateCliResponse()
    if (openResponse !== 'Done') {
      throw new Error(`Unable to open sensor network: ${openResponse}`)
    }

    await delay(1000)

    debugLog(`Add Wireless Sensor: Verifying Network State`)
    await this.writeToMateCLI(`bdb state\r`)
    const stateOpenResponse = await this.getMateCliResponse()
    if (stateOpenResponse !== 'open') {
      throw new Error(`Sensor network failed to open: ${stateOpenResponse}`)
    }

    const stateDoneResponse = await this.getMateCliResponse()
    if (stateDoneResponse !== 'Done') {
      throw new Error(
        `Sensor network failed to finish opening: ${stateDoneResponse}`
      )
    }
  }

  // Step 2 of adding a wireless sensor
  // Should be called after addWirelessSensorReadyNetwork
  public getSensorPairingStatus = async (
    macAddress: string
  ): Promise<WirelessSensorPairingStatus> => {
    const command = `zdo status ${macAddress}\r`
    await this.writeToMateCLI(command)

    const response = await this.getMateCliResponse()

    if (response.startsWith('Error')) {
      throw new Error(`Failed to join wireless sensor: ${response}`)
    }

    const doneResponse = await this.getMateCliResponse()
    if (doneResponse !== 'Done') {
      throw new Error(`Failed to get sensor status: ${doneResponse}`)
    }

    if (response === 'no device found') {
      return WirelessSensorPairingStatus.DeviceNotAdded
    } else if (response === 'waiting for device auth') {
      return WirelessSensorPairingStatus.Initializing
    } else if (response === 'getting device details') {
      return WirelessSensorPairingStatus.GettingDetails
    } else if (response === 'waiting for security device') {
      return WirelessSensorPairingStatus.Finalizing
    } else if (response === 'join complete') {
      return WirelessSensorPairingStatus.Done
    } else {
      return WirelessSensorPairingStatus.Unknown
    }
  }

  public removeWirelessSensorFromNetwork = async (macAddress: string) => {
    const command = `zdo mgmt_leave ${macAddress}\r`
    await this.writeToMateCLI(command)
    const response = await this.getMateCliResponse()

    if (response === 'Error: device has already left') {
      // Sensor has already been removed from mate
      return
    } else if (response === 'Error: mgmt_leave request timed out') {
      // Sensor will be removed from mate next time it connects to the mate
      return
    } else if (response !== 'Done') {
      throw new Error(`Failed to remove wireless sensor: ${response}`)
    }
  }

  public prepareForDFU = async (mateId: string): Promise<string> => {
    // Attempt to Connect to Mate
    if (!this.connected.value) {
      try {
        await this.connectToMate(mateId)
      } catch {
        // Mate may not be found due to DFU mode
      }
    }

    // Mate sucessfully connected
    if (this.connected.value) {
      const address = this.address
      await this.disconnectFromMate(false)
      return address
    }

    // Mate is in DFU mode
    const address = await this.bluetoothAdapter.scan([dfuServiceUUID])
    return address
  }

  public performDFU = async (
    firmwareUrl: string,
    mateAddress: string,
    progressHook: Function
  ) => {
    if (this.connected.value) {
      throw new Error('Mate must be disconnected before performing a DFU')
    }

    return new Promise<void>((resolve, reject) => {
      DfuUpdate.updateFirmware(
        (updateObj: DfuResult) => {
          const { status, progress = false } = updateObj
          if (status === 'dfuCompleted') {
            resolve()
          }

          if (progress) {
            progressHook(progress.percent)
          }
        },
        (error) => {
          reject(error)
        },
        {
          fileUrl: firmwareUrl,
          deviceId: mateAddress,
        }
      )
    })
  }

  public setIsArmed = async (isArmedStatus: boolean) => {
    this.clearArmedControlReponseQueue()

    const mateIsArmedStatus = isArmedStatus
      ? mateAllZonesArmed
      : mateAllZonesDisarmed

    await this.bluetoothAdapter.writeToCharacteristicBytes(
      this.address,
      mateServiceUUID,
      armedControlCharacteristicUUID,
      new Uint8Array([armedCharacteristicOpCode, mateIsArmedStatus])
    )

    const response = await this.getNextArmedControlResponse()
    if (response.length < 3) {
      throw new Error(`Mate returned not enough bytes ${response.length}`)
    }

    if (response[0] !== 16) {
      throw new Error(`Wrong response OP code returned ${response[0]}`)
    }

    if (response[1] !== 1) {
      throw new Error(`Wrong original OP code returned ${response[1]}`)
    }

    if (response[2] !== 1) {
      throw new Error(`Failed to update Mate's isArmed status ${response[2]}`)
    }
  }

  private subscribeToCharacteristics = async () => {
    await this.bluetoothAdapter.subscribeToCharacteristic(
      this.address,
      mateServiceUUID,
      dataCharacteristicUUID,
      this.handleSensorData
    )
    debugLog(`Subscribed to data characteristic`)

    await this.bluetoothAdapter.subscribeToCharacteristic(
      this.address,
      mateServiceUUID,
      armedCharacteristicUUID,
      this.handleArmedChange
    )
    debugLog(`Subscribed to armed characteristic`)

    await this.bluetoothAdapter.subscribeToCharacteristic(
      this.address,
      mateServiceUUID,
      armedControlCharacteristicUUID,
      this.handleArmedControlResponse
    )
    debugLog(`Subscribed to armed control characteristic`)

    await this.bluetoothAdapter.subscribeToCharacteristic(
      this.address,
      nordicServiceUUID,
      nordicReadCharactertisticUUID,
      this.handleCliOutput,
      this.cliResponseQueue.handleCLIRejection
    )
    debugLog(`Subscribed to CLI output characteristic`)
  }

  private writeToMateCLI = async (message: string): Promise<void> => {
    this.cliResponseQueue.clear()
    await this.bluetoothAdapter.writeToCharacteristic(
      this.address,
      nordicServiceUUID,
      nordicWriteCharactertisticUUID,
      message
    )

    debugLog(`CLI Input: ${message}`)
  }

  private getMateCliResponse = (): Promise<string> =>
    this.cliResponseQueue.dequeue()

  private handleCliOutput = (bytes: Uint8Array) => {
    const rawResponse = this.bluetoothAdapter.bytesToString(bytes)

    debugLog(`CLI Raw Output: '${rawResponse}'`)

    if (rawResponse.length > 0) {
      this.cliResponseQueue.enqueue(rawResponse)
    }
  }

  private handleDisconnection = (showErrorToast: boolean) => {
    if (showErrorToast) {
      this.helpers.showInfiniteDangerToast(
        'Lost bluetooth connection to the Mate'
      )
    }

    this.clearCacheData()
    this.connected.next(false)
    this.address = ''
    this.cliResponseQueue.handleCLIRejection(new Error('Mate disconnected'))
  }

  private handleSensorData = async (bytes: Uint8Array): Promise<void> => {
    let zigbeeData: ZigbeeData = null
    try {
      zigbeeData = extractZigbeeData(bytes)
    } catch (err) {
      debugLog(`Sensor Data Error: ${err.message}`)
      return
    }

    debugLog(`Received Data from Sensor: ${zigbeeData.macAddress}`)
    debugLog(`Data: ${Buffer.from(bytes).toString('hex')}`)

    const val = isAlarm1Active(zigbeeData.zoneStatus)

    const datetime: number = Math.round(new Date().getTime() / 1000)
    const stat: Stat<any> = {
      val,
      datetime,
      isWireless: true,
      temp: zigbeeData.temperature,
      batt: zigbeeData.batteryPercent,
    }

    this.cachedData[zigbeeData.macAddress] = stat
    this.sensorData.next(this.cachedData)
  }

  private handleArmedChange = async (bytes: Uint8Array) => {
    const newIsArmed: boolean = bytes[0] > mateAllZonesDisarmed
    if (this.hasIsArmedStateChanged(newIsArmed)) {
      debugLog(`Updating Armed State to ${newIsArmed}`)
      await this.settings.setIsArmed(newIsArmed, true)
    }
  }

  private readArmedState = async () => {
    try {
      const bytes = await this.bluetoothAdapter.readCharacteristic(
        this.address,
        mateServiceUUID,
        armedCharacteristicUUID
      )
      const newIsArmed: boolean = bytes[0] > mateAllZonesDisarmed
      if (this.hasIsArmedStateChanged(newIsArmed)) {
        debugLog(`Setting armed state to ${newIsArmed}`)
        await this.settings.setIsArmed(newIsArmed, true)
      }
    } catch (err) {
      debugLog(`Error reading mate armed state ${err.message}`)
    }
  }

  private clearCacheData() {
    this.cachedData = {}
    this.sensorData.next(this.cachedData)
  }

  private handleArmedControlResponse = async (bytes: Uint8Array) => {
    this.armedControlReponseQueue.push(bytes)
  }

  private getNextArmedControlResponse = async (): Promise<Uint8Array> => {
    const attempts = 10 // 2 second timeout
    for (let i = 0; i < attempts; i++) {
      if (this.armedControlReponseQueue.length > 0) {
        return this.armedControlReponseQueue[0]
      } else {
        await delay(200)
      }
    }

    throw new Error('Failed to receive armed update response from Mate')
  }
  private clearArmedControlReponseQueue = () => {
    while (this.armedControlReponseQueue.length > 0) {
      this.armedControlReponseQueue.pop()
    }
  }

  private hasIsArmedStateChanged = (newIsArmed: boolean) => {
    const settings = this.settings.deviceSettings$.value
    if (!settings) {
      // No settings are available, assume update
      return true
    }

    return settings.isArmed !== newIsArmed
  }
}
