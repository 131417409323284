<ion-header>
  <ion-toolbar class="toolbar-background">
    <ion-buttons slot="start">
      <ion-back-button
        *ngIf="!isRoot()"
        class="toolbar-button"
      ></ion-back-button>
    </ion-buttons>
    <ion-title class="toolbar-title">Add to fleet</ion-title>
    <ion-buttons slot="primary">
      <ion-button
        *ngIf="isRoot()"
        (click)="logout()"
        class="toolbar-button"
        color="danger"
      >
        Logout
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="share-device_bg" *ngIf="status === 0">
  <div class="share-device_container">
    <ion-card class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="share-device-header"
          >You've been invited to share {{ deviceName }}.</ion-card-title
        >
      </ion-card-header>
      <ion-card-content>
        <p class="share-device-text">
          Owner Name invited you to share. Do you want to add {{ deviceName }} to
          your fleet?
        </p>
        <div class="add-device_btn-container">
          <ion-button (click)="linkDevice()" icon-end>
            Add BRNKL
            <ion-icon name="add"></ion-icon>
          </ion-button>
        </div>
      </ion-card-content>
    </ion-card>
    <div #scrollPoint class="share-device_bottom"></div>
  </div>
</ion-content>
<ion-content class="share-device_bg" *ngIf="status === 1">
  <div class="share-device_container">
    <ion-card class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="share-device-header"
          >This invite was sent to an email address different from your BRNKL account email.</ion-card-title
        >
      </ion-card-header>
      <ion-card-content>
        <p class="share-device-text">
          To share DeviceName, ask ownerName to send an invite to the email address linked to your BRNKL account.
        </p>
      </ion-card-content>
    </ion-card>
    <div #scrollPoint class="share-device_bottom"></div>
  </div>
</ion-content>
<ion-content class="share-device_bg" *ngIf="status === 2">
  <div class="share-device_container">
    <ion-card class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="share-device-header"
          >This invitation has expired.</ion-card-title
        >
      </ion-card-header>
      <ion-card-content>
        <p class="share-device-text">
          To share {{deviceName}}, ask the owner to send the invite again.
        </p>
      </ion-card-content>
    </ion-card>
    <div #scrollPoint class="share-device_bottom"></div>
  </div>
</ion-content>
