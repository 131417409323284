import { Component, OnInit } from '@angular/core'
import { AlertController } from '@ionic/angular'
import { NavController } from '@ionic/angular'

import { AlertsProvider } from '../../services/alerts/alerts.service'
import { AppAlert } from '../../models/app-alert.model'

import { AuthProvider } from '../../services/auth/auth.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { SettingsProvider } from '../../services/settings/settings.service'
import { Router } from '@angular/router'
import { VideosProvider } from 'app/services/videos/videos.service'
import { DeviceProvider } from 'app/services/device/device.service'

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.page.html',
  styleUrls: ['./alert-details.page.scss'],
})
export class AlertDetailsPage implements OnInit {
  alert: AppAlert
  alertId: string
  alertDate: string
  videoUrl: string
  isVideoUrlLoaded: boolean = false
  thumbnail: any
  cameraName: string

  constructor(
    private navCtrl: NavController,
    private alertsProvider: AlertsProvider,
    private settingsProvider: SettingsProvider,
    private auth: AuthProvider,
    private helpers: HelpersProvider,
    private alertsController: AlertController,
    private videosProvider: VideosProvider,
    private device: DeviceProvider,
    private router: Router
  ) {
    let navParams = this.router.getCurrentNavigation().extras.state
    this.alert = navParams['alert']
    this.alertId = navParams['alertId']
    this.alertDate = navParams['alertDate']
    this.thumbnail = navParams['thumbnail']
    this.cameraName = navParams['cameraName']
  }

  async ngOnInit(): Promise<void> {
    if (this.alert.video) {
      const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
      this.videoUrl = await this.videosProvider
        .getVideoUrl(deviceId, this.alert.video.uuid, this.alert.video.fileName)
        .then((res: any) => res.url)
    }
  }

  async showClearAlertDialog(alert: AppAlert): Promise<void> {
    this.alertsController
      .create({
        header: 'Clear Alert?',
        subHeader: 'Are you sure you would like to clear this alert?',
        buttons: [
          {
            text: 'Confirm',
            handler: () => {
              this.clearAlert(alert, this.alertId)
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
          },
        ],
      })
      .then((confirmAlert) => confirmAlert.present())
  }

  clearAlert(alert: AppAlert, alertId: string): void {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Alert cannot be cleared in demo mode.')
      return
    }

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    this.alertsProvider.clearAlert(alert, alertId)
    this.navCtrl.pop()
  }
}
