import { Component, ViewEncapsulation } from '@angular/core'
import {
  BarcodeScannerOptions,
  BarcodeScanner,
  BarcodeScanResult,
} from '@ionic-native/barcode-scanner/ngx'
import { Insomnia } from '@ionic-native/insomnia/ngx'
import { ModalController, NavParams } from '@ionic/angular'
import { Subscription } from 'rxjs'

import { SetupStep } from '../../models/setup-step.model'
import { WirelessSensorInfo } from '../../models/wireless-sensor-info.model'
import { WirelessSensorPairingStatus } from '../../models/wireless-sensor-pairing-status.model'
import { WirelessSensorDisplay } from '../../models/wireless-sensor-display.model'

import { BaseSetupModalComponent } from '../../components/base-setup-modal/base-setup-modal.component'
import { SettingsAddDeviceModal } from '../../components/settings-add-device-modal/settings-add-device-modal.component'

import { DeviceProvider } from '../../services/device/device.service'
import { SettingsProvider } from '../../services/settings/settings.service'
import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'

import { delay } from '../../util/util'

const scannerOptions: BarcodeScannerOptions = {
  preferFrontCamera: false,
  formats: 'DATA_MATRIX,QR_CODE',
  resultDisplayDuration: 0,
}

@Component({
  selector: 'add-wireless-sensor-modal',
  templateUrl: './add-wireless-sensor-modal.component.html',
  styleUrls: ['./add-wireless-sensor-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddWirelessSensorModalComponent extends BaseSetupModalComponent {
  scanInfo: SetupStep = {
    name: 'scanInfo',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: true,
    exitButton: true,
  }

  find: SetupStep = {
    name: 'find',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }

  init: SetupStep = {
    name: 'init',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }

  activate: SetupStep = {
    name: 'activate',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }

  name: SetupStep = {
    name: 'name',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: true,
  }

  added: SetupStep = {
    name: 'added',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }

  repeat: SetupStep = {
    name: 'repeat',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }

  scanningBarcode: boolean = false
  selectedSensorDisplay: WirelessSensorDisplay
  selectedSensorInfo: WirelessSensorInfo
  progressStep: string = 'Initializing'
  progressPercent: number = 20
  progressHeader: string = 'Connecting'
  progressDescription: string = 'Connecting your sensor to BRNKL'
  progressIconName: string = 'custom-wireless'
  doneButtonText: string = ''
  findHeader: string = 'Finding'
  findDescription: string = 'Finding the sensor'
  pairingStatus: WirelessSensorPairingStatus
  addedHeader: string = 'Added'
  addedDescription: string

  private refreshSettings: () => void
  private subscriptions: Subscription[] = []

  constructor(
    private barcodeScanner: BarcodeScanner,
    private helpers: HelpersProvider,
    public viewCtrl: ModalController,
    private navParams: NavParams,
    private bluetoothLEProvider: BluetoothLEProvider,
    private settings: SettingsProvider,
    private device: DeviceProvider,
    private insomnia: Insomnia
  ) {
    super(viewCtrl)
    this.selectedSensorDisplay = this.navParams.get('selectedSensor')
    this.configure()
    this.subscribe()
    this.refreshSettings = this.navParams.get('refreshSettings')
  }

  subscribe() {
    this.subscriptions.push(
      this.bluetoothLEProvider.connected.subscribe((isConnected: boolean) => {
        if (!isConnected) {
          // Mate disconnected
          this.exit()
        }
      })
    )
  }

  ngOnInit() {
    this.insomnia.keepAwake()
  }

  ngOnDestroy() {
    this.insomnia.allowSleepAgain()
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  onExitPressed(): void {
    this.exit()
  }

  onBackPressed(): void {
    if (this.currentStep.name === 'scanInfo') {
      this.openAddDeviceModal()
    } else {
      this.back()
    }
  }

  async scanDataMatrix(): Promise<BarcodeScanResult> {
    if (this.scanningBarcode) {
      return
    }
    this.scanningBarcode = true

    try {
      const result = await this.barcodeScanner.scan(scannerOptions)
      this.scanningBarcode = false
      return result
    } catch (err) {
      this.scanningBarcode = false
      throw err
    }
  }

  onProgressBtnClick = () => {
    if (this.progressPercent === 100) {
      this.pushStep(this.name)
    }
  }

  parseBarcodeScanResult(text: string): WirelessSensorInfo {
    const wirelessSensorReg = /Z:([A-Za-z0-9]+)\$I:([A-Za-z0-9]+)%/g
    const sengledReg = /%M:([A-Za-z0-9]+)/g
    const linkindReg = /([A-Za-z0-9]+)\|([A-Za-z0-9]+)/
    const vmarkTempReg = /[A-Za-z]+\|([A-Za-z0-9]+)\|([A-Za-z0-9]*)/
    // check if sensor is V-Mark or Linkind with different QR code format
    const linkind = linkindReg.test(text)
    const vmarkTemp = vmarkTempReg.test(text)

    if (vmarkTemp) {
      const matches = vmarkTempReg.exec(text)
      return {
        macAddress: matches[1],
        installCode: '83FED3407A939723A5C639B26916D505C3B5',
        manufacturer: 'V-Mark',
      }
    } else if (linkind) {
      const matches = linkindReg.exec(text)
      return {
        macAddress: matches[1],
        installCode: matches[2],
        manufacturer: 'Linkind',
      }
    } else {
      // otherwise grab macAddress and install code from general QR code format
      const matches = wirelessSensorReg.exec(text)
      const sengled = sengledReg.test(text)
      const leederson = +matches[2] === 0
      let manufacturer = sengled
        ? 'Sengled'
        : leederson
        ? 'Leedarson'
        : 'Samsung'
      return {
        macAddress: matches[1],
        installCode: matches[2],
        manufacturer,
      }
    }
  }

  setProgressStepDetails = (
    percent?: number,
    step?: string,
    iconName?: string,
    header?: string,
    description?: string,
    doneButtonText?: string
  ) => {
    this.progressPercent = percent
    this.progressStep = step
    this.progressIconName = iconName
    this.progressHeader = header
    this.progressDescription = description
    this.doneButtonText = doneButtonText
  }

  async pair(attempts: number): Promise<void> {
    for (let i = 0; i < attempts; i++) {
      this.pairingStatus =
        await this.bluetoothLEProvider.getSensorPairingStatus(
          this.selectedSensorInfo.macAddress
        )

      this.setPairingStatus(this.pairingStatus)

      if (this.pairingStatus === WirelessSensorPairingStatus.Done) {
        return
      }

      await delay(500)
    }

    throw new Error('Pairing Timed Out')
  }

  onProgressCancel = () => {
    if (this.currentStep.name === 'added') {
      this.pushStep(this.repeat)
    }
  }

  openAddDeviceModal = () => {
    this.helpers.showModal(
      SettingsAddDeviceModal,
      {
        refreshSettings: this.refreshSettings,
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }

  configure() {
    this.scanInfo.next = async () => {
      let result: BarcodeScanResult
      try {
        result = await this.scanDataMatrix()
      } catch (e) {
        this.helpers.showInfiniteToast(
          'BRNKL needs to use the camera on this device to scan. In the device Settings, allow BRNKL access to the camera.'
        )
        return
      }

      if (!result) {
        return
      }

      if (result.cancelled) {
        return
      }

      try {
        this.selectedSensorInfo = this.parseBarcodeScanResult(result.text)

        this.pushStep(this.find)
      } catch {
        this.helpers.showInfiniteDangerToast(
          'Invalid QR Code. Please contact BRNKL support.'
        )
        return
      }

      try {
        const pairStatus =
          await this.bluetoothLEProvider.getSensorPairingStatus(
            this.selectedSensorInfo.macAddress
          )
        if (pairStatus !== WirelessSensorPairingStatus.DeviceNotAdded) {
          // Sensor is already pairing/paired
          this.findHeader = 'Found'
          this.findDescription = 'Sensor Found'
          await delay(2000)
          await this.setupProgressPage()
          return
        }

        await this.bluetoothLEProvider.addWirelessSensorReadyNetwork(
          this.selectedSensorInfo.installCode,
          this.selectedSensorInfo.macAddress
        )
        await delay(1000)
        this.findHeader = 'Found'
        this.findDescription = 'Sensor Found'

        await delay(2000)
        this.pushStep(this.init)
      } catch (err) {
        this.helpers.showInfiniteDangerToast(
          `Failed to find sensor ${err.message}`
        )
        // reset process
        this.openAddDeviceModal()
      }
    }

    this.init.next = async () => {
      return this.setupProgressPage()
    }

    this.name.next = async (
      selectedName: string,
      sensorDisplayInfo: WirelessSensorDisplay
    ) => {
      try {
        this.helpers.startLoading('Adding Sensor')

        if (!sensorDisplayInfo.type) {
          throw new Error('sensorType not found')
        }

        const response = await this.settings.addWirelessSensor(
          this.device.currentBRNKLandMateId$.value.mateId,
          selectedName,
          sensorDisplayInfo.type,
          this.selectedSensorInfo.macAddress.toUpperCase(),
          this.selectedSensorInfo.installCode,
          this.selectedSensorInfo.manufacturer
        )

        if (!response.success) {
          throw new Error('Database request did not return a success')
        }

        if (response.data && response.data.sensorNameUpdatedOnly) {
          this.helpers.showToast('Updated sensor name')
        }

        await delay(2000)
        await this.helpers.stopLoading()
        this.refreshSettings()
        this.addedDescription = `${selectedName} sensor added to BRNKL.`
        this.pushStep(this.added)
      } catch (err) {
        await this.helpers.stopLoading()
        this.helpers.showInfiniteDangerToast(
          `An error occured when adding a device: ${err.message}`
        )
      }
    }

    this.repeat.next = () => {
      this.openAddDeviceModal()
    }

    //Set first step
    this.pushStep(this.scanInfo)
  }

  private setPairingStatus(pairingStatus: WirelessSensorPairingStatus) {
    if (pairingStatus === WirelessSensorPairingStatus.DeviceNotAdded) {
      this.setProgressStepDetails(
        20,
        'Initializing',
        'custom-wireless',
        'Connecting',
        `Connecting your sensor to BRNKL`,
        ''
      )
    } else if (pairingStatus === WirelessSensorPairingStatus.Initializing) {
      this.setProgressStepDetails(
        40,
        'Joining',
        'custom-wireless',
        'Connecting',
        `Connecting your sensor to BRNKL`,
        ''
      )
    } else if (pairingStatus === WirelessSensorPairingStatus.GettingDetails) {
      this.setProgressStepDetails(
        60,
        'Connecting',
        'custom-wireless',
        'Connecting',
        `Connecting your sensor to BRNKL`,
        ''
      )
    } else if (pairingStatus === WirelessSensorPairingStatus.Finalizing) {
      this.setProgressStepDetails(
        80,
        'Registering',
        'custom-wireless',
        'Connecting',
        `Connecting your sensor to BRNKL`,
        ''
      )
    } else if (pairingStatus === WirelessSensorPairingStatus.Done) {
      this.setProgressStepDetails(
        100,
        'Connected',
        'custom-wireless',
        'Connected',
        `Sensor connected to BRNKL`,
        'Next'
      )
    }

    // If unknown don't update the progress bar (intermediate step)
  }

  private setupProgressPage = async () => {
    this.pushStep(this.activate)

    try {
      await this.pair(120)
    } catch (err) {
      this.helpers.showInfiniteDangerToast(
        `Failed to pair device: ${err.message}`
      )
      // reset process
      this.openAddDeviceModal()
    }
  }
}
