import { Component, EventEmitter, Output, ViewChild } from '@angular/core'
import { ModalController } from '@ionic/angular'

import { DeviceProvider } from '../../services/device/device.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { SetPasswordComponent } from '../set-password/set-password.component'

@Component({
  selector: 'set-password-modal',
  templateUrl: './set-password-modal.component.html',
  styleUrls: ['./set-password-modal.component.scss'],
})
export class SetPasswordModalComponent {
  @Output() saveClick: EventEmitter<string> = new EventEmitter()
  @ViewChild(SetPasswordComponent) setPassword: SetPasswordComponent
  constructor(
    private viewCtrl: ModalController,
    private deviceProvider: DeviceProvider,
    private helpers: HelpersProvider
  ) {}

  dismiss(): Promise<any> {
    return this.viewCtrl.dismiss()
  }

  save(): void {
    this.helpers.startLoading('Saving password...')
    this.deviceProvider
      .changePassword(this.setPassword.passwordForm.controls.password.value)
      .then(() => {
        this.helpers.stopLoading()
        this.dismiss()
        this.helpers.showToast('Success!')
      })
      .catch((err) => {
        this.helpers.stopLoading()
        this.dismiss()
        this.helpers.showDangerToast('Failed to save')
      })
  }
}
