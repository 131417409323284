import { select } from '@angular-redux/store'
import { Component, ViewEncapsulation } from '@angular/core'
import { DeviceProvider } from '../../services/device/device.service'
import { Observable } from 'rxjs'

import { AppDevice } from '../../models/app-device.model'
import { NavController } from '@ionic/angular'

@Component({
  selector: 'top-bar-title',
  templateUrl: './top-bar-title.component.html',
  styleUrls: ['./top-bar-title.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopBarTitleComponent {
  @select('currentDevice')
  currentDevice$: Observable<AppDevice>

  constructor(private device: DeviceProvider, private navCtrl: NavController) {}

  goToFleetView(): void {
    this.navCtrl.navigateRoot('loading')
    this.device.setCurrentDeviceId(null)
  }
}
