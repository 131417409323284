import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { NavController } from '@ionic/angular'

import { Tab } from '../../models/tab.model'
import { DeviceProvider } from '../../services/device/device.service'
import { DeviceStatus } from '../../models-shared/device-status'
import { UpdateMateProvider } from '../../services/update-mate/update-mate.service'
import { HelpersProvider } from 'app/services/helpers/helpers.service'
import { isBlueDevice } from 'app/util'

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabsPage implements OnInit {
  tabs: Tab[]
  deviceType: string

  constructor(
    public navCtrl: NavController,
    private device: DeviceProvider,
    private helpers: HelpersProvider,
    // Not used here but to ensure UpdateMateProvider
    // is constructed for mate update check on vessel load
    updateMate: UpdateMateProvider
  ) {
    this.displaySubscribeAlert()
  }

  async ngOnInit() {
    this.tabs = this.device.isBlueDevice()
      ? [
          { page: 'map', name: 'Map', icon: 'map' },
          { page: 'graphs', name: 'History', icon: 'analytics' },
          { page: 'videos', name: 'Video', icon: 'videocam' }, //Change to video if device type is BBLK
          { page: 'controls', name: 'Controls', icon: 'toggle-sharp' },
          { page: 'settings', name: 'Settings', icon: 'cog' },
        ]
      : [
          { page: 'map', name: 'Map', icon: 'map' },
          { page: 'graphs', name: 'Graphs', icon: 'analytics' },
          { page: 'photos', name: 'Photos', icon: 'camera' },
          { page: 'alerts', name: 'Alerts', icon: 'alert-circle-sharp' },
          { page: 'settings', name: 'Settings', icon: 'cog' },
        ]
  }

  async displaySubscribeAlert() {
    const deviceId = this.device.currentBRNKLandMateId$.getValue().deviceId
    const status: DeviceStatus = await this.device.deviceStatus(deviceId)

    if ((status != null && status.subscribed) || isBlueDevice(deviceId)) {
      return
    } else if (status != null && !status.subscribed) {
      this.helpers.showDangerToast(
        'This BRNKL does not have an active subscription! Please subscribe in the BRNKL tab of the settings page.',
        15
      )
    }
  }
}
