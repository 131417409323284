import { AnalogSensor, AnalogSensorTypes } from './analog-sensor.model'
import { SensorTypes } from './sensor-types'

export function DefaultBattery(name: string, key: string): AnalogSensor {
  return {
    type: SensorTypes.Analog,
    analogType: AnalogSensorTypes.Battery,
    name,
    slotName: name,
    key,
    enabled: true,
    showOnGrid: true,
    unit: 'V',
    nDecimals: 2,
    offset: 0,
    lowValue: 10,
    highValue: 16,
    steppedLine: false,
  }
}

// export function DefaultInternalBattery(): AnalogSensor {
//   return {
//     type: SensorTypes.Analog,
//     analogType: AnalogSensorTypes.Battery,
//     name: 'Internal Battery',
//     key: 'internalbattery',
//     enabled: false,
//     showOnGrid: false,
//     unit: '%',
//     nDecimals: 0,
//     offset: 0,
//     lowValue: 30,
//     steppedLine: false
//   }
// }

// Returns a Partial<AnalogSensor> because
// all analog inputs are originally defined as Batteries.
export function DefaultAnalogBilge(): Partial<AnalogSensor> {
  return {
    analogType: AnalogSensorTypes.Bilge,
    name: `Bilge`,
    triggerValue: 5,
    steppedLine: true,
    lowName: 'OK',
    highName: 'Pumping',
    alertOnHigh: true,
  }
}

// Returns a Partial<AnalogSensor> because
// all analog inputs are originally defined as Batteries.
export function DefaultAnalogIgnition(): Partial<AnalogSensor> {
  return {
    analogType: AnalogSensorTypes.Ignition,
    name: `Ignition`,
    steppedLine: true,
    triggerValue: 5,
    lowName: 'Off',
    highName: 'On',
    alertOnHigh: true,
    engineHours: 0,
  }
}

// Returns a Partial<AnalogSensor> because
// all analog inputs are originally defined as Batteries.
export function DefaultAnalogOther(input: number): Partial<AnalogSensor> {
  return {
    analogType: AnalogSensorTypes.Other,
    name: `Analog ${input}`,
    steppedLine: true,
    triggerValue: 5,
    lowName: 'Off',
    highName: 'On',
    alertOnHigh: true,
    engineHours: 0,
  }
}
