<ion-list *ngIf="inState('menu')">
  <ion-list-header>Options</ion-list-header>
  <ion-item (click)="currentState = 'sort'">
    <ion-icon name="filter-outline" style="padding-right: 10px"></ion-icon>
    Sort
  </ion-item>
  <!-- <button ion-item (click)="showFilters()">
    <ion-icon name="list"></ion-icon>
    <ion-icon name="stats"></ion-icon>
    <ion-icon name="switch"></ion-icon>
    <ion-icon name="options"></ion-icon>
    Filter
  </button> -->
  <ion-item (click)="clearAllAlerts()">
    <ion-icon name="trash" style="padding-right: 10px"></ion-icon>
    Clear All Alerts
  </ion-item>
</ion-list>
<ion-list *ngIf="inState('sort')" inset>
  <ion-list-header>Sort</ion-list-header>
  <ion-item [disabled]="!sortAscending" (click)="saveSortDescending()">
    Newest to Oldest
  </ion-item>
  <ion-item [disabled]="sortAscending" (click)="saveSortAscending()">
    Oldest to Newest
  </ion-item>
</ion-list>
