import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'

@Component({
  selector: 'setup-done-step',
  templateUrl: './setup-done-step.component.html',
  styleUrls: ['./setup-done-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupDoneStepComponent {
  @Output() exit = new EventEmitter()
  @Output() next = new EventEmitter()

  @Input() header: string
  @Input() description: string
  @Input() nextButtonText: string
  @Input() exitButtonText: string
}
