import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
} from '@angular/core'

import { AnalyticsProvider } from '../../services/analytics/analytics.service'
import { AppError, AppErrorCode } from '../../models-shared/app-error.model'

const STRIPE_SCRIPT_URL: string = 'https://js.stripe.com/v3/'

@Component({
  selector: 'stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.scss'],
})
export class StripeCardComponent {
  @ViewChild('mountPoint') mountEl: ElementRef
  @Input() publishableKey: string
  @Input() options: stripe.elements.ElementsOptions

  invalid: stripe.Error
  @Output() invalidChange = new EventEmitter<stripe.Error>()
  @Output() tokenChange = new EventEmitter<stripe.Token>()

  @Output() catch = new EventEmitter<stripe.Error>()

  @Output() ready = new EventEmitter<boolean>()

  @Output() change = new EventEmitter<stripe.elements.ElementChangeResponse>()
  stripe: stripe.Stripe
  elements: stripe.elements.Element
  constructor(private analytics: AnalyticsProvider) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.elements) {
      const options = changes.options.currentValue
      this.elements.update(options)
    }
  }

  async ngOnInit() {
    await this.initStripe()
  }

  async initStripe() {
    try {
      const stripeFactory = await this.injectScript()
      this.stripe = stripeFactory(this.publishableKey)
      this.elements = this.stripe.elements().create('card', this.options)
      this.elements.mount(this.mountEl.nativeElement)
      this.ready.emit(true)

      // Typecast stripe elements to access EventListener
      const htmlElement: any = this.elements as any
      htmlElement.addEventListener('change', (res) => {
        this.change.emit(res)
        if (res.error) {
          this.invalidChange.emit((this.invalid = res.error))
        } else {
          this.invalidChange.emit((this.invalid = undefined))
        }
      })
    } catch (err) {
      // this is a severe failure so we must report it
      await this.analytics.reportError(
        new AppError(AppErrorCode.STRIPE_FAILURE)
      )
    }
  }

  injectScript(): Promise<stripe.StripeStatic> {
    // TODO: see if we need this
    // if (window['Stripe']) {
    //   return window['Stripe']
    // }
    const script = document.createElement('script')
    script.setAttribute('src', STRIPE_SCRIPT_URL)
    script.setAttribute('type', 'text/javascript')
    return new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        resolve(window['Stripe'])
      })
      script.addEventListener('error', () => reject())
      const body = document.querySelectorAll('body')[0]
      body.appendChild(script)
    })
  }

  async createToken(customerData: stripe.TokenOptions) {
    const result = await this.stripe.createToken(this.elements, customerData)
    if (result && result.error) {
      if (result.error.type === 'validation_error') {
        this.invalidChange.emit((this.invalid = result.error))
      } else {
        this.catch.emit(result.error)
      }
    } else {
      this.tokenChange.emit(result.token)
    }
  }
}
