<form [formGroup]="paymentForm" class="payment_form">
  <div>
    <ng-container *ngFor="let entry of formEntries">
      <text-input
        type="text"
        required="true"
        [label]="entry?.label"
        labelClass="payment_label"
        [formControlName]="entry?.name"
        class="payment_textbox"
        [disableInput]="disablePayments"
      >
      </text-input>
    </ng-container>
  </div>
  <label class="payment_label">Credit Card *</label>
  <stripe-card
    #stripeCard
    [options]="stripeOptions"
    (catch)="errorHandler($event)"
    (invalidChange)="invalidError = $event"
    (ready)="ready.emit($event)"
    (tokenChange)="tokenChangeHandler($event)"
    (change)="stripeChangeHandler($event)"
    [publishableKey]="key"
  >
  </stripe-card>
  <p>* required</p>
  <optional-text>
    <p *ngIf="invalidError">
      <ion-icon name="alert-circle"></ion-icon>
      {{ invalidError.message }}
    </p>
  </optional-text>
  <ng-content></ng-content>
  <div class="payment_bottom">
    <optional-text class="payment_successful-text">
      <p *ngIf="paymentSuccessful">
        <b>Payment successful!</b> A receipt has been sent to {{ email }}
      </p>
    </optional-text>
    <ion-spinner *ngIf="processingPayment" medium></ion-spinner>
    <div class="payment_button-container">
      <ion-button
        [disabled]="!showPay || disablePayments"
        icon-end
        (click)="submitHandler()"
      >
        Pay
        <ion-icon name="card"></ion-icon>
      </ion-button>
    </div>
  </div>
</form>
