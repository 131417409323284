import { Injectable } from '@angular/core'

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'

import { ENV } from '@app/env'
import firebase from 'firebase/compat/app'
import { Observable, from, lastValueFrom } from 'rxjs'
import { switchMap, take, map } from 'rxjs/operators'

import { AuthProvider } from '../auth/auth.service'

const joinWithBase = (route: string): string =>
  `${ENV.FIRE_FUNCTIONS_URL}/api/${route}`

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionsProvider {
  constructor(private http: HttpClient, private auth: AuthProvider) {}

  private httpReq<ResType>(
    method: string,
    subroute: string,
    params?: HttpParams,
    body?: any
  ): Observable<ResType> {
    const options = {
      ...(params ? { params } : undefined),
      ...(body ? { body } : undefined),
    }
    console.log(subroute)
    return this.http.request(method, joinWithBase(subroute), options).pipe(
      map((res: Response): any => {
        try {
          return res.json
        } catch (err) {
          console.log(err)
        }
      })
    )
  }

  public get<ResType>(route: string, params?: HttpParams): Observable<ResType> {
    return this.httpReq('GET', route, params)
  }

  private authedHttpReq<ResType>(
    method: string,
    subroute: string,
    responseType: 'arraybuffer' | 'blob' | 'text' | 'json',
    params?: HttpParams,
    body?: any
  ): Observable<ResType> {
    return this.auth.user
      .pipe(
        switchMap(
          (user: firebase.User): Observable<string> => from(user.getIdToken())
        )
      )
      .pipe(
        switchMap((idToken: string): Observable<any> => {
          const headers: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${idToken}`,
          })
          const options = {
            params: params ? params : undefined,
            headers: headers,
            responseType: responseType,
            body: body ? body : undefined,
          }
          return this.http
            .request(method, joinWithBase(subroute), options)
            .pipe(
              map((res: Response): any => {
                try {
                  // TODO: figure dout why this doesn't work
                  // if (responseType === 'arraybuffer') {
                  //   return res.arrayBuffer
                  // } else if (responseType === 'blob') {
                  //   return res.blob
                  // } else if (responseType === 'json') {
                  //   return res.json
                  // } else if (responseType === 'text') {
                  //   return res.text
                  // }
                  return res
                } catch (err) {
                  console.log(err)
                }
              })
            )
        })
      )
  }

  public authedGet<ResType>(
    route: string,
    params?: HttpParams
  ): Observable<ResType> {
    return this.authedHttpReq('GET', route, 'json', params)
  }

  public authedGetPromise<ResType>(
    route: string,
    params?: HttpParams
  ): Promise<ResType> {
    return lastValueFrom(this.authedGet<ResType>(route, params).pipe(take(1)))
  }

  public authedGetBlobPromise(
    route: string,
    params?: HttpParams
  ): Promise<Blob> {
    return lastValueFrom(
      this.authedHttpReq<Blob>('GET', route, 'blob', params).pipe(take(1))
    )
  }

  public authedGetImage(route: string, params?: HttpParams): Promise<Blob> {
    return this.authedGetBlobPromise(route, params)
  }

  public authedPost<ResType>(route: string, body?: any): Promise<ResType> {
    return lastValueFrom(
      this.authedHttpReq<ResType>('POST', route, 'json', undefined, body).pipe(
        take(1)
      )
    )
  }

  public authedPut<ResType>(route: string, body?: any): Promise<ResType> {
    return lastValueFrom(
      this.authedHttpReq<ResType>('PUT', route, 'json', undefined, body).pipe(
        take(1)
      )
    )
  }

  public authedDel<ResType>(route: string, body?: any): Promise<ResType> {
    return lastValueFrom(
      this.authedHttpReq<ResType>(
        'DELETE',
        route,
        'json',
        undefined,
        body
      ).pipe(take(1))
    )
  }
}
