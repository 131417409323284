import { select } from '@angular-redux/store'
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core'
import { Observable } from 'rxjs'

import { AppDevice } from '../../models/app-device.model'
import { BehaviorSubject } from 'rxjs'
import { UserSettings } from '../../models-shared/user-settings.model'
import { SettingsProvider } from '../../services/settings/settings.service'
import { Stat } from 'app/models-shared/stat.model'
import { isMateSensor, offlineTimeout } from 'app/util'
import { SensorConfig } from 'app/models-shared/sensor-config.model'
import {
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  DragRef,
  CdkDragEnter,
} from '@angular/cdk/drag-drop'

@Component({
  selector: 'stats-grid',
  templateUrl: './stats-grid.component.html',
  styleUrls: ['./stats-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatsGridComponent {
  @select('currentDevice') currentDevice$: Observable<AppDevice>
  @Input() expanded: boolean
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter()
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>
  @ViewChild(CdkDropList, { static: false }) placeholder: CdkDropList

  public userSettings: BehaviorSubject<UserSettings>
  public sensorConfig: SensorConfig
  public target: CdkDropList
  public targetIndex: number
  public source: CdkDropList
  public sourceIndex: number
  public dragRef: DragRef = null
  public userInitiatedChange: boolean = false
  public stats: Stat<any>[]
  public dragging: boolean = false
  public draggingIndex: number
  public pressTimeout: any

  boxWidth = '200px'
  boxHeight = '200px'

  constructor(private settingsProvider: SettingsProvider) {
    this.userSettings = this.settingsProvider.userSettings$
    this.target = null
    this.source = null
    this.stats = []
  }

  public getWirelessSensorPreferredTempUnit = () => {
    return this.userSettings.value.preferredTempUnit
  }

  ngOnInit() {
    this.currentDevice$.subscribe((device) => {
      const mateSettings = this.settingsProvider.mateSettings$.value
      this.sensorConfig = {...device.settings.sensorConfig }
      if (mateSettings) {
        this.sensorConfig = {
          ...this.sensorConfig,
          ...mateSettings.sensorConfig
        }
      }
      this.stats = this.sortedStats(device.stats)
    })
  }

  ngAfterViewInit() {
    if (this.placeholder) {
      const placeholderElement = this.placeholder.element.nativeElement
      placeholderElement.style.display = 'none'
      placeholderElement.parentNode.removeChild(placeholderElement)
    } else {
      console.error('Placeholder is undefined')
    }
  }

  toggleOpen(): void {
    this.expanded = !this.expanded
    this.expandedChange.emit(this.expanded)
  }

  sortedStats(stats: Stat<any>[]): Stat<any>[] {
    if (!stats || !stats.length) return []
    return stats
      .filter((stat) => stat.showOnGrid)
      .sort((a, b) => {
        const indexA =
          this.sensorConfig[a.key]?.statsIndex ?? Number.MAX_SAFE_INTEGER
        const indexB =
          this.sensorConfig[b.key]?.statsIndex ?? Number.MAX_SAFE_INTEGER

        if (indexA === indexB) {
          return 0 // Keep original order for items with the same index or no index
        }

        return indexA - indexB
      })
  }

  getStatKey(index, stat: Stat<any>): string {
    return stat.key
  }

  isStale(statDatetime: string): boolean {
    return +statDatetime <= offlineTimeout
  }

  onDropListDropped() {
    if (!this.target) {
      return
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement
    const placeholderParentElement: HTMLElement =
      placeholderElement.parentElement

    placeholderElement.style.display = 'none'

    placeholderParentElement.removeChild(placeholderElement)
    placeholderParentElement.appendChild(placeholderElement)
    placeholderParentElement.insertBefore(
      this.source.element.nativeElement,
      placeholderParentElement.children[this.sourceIndex]
    )

    if (this.placeholder._dropListRef.isDragging()) {
      this.placeholder._dropListRef.exit(this.dragRef)
    }

    this.target = null
    this.source = null
    this.dragRef = null
    this.dragging = false
    this.draggingIndex = null

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.stats, this.sourceIndex, this.targetIndex)
      this.stats.forEach((stat, index) => {
        if (isMateSensor(stat.key)) {
          const mateSettings = this.settingsProvider.mateSettings$.value
          mateSettings.sensorConfig[stat.key].statsIndex = index
          this.settingsProvider.saveMateSettings({
            ...mateSettings
          })
        } else {
          const deviceSettings = this.settingsProvider.deviceSettings$.value
          deviceSettings.sensorConfig[stat.key].statsIndex = index
          this.settingsProvider.saveDeviceSettings({
            ...deviceSettings
          })
        }
      })
    }
  }

  onDropListEntered({ item, container }: CdkDragEnter) {
    if (container == this.placeholder) {
      return
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement
    const sourceElement: HTMLElement = item.dropContainer.element.nativeElement
    const dropElement: HTMLElement = container.element.nativeElement
    const dragIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      this.source ? placeholderElement : sourceElement
    )
    const dropIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      dropElement
    )

    if (!this.source) {
      this.sourceIndex = dragIndex
      this.source = item.dropContainer

      placeholderElement.style.width = this.boxWidth + 'px'
      placeholderElement.style.height = this.boxHeight + 40 + 'px'

      sourceElement.parentElement.removeChild(sourceElement)
    }

    this.targetIndex = dropIndex
    this.target = container
    this.dragRef = item._dragRef

    placeholderElement.style.display = ''

    dropElement.parentElement.insertBefore(
      placeholderElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    )

    this.placeholder._dropListRef.enter(
      item._dragRef,
      item.element.nativeElement.offsetLeft,
      item.element.nativeElement.offsetTop
    )
  }

  onPressStart(index) {
    this.pressTimeout = setTimeout(() => {
      this.dragging = true
      this.draggingIndex = index
    }, 800)
  }

  onPressEnd() {
    clearTimeout(this.pressTimeout)
    this.dragging = false
  }
}
