import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { LoadingController, ModalController } from '@ionic/angular'
import { VideoPlayerModalComponent } from 'app/components/video-player-modal/video-player-modal.component'
import { VideosProvider } from 'app/services/videos/videos.service'
import {
  Observable,
  Subscription,
  combineLatest,
  map,
  startWith,
  tap,
} from 'rxjs'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { DeviceProvider } from 'app/services/device/device.service'
import { select } from '@angular-redux/store'
import { AppDevice } from 'app/models/app-device.model'
import { HelpersProvider } from 'app/services/helpers/helpers.service'

@Component({
  templateUrl: './video.page.html',
  styleUrls: ['./video.page.scss'],
})
export class VideoPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []
  private isTouchEvent: boolean = false

  public cameras: { camera: any; thumbnail: any; videos?: any[] }[] = []
  public currentCamera: { camera: any; thumbnail: string; videos?: any[] }
  public currentVideo: string
  public currentStep: 'selectCamera' | 'selectVideo' | 'displayWebRTC' =
    'selectCamera'
  public isLoading: boolean = false
  public currentUrl: SafeResourceUrl
  public tunnelUrl: string

  public videoLoaded: boolean = false
  public selectedThumbnail: string
  public thumbnailsLoaded: { [camId: string]: boolean } = {}
  public showPTZControls: boolean = false
  public showPresets: boolean = false
  public keepStreamAlive: boolean = false
  public currentPTZCommand: any = { command: '', direction: '', startTime: 0 }

  public thumbnails$: Observable<{ [camId: string]: string }>
  @select('currentDevice') currentDevice$: Observable<AppDevice>
  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>

  constructor(
    private videoService: VideosProvider,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private device: DeviceProvider,
    private helper: HelpersProvider,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId

    this.subscriptions.push(
      this.videoService.getCameras().subscribe((cameras) => {
        this.cameras = cameras.map((camera) => ({
          camera: {
            uuid: camera.camId,
            camName: camera.config.cameraName,
            cameraType: camera.config.model,
            ptz: camera.config.ptz,
            presets: camera.config.presets,
            timestamp: camera.config.lastUpdated,
          },
          thumbnail: false,
          videos: [],
        }))

        const thumbnailObservables = this.cameras.map((camera) => {
          return this.videoService
            .getCameraThumbnail(deviceId, camera.camera.uuid)
            .pipe(
              tap(() => {
                camera.thumbnail = true // Thumbnail is loaded
              }),
              startWith(null) // Provide an initial value for each thumbnail observable
            )
        })

        this.thumbnails$ = combineLatest(thumbnailObservables).pipe(
          map((thumbnails) => {
            const result: { [camId: string]: string } = {}
            thumbnails.forEach((thumbnail, index) => {
              if (thumbnail) {
                result[this.cameras[index].camera.uuid] = thumbnail
              }
            })
            return result
          })
        )
      })
    )

    this.subscriptions.push(
      this.currentDevice$.subscribe((device) => {
        this.tunnelUrl = device.settings.tunnelUrl
      })
    )
  }

  handleIframeMessage(event: MessageEvent) {
    if (event.data === 'videoReady') {
      this.onVideoLoad()
    }
  }

  setVideo(camId: string, index: number): void {
    const camera = this.cameras.find((camera) => camera.camera.uuid === camId)
    if (camera) {
      this.currentVideo = camera.videos[index].url
    }
  }

  async displayStream(camId: string): Promise<void> {
    if (!this.tunnelUrl) {
      this.helper.showDangerToast(
        'Error connecting to live stream. Contact brnkl support for help at support@brnkl.io.',
        2
      )
      return
    }
    this.currentStep = 'displayWebRTC'
    const url = `https://${this.tunnelUrl}/stream-server/${camId}/`
    this.currentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    this.currentUrl = url

    this.videoLoaded = false
    this.showPTZControls = false
    this.currentCamera = this.cameras.find(
      (camera) => camera.camera.uuid === camId
    )
  }

  onVideoLoad() {
    this.videoLoaded = true
  }

  onThumbnailLoad(camId: string) {
    const camera = this.cameras.find((cam) => cam.camera.uuid === camId)
    if (camera) {
      camera.thumbnail = true // Thumbnail is loaded
    }
  }

  async playVideo(url: string, name: string): Promise<void> {
    const modal = await this.modalController.create({
      component: VideoPlayerModalComponent,
      componentProps: {
        videoUrl: url,
        videoName: name,
      },
    })

    await modal.present()
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Loading...',
    })
    await loading.present()
  }

  async dismissLoading() {
    await this.loadingController.dismiss()
  }

  resetSelection(): void {
    this.currentStep = 'selectCamera'
    this.currentVideo = null
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    window.removeEventListener('message', this.handleIframeMessage.bind(this))
  }

  goToSelectCamera(): void {
    this.currentStep = 'selectCamera'
    this.showPTZControls = false
  }

  handleMouseDown(command, direction) {
    if (this.isTouchEvent) return
    this.handlePTZCommandStart(command, direction)
  }

  handleMouseUp(command, direction) {
    if (this.isTouchEvent) return
    this.handlePTZCommandEnd(command, direction)
  }

  handleTouchStart(event: TouchEvent, command, direction) {
    this.isTouchEvent = true
    event.preventDefault()
    this.handlePTZCommandStart(command, direction)
  }

  handleTouchEnd(event: TouchEvent, command, direction) {
    event.preventDefault()
    this.handlePTZCommandEnd(command, direction)
    setTimeout(() => (this.isTouchEvent = false), 0) // Reset the flag after a short delay
  }

  handleTogglePTZControls() {
    this.keepStreamAlive = !this.keepStreamAlive
    this.showPTZControls = !this.showPTZControls
  }

  handlePTZCommandStart(command: string, direction) {
    this.currentPTZCommand = { command, direction, startTime: Date.now() }
  }

  handlePTZCommandEnd(command: string, direction) {
    const duration =
      command === this.currentPTZCommand.command &&
      direction === this.currentPTZCommand.direction
        ? Date.now() - this.currentPTZCommand.startTime
        : 0
    const response = this.videoService.sendPTZCommand(
      this.currentCamera.camera.uuid,
      command,
      direction,
      duration
    )

    if (!response) {
      console.log('Error sending PTZ command to camera.')
    } else {
      console.log('PTZ command sent successfully.')
    }
    this.keepStreamAlive = !this.keepStreamAlive
  }

  handlePresetChange(e) {
    e.preventDefault()
    this.keepStreamAlive = !this.keepStreamAlive
    const response = this.videoService.sendPTZCommand(
      this.currentCamera.camera.uuid,
      'preset',
      e.detail.value.id
    )

    if (!response) {
      console.log('Error sending preset command to camera.')
    } else {
      console.log('Preset command sent successfully.')
    }
  }

  displayPresets() {
    this.showPresets = !this.showPresets
  }
}
