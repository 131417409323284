import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'

@Component({
  selector: 'setup-device-box',
  templateUrl: './setup-device-box.component.html',
  styleUrls: ['./setup-device-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupDeviceBox {
  @Output() next = new EventEmitter()

  @Input() header: string
  @Input() deviceName: string
  @Input() iconName: string
}
