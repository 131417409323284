<ion-content class="add-wireless-sensor-content" [ngSwitch]="currentStep.name">
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <setup-info
      *ngSwitchCase="'scanInfo'"
      (next)="currentStep.next()"
      topIcon="custom-scan-code"
      header="Scan Device"
      description="Scan the QR code on the {{
        selectedSensorDisplay.name
      }} or on its packaging."
      [device]="selectedSensorDisplay"
      nextButtonText="Scan"
    ></setup-info>
    <setup-progress
      *ngSwitchCase="'find'"
      (next)="currentStep.next()"
      [description]="findDescription"
      [progressStep]=""
      [progressPercent]=""
      [doneButtonText]=""
      [device]="selectedSensorDisplay"
      progressIconName="custom-find"
      [header]="findHeader"
    ></setup-progress>
    <setup-wireless-init
      *ngSwitchCase="'init'"
      (next)="currentStep.next()"
      header="Connect"
      instructions="Remove the tab from the sensor"
      nextButtonText="Next"
      [device]="selectedSensorDisplay"
    >
    </setup-wireless-init>
    <setup-progress
      *ngSwitchCase="'activate'"
      (next)="currentStep.next()"
      [description]="progressDescription"
      [progressStep]="progressStep"
      [progressPercent]="progressPercent"
      [doneButtonText]="doneButtonText"
      (cancel)="onProgressBtnClick()"
      [device]="selectedSensorDisplay"
      [progressIconName]="progressIconName"
      [header]="progressHeader"
    ></setup-progress>
    <setup-input
      *ngSwitchCase="'name'"
      (next)="currentStep.next($event, selectedSensorDisplay)"
      header="Name"
      description="Name the sensor."
      nextButtonText="Next"
      exampleInput="Main Entry"
      [device]="selectedSensorDisplay"
    ></setup-input>
    <setup-progress
      *ngSwitchCase="'added'"
      (next)="currentStep.next()"
      [description]="addedDescription"
      [progressStep]=""
      [progressPercent]=""
      [doneButtonText]="'Next'"
      [device]="selectedSensorDisplay"
      progressIconName="md-checkmark"
      [header]="addedHeader"
      (cancel)="onProgressCancel()"
    ></setup-progress>
    <setup-done-step
      *ngSwitchCase="'repeat'"
      (next)="currentStep.next()"
      (exit)="onExitPressed()"
      header="Add another device?"
      description="Would you like to add another device to your BRNKL Mate?"
      nextButtonText="Yes"
      exitButtonText="Exit"
    ></setup-done-step>
  </setup-container>
</ion-content>
