<form [formGroup]="passwordForm">
  <ion-list>
    <ion-item>
      <ion-label position="floating">New Vessel Password</ion-label>
      <ion-input type="password" formControlName="password"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="floating">Confirm Password</ion-label>
      <ion-input type="password" formControlName="confirmPassword"></ion-input>
    </ion-item>
    <ion-item *ngIf="showNoMatch || showTooShort">
      <p *ngIf="showTooShort">
        Password must be at least {{ minPasswordLen }} characters
      </p>
      <p *ngIf="showNoMatch">Passwords must match</p>
    </ion-item>
  </ion-list>
</form>
