import { ZigbeeData } from '../../models/zigbee-data.model'

const getMacAddress = (bytes: Uint8Array): string => {
  let charArray: string[] = []
  for (let i = 0; i < 8; i++) {
    let characters = bytes[i].toString(16)
    if (characters.length !== 2) {
      characters = '0' + characters
    }
    charArray.push(characters.toUpperCase())
  }

  // Switch endianness of the mac address
  charArray = charArray.reverse()
  return charArray.join('')
}

const hasTemperature = (byte: number) => {
  return (byte & 0x1) == 0x1
}

const getTemperature = (bytes: Uint8Array): number => {
  const sign = bytes[1] & (1 << 7)
  let result = ((bytes[1] & 0xff) << 8) | (bytes[0] & 0xff)
  if (sign) {
    result = 0xffff0000 | result // fill in most significant bits with 1's
  }

  return result / 100
}

const getBatteryPercent = (byte: number) => {
  return (byte >>> 0) / 2
}

export const extractZigbeeData = (bytes: Uint8Array): ZigbeeData => {
  const macAddress = getMacAddress(bytes.slice(0, 8))
  const timestamp = bytes.slice(8, 16)
  const batteryPercent = getBatteryPercent(bytes[17])
  const zoneStatus = bytes.slice(18, 20)

  let temperature: number = null
  if (hasTemperature(bytes[16])) {
    temperature = getTemperature(bytes.slice(20, 22))
  }

  return {
    macAddress,
    timestamp,
    zoneStatus,
    batteryPercent,
    temperature,
  }
}

export const isAlarm1Active = (zoneStatus: Uint8Array): boolean => {
  return (zoneStatus[0] & 0x1) == 1
}
