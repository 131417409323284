<div class="setup-container_header setup-done_section">
  {{ header }}
</div>
<div class="setup-container_text setup-container_bottom-space">
  {{ description }}
</div>
<div class="setup-container_btn-align setup-container_btn-space">
  <button class="setup-container_btn-action" (click)="next.emit()">
    {{ nextButtonText }}
  </button>
</div>
<div class="setup-container_btn-align">
  <button class="setup-container_btn-option" (click)="exit.emit()">
    {{ exitButtonText }}
  </button>
</div>
