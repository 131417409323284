import { ThresholdSensor } from './threshold-sensor.model'

export interface AnalogSensor extends ThresholdSensor {
  analogType: AnalogSensorTypes
  steppedLine: boolean
  // Used for alerting above/below a specific value
  triggerValue?: number
  alertOnHigh?: boolean
  highName?: string
  lowName?: string
  engineHours?: number // for Ignition analog type
}

export enum AnalogSensorTypes {
  Battery = 0,
  Bilge,
  Ignition,
  Other,
}
