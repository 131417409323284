<div
  class="search-bar_container"
  [ngClass]="{
    invalid: validInput === 'invalid',
    valid: validInput === 'valid' || validInput === true
  }"
>
  <input
    type="text"
    class="search-bar_input"
    [placeholder]="inputPlaceholder"
    (ngModelChange)="ngModelChange.emit($event)"
    [ngModel]="ngModel"
  />
  <div
    item-end
    class="search-bar_icon-container"
    [ngClass]="{
      'invalid-icon': validInput === 'invalid',
      'valid-icon': validInput === 'valid' || validInput === true
    }"
    (click)="clickSearch.emit($event)"
  >
    <ion-spinner name="lines-small" *ngIf="isLoading$ | async; else searchIcon">
    </ion-spinner>
    <ng-template #searchIcon>
      <ion-icon *ngIf="validInput === 'enter'" name="custom-add-action">
      </ion-icon>
      <ion-icon
        *ngIf="validInput === 'valid' || validInput === true"
        name="checkmark"
      >
      </ion-icon>
      <ion-icon
        *ngIf="validInput === 'invalid' || validInput === false"
        name="close"
      >
      </ion-icon>
    </ng-template>
  </div>
</div>
