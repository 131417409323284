<div class="setup-wireless-activate_center-align">
  <img [src]="imagePath" class="setup-wireless-activate_device-image" />
</div>
<div class="setup-container_text setup-wireless-activate_top-space">
  {{ userInstructions() }}
</div>
<div class="setup-wireless-activate_details-text setup-container_bottom-space">
  If you're not able to see a green light on the sensor, try resetting the
  device.
  <span class="setup-wireless-activate_get-help"> Get Help</span>
</div>
