<video
  #video
  [src]="videoSrc"
  controls
  autoplay="autoplay"
  muted
  defaultMuted
  playsinline
  id="video"
  (canplaythrough)="onVideoLoaded()"
></video>
<div class="video-overlay" *ngIf="loading">
  <img [src]="thumbnail" *ngIf="loading" class="thumbnail" />
  <ion-spinner name="crescent" color="primary"></ion-spinner>
</div>

<!-- "Are you still watching?" modal overlay -->
<div class="still-watching-overlay" *ngIf="showStillWatchingPrompt">
  <div class="still-watching-modal">
    <h2>Are you still watching?</h2>
    <ion-button
      (click)="continueWatching(videoElement.nativeElement)"
      color="light"
      >Yes</ion-button
    >
  </div>
</div>

<script>
  window.addEventListener('DOMContentLoaded', loadVideo())
</script>
