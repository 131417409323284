export class NavDataService {
  private data: any = {}
  constructor() {}
  public set(key: string, value: any) {
    this.data[key] = value
  }
  public get(key: string) {
    return this.data[key]
  }
  public remove(key: string) {
    delete this.data[key]
  }
}
