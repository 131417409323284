<ion-header>
  <ion-toolbar>
    <ion-title>Create Account</ion-title>
    <ion-buttons slot="primary">
      <ion-button
        [disabled]="!createAccountForm.valid"
        (click)="createAccount()"
      >
        Submit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <form [formGroup]="createAccountForm">
    <ion-list>
      <ion-item *ngFor="let field of accountFields">
        <ion-label position="stacked">{{ field.label }}</ion-label>
        <div class="create-account-show-password">
        <ion-input
          type="{{ field.type }}"
          formControlName="{{ field.formControlName }}"
        >
        </ion-input>
        <ion-button *ngIf="field.label==='Password'" slot="end" fill="clear" (click)="handleShowPassword()" class="show-password-icon">
          <ion-icon
            *ngIf="showPassword==true"
            name="eye-outline"
          ></ion-icon>
          <ion-icon
            *ngIf="showPassword==false"
            name="eye-off-outline"
          ></ion-icon>
        </ion-button>
      </div>
      </ion-item>
      <ion-item *ngIf="showPasswordTooShort()">
        <p *ngIf="showPasswordTooShort()">
          Password must be {{ minPasswordLen }} characters or more
        </p>
      </ion-item>
      <ion-item *ngIf="showPasswordNoMatch()">
        <p>Passwords do not match</p>
      </ion-item>
      <ion-item *ngIf="showInvalidEmail()">
        <p>Invalid email format</p>
      </ion-item>
    </ion-list>
  </form>
</ion-content>
