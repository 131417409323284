import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'bottom-panel',
  templateUrl: './bottom-panel.component.html',
  styleUrls: ['./bottom-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BottomPanelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
