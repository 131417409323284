import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent {
  @Input() progressPercent: number
}
