import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'

@Component({
  selector: 'setup-container',
  templateUrl: './setup-container.component.html',
  styleUrls: ['./setup-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupContainerComponent {
  @Output() back = new EventEmitter()
  @Output() exit = new EventEmitter()

  @Input() backButton: boolean
  @Input() exitButton: boolean
  @Input() title: String
  @Input() iconName: String
}
