import { Component, ViewEncapsulation } from '@angular/core'
import { SetupStep } from '../../models/setup-step.model'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'base-setup-modal',
  templateUrl: './base-setup-modal.component.html',
  styleUrls: ['./base-setup-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseSetupModalComponent {
  stepStack: SetupStep[] = []
  currentStep: SetupStep

  constructor(private viewControl: ModalController) {}

  exit(): void {
    this.viewControl.dismiss()
  }

  back(): void {
    this.prevStep()
  }

  pushStep(step: SetupStep) {
    if (this.currentStep && this.currentStep.name === step.name) {
      return
    }

    this.stepStack.push(step)
    this.currentStep = step
  }

  prevStep() {
    if (this.stepStack.length <= 1) return
    this.stepStack.pop()
    this.currentStep = this.stepStack[this.stepStack.length - 1]
  }

  reset() {
    while (this.stepStack.length > 1) {
      this.stepStack.pop()
    }

    this.currentStep = this.stepStack[this.stepStack.length - 1]
  }
}
