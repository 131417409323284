import {
  Component,
  Input,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core'
import { Observable } from 'rxjs'
import { debounceTime, tap, switchMap } from 'rxjs/operators'

import { SubscriptionProvider } from '../../services/subscription/subscription.service'
import { PricingResponse } from '../../models-shared/pricing-res.model'
import { PricingRequest } from '../../models-shared/pricing-req.model'

@Component({
  selector: 'checkout-price',
  templateUrl: './checkout-price.component.html',
  styleUrls: ['./checkout-price.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutPriceComponent {
  @Input() pricingInfo: EventEmitter<PricingRequest>
  pricing$: Observable<PricingResponse>
  loading$: EventEmitter<boolean> = new EventEmitter<boolean>()
  loadingDebounce$: Observable<boolean>

  constructor(private subscription: SubscriptionProvider) {}

  ngOnInit() {
    this.pricing$ = this.getPricing()
    this.loadingDebounce$ = this.loading$.pipe(debounceTime(300))
  }

  getPricing() {
    return this.pricingInfo.pipe(
      tap((_) => this.loading$.emit(true)),
      switchMap((k) => this.subscription.getPricing(k)),
      tap((_) => this.loading$.emit(false))
    )
  }
}
