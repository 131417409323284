import { Component } from '@angular/core'
import { NavParams, ModalController } from '@ionic/angular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthProvider } from '../../services/auth/auth.service'

@Component({
  selector: 'forget-password-modal',
  templateUrl: './forget-password-modal.component.html',
  styleUrls: ['./forget-password-modal.component.scss'],
})
export class ForgetPasswordModalComponent {
  emailForm: FormGroup
  showSpinner: boolean = false
  disableButton = false
  statusMessage = ''

  constructor(
    public params: NavParams,
    private viewCtrl: ModalController,
    private formBuilder: FormBuilder,
    private auth: AuthProvider
  ) {
    this.buildForm()
  }

  buildForm() {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  async sendResetPasswordEmail() {
    if (this.emailForm.valid) {
      const email = this.emailForm.controls.email.value

      this.showSpinner = true
      this.disableButton = true
      try {
        await this.auth.sendPasswordResetEmail(email)
        this.statusMessage = `Success! An email was sent to ${email}`
      } catch {
        this.disableButton = false
        this.statusMessage = `Failure! No account found for ${email}`
      }

      this.showSpinner = false
    }
  }

  dismiss(): void {
    this.viewCtrl.dismiss()
  }
}
