import { Injectable } from '@angular/core'
import {
  BluetoothLE,
  ScanStatus,
  Device,
  DeviceInfo,
  OperationResult,
} from '@ionic-native/bluetooth-le/ngx'

import { Observable } from 'rxjs'
import { Subscription } from 'rxjs'

import { EnvironmentProvider } from '../../services/environment/environment.service'

import { debugLog } from '../../util/'
declare var cordova: any

interface CharacteristicInfo {
  address: string
  serviceUUID: string
  characteristicUUID: string
}

@Injectable({
  providedIn: 'root',
})
export class BluetoothLEAdapter {
  private subscriptions: Subscription[] = []
  private subscribedCharacteristics: CharacteristicInfo[] = []
  private isAndroid: boolean

  public timeout: number = 5000
  constructor(
    private bluetooth: BluetoothLE,
    private environmentProvider: EnvironmentProvider
  ) {
    this.isAndroid = this.environmentProvider.isAndroidApp()
  }

  public init = async () => {
    const result = await this.bluetooth.isInitialized()

    if (!result.isInitialized) {
      return new Promise<void>((resolve, reject) => {
        this.bluetooth.initialize({ request: true }).subscribe((res) => {
          if (res.status === 'disabled') {
            console.error('Bluetooth is disabled')
            reject(new Error('Failed to initialize bluetooth'))
          }
          resolve()
        })
      })
    }

    if (this.isAndroid) {
      await this.setupAndroidPermissions()
    }
  }

  public scan = (serviceUUIDs: string[]): Promise<string> => {
    return this.scanForDevice(serviceUUIDs, false)
  }

  public scanForMate = (
    mateId: string,
    serviceUUIDs: string[]
  ): Promise<string> => {
    return this.scanForDevice(serviceUUIDs, true, mateId)
  }

  private scanForDevice = (
    serviceUUIDs: string[],
    validMateId: boolean,
    mateId?: string
  ): Promise<string> => {
    // Start scanning for devices with the specified service UUIDs

    let subscription: Subscription | null = null

    // Function to end the scan, safely handling any errors
    const endScan = async () => {
      if (subscription) {
        subscription.unsubscribe()
        subscription = null
      }

      try {
        await this.bluetooth.stopScan()
      } catch (error) {
        console.error('Error stopping the Bluetooth scan:', error)
      }
    }

    return new Promise((resolve, reject) => {
      const scan$ = this.bluetooth.startScan({
        services: serviceUUIDs,
      })

      // Set a timeout for how long to scan before giving up
      const timer = setTimeout(async () => {
        await endScan()
        reject(new Error('Failed to find BRNKL Mate within timeout'))
      }, this.timeout)

      // Function to handle each scan result
      const handleScanStatus = async (scanStatus: ScanStatus) => {
        console.log('Scan Status', scanStatus)
        if (scanStatus.status === 'scanResult') {
          const isDeviceMatch =
            !validMateId || (validMateId && scanStatus.name === mateId)

          console.log('isDeviceMatch', isDeviceMatch)

          if (isDeviceMatch) {
            clearTimeout(timer)
            console.log('Found BRNKL Mate', scanStatus.address)
            await endScan()
            resolve(scanStatus.address)
          }
        }
      }

      // Function to handle scan errors
      const handleError = async (error: any) => {
        console.log('Scan Error', error)
        clearTimeout(timer)
        console.error('Error during Bluetooth scan:', error)
        await endScan()
        reject(
          error instanceof Error
            ? error
            : new Error('Unknown error occurred during scan')
        )
      }

      // Subscribe to the scan observable
      subscription = scan$.subscribe({
        next: handleScanStatus,
        error: handleError,
      })
    })
  }

  public connect = async (address: string, disconnectCallback: () => void) => {
    // Typecast observable (legacy type definitions)
    const connection$: Observable<DeviceInfo> = this.bluetooth.connect({
      address,
    }) as any

    const promise = new Promise<void>((resolve, reject) => {
      const timer = setTimeout(() => {
        this.clearSubscriptions()
        reject(new Error('Failed to connect to BRNKL Mate'))
      }, this.timeout)

      let resolved: boolean = false
      const subscription = connection$.subscribe(
        async (deviceInfo) => {
          if (deviceInfo.status === 'connected') {
            clearTimeout(timer)
            resolved = true
            resolve()
          } else if (deviceInfo.status === 'disconnected') {
            disconnectCallback()
            await this.handleDisconnection(address)
          }
        },
        (err) => {
          if (!resolved) {
            clearTimeout(timer)
            this.clearSubscriptions()
            resolved = true
            reject(err)
          }

          // tslint:disable-next-line
          debugLog(`Connect Subscription Error - ${err.message}`)
        }
      )

      this.subscriptions.push(subscription)
    })

    return promise
  }

  public discover = (address: string): Promise<Device> => {
    // Typecast observable (legacy type definitions)
    return this.bluetooth.discover({
      address,
    }) as Promise<any>
  }

  public disconnect = async (address: string): Promise<void> => {
    this.clearSubscriptions()
    await this.clearSubscribedCharacteristics()

    await this.bluetooth.disconnect({
      address,
    })

    await this.bluetooth.close({
      address,
    })
  }

  public subscribeToCharacteristic(
    address: string,
    service: string,
    characteristic: string,
    callback: (bytes: Uint8Array) => Promise<void> | void,
    errCallback?: (reason: any) => void
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      // Typecast observable (legacy type definitions)
      const data$: Observable<OperationResult> = <any>this.bluetooth.subscribe({
        address,
        service,
        characteristic,
      })

      let subscribed = false
      let promisedResolved = false

      const debugHeader = `Service: ${service} Characeristic: ${characteristic}`

      const subscription = data$.subscribe(
        async (obj) => {
          const data: OperationResult = <any>obj

          if (data.status === 'subscribed' && !subscribed) {
            subscribed = true

            if (!promisedResolved) {
              promisedResolved = true
              resolve()
            }
          } else if (data.status === 'subscribedResult' && subscribed) {
            const bytes = this.bluetooth.encodedStringToBytes(data.value)
            if (bytes.length > 0) {
              const promise = callback(bytes)
              if (promise) {
                await promise
              }
            }
          } else if (!promisedResolved) {
            promisedResolved = true
            reject(`${debugHeader}: Invalid subscription status ${data.status}`)
          }
        },
        (err) => {
          debugLog(`${debugHeader}: Error - ${err.message}`)
          if (errCallback != null) {
            errCallback(err)
          }
        }
      )

      this.subscriptions.push(subscription)
      this.subscribedCharacteristics.push({
        address,
        serviceUUID: service,
        characteristicUUID: characteristic,
      })
    })
  }

  public bytesToString = (bytes: Uint8Array) => {
    return this.bluetooth.bytesToString(bytes)
  }

  public async writeToCharacteristic(
    address: string,
    service: string,
    characteristic: string,
    message: string
  ) {
    const bytes = this.bluetooth.stringToBytes(message)
    return this.writeToCharacteristicBytes(
      address,
      service,
      characteristic,
      bytes
    )
  }

  public async writeToCharacteristicBytes(
    address: string,
    service: string,
    characteristic: string,
    bytes: Uint8Array
  ) {
    const encodedString = this.bluetooth.bytesToEncodedString(bytes)
    const result = await this.bluetooth.write({
      address,
      service,
      characteristic,
      value: encodedString,
    })

    if (result.status !== 'written') {
      throw new Error(`Failed to write ${result.status}`)
    }
  }

  public async readCharacteristic(
    address: string,
    service: string,
    characteristic: string
  ): Promise<Uint8Array> {
    // Typecast result (legacy type definitions)
    debugLog(`Reading Characteristic ${characteristic}`)
    const response: OperationResult = (await this.bluetooth.read({
      address,
      characteristic,
      service,
    })) as any

    debugLog('Successfully Read Characteristic')

    if (response.status !== 'read') {
      throw new Error(
        `Reading characteristic ${characteristic} failed (status: ${response.status})`
      )
    }
    const base64String = response.value
    const bytes = this.bluetooth.encodedStringToBytes(base64String)
    if (bytes.length === 0) {
      throw new Error(
        `No data returned from read characteristic ${characteristic}`
      )
    }
    return bytes
  }

  private clearSubscriptions() {
    while (this.subscriptions.length != 0) {
      this.subscriptions.pop().unsubscribe()
    }
  }

  private clearSubscribedCharacteristics = async () => {
    while (this.subscribedCharacteristics.length != 0) {
      const { address, serviceUUID, characteristicUUID } =
        this.subscribedCharacteristics.pop()

      try {
        await this.unsubscribeFromCharacteristic(
          address,
          serviceUUID,
          characteristicUUID
        )
        debugLog(`Unsubscribed from characteristic: ${characteristicUUID}`)
      } catch (err) {
        debugLog(
          `Error unsubscribing from characterisic ${characteristicUUID}: ${err}`
        )
      }
    }
  }

  private unsubscribeFromCharacteristic = async (
    address: string,
    service: string,
    characteristic: string
  ) => {
    const result = await this.bluetooth.unsubscribe({
      address,
      service,
      characteristic,
    })

    if (result.status !== 'unsubscribed') {
      throw new Error(`Failed to unsubscribe: ${result.status}`)
    }
  }

  private handleDisconnection = async (address: string) => {
    this.clearSubscriptions()

    // Reset as Mate is disconnected
    this.subscribedCharacteristics = []

    await this.bluetooth.close({
      address,
    })
  }

  private async setupAndroidPermissions(): Promise<void> {
    await this.requestBlePermission()
  }

  private requestBlePermission = async () => {
    const permissions = cordova.plugins.permissions

    const error = (permissionName) => {
      console.warn(`${permissionName} permission is not turned on`)
    }

    const grantPermission = (permissionName) => {
      return new Promise<void>((resolve, reject) => {
        permissions.checkPermission(
          permissionName,
          (status) => {
            if (!status.hasPermission) {
              permissions.requestPermission(
                permissionName,
                (status) => {
                  if (!status.hasPermission) {
                    reject(new Error(`Please enable Bluetooth permissions!`))
                  } else {
                    resolve()
                  }
                },
                (err) => {
                  reject(new Error(`Please enable Bluetooth permissions!`))
                }
              )
            } else {
              resolve()
            }
          },
          (err) => {
            error(permissionName)
            reject(new Error(`Please enable Bluetooth permissions!`))
          }
        )
      })
    }

    const permissionList = [
      'android.permission.BLUETOOTH_CONNECT',
      'android.permission.ACCESS_FINE_LOCATION',
      'android.permission.BLUETOOTH_SCAN',
    ]

    for (const permission of permissionList) {
      try {
        await grantPermission(permission)
      } catch (err) {
        console.error(err.message)
        throw err
      }
    }
  }

  // private isLocationEnabled = async (): Promise<boolean> => {
  //   const result = await this.bluetooth.isLocationEnabled()
  //   return result.isLocationEnabled
  // }

  // private requestLocation = async (): Promise<boolean> => {
  //   const result = await this.bluetooth.requestLocation()
  //   return result.requestLocation
  // }
}
