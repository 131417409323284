<ng-container *ngIf="pricing$ | async as pricing">
  <table class="checkout-price">
    <thead class="checkout-price__bold">
      <tr>
        <td>Item</td>
        <td class="checkout-price_price-row">
          <ion-spinner small *ngIf="loadingDebounce$ | async"></ion-spinner>
          <span class="checkout_price-price-title">Price (CA$)</span>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of pricing.items">
        <td>{{ item.name }}</td>
        <td class="checkout-price_amount">
          {{ item.amount | currency: "CAD":true }}
        </td>
      </tr>
      <tr *ngIf="pricing.discount != 0">
        <td>Discount</td>
        <td class="checkout-price_amount">
          {{ pricing.discount | currency: "CAD":true }}
        </td>
      </tr>
      <tr>
        <td class="checkout-price__bold">Subtotal</td>
        <td class="checkout-price_amount">
          {{ pricing.subtotal | currency: "CAD":true }}
        </td>
      </tr>
      <tr *ngFor="let tax of pricing.tax">
        <td>{{ tax.name }}</td>
        <td class="checkout-price_amount">
          {{ tax.amount | currency: "CAD":true }}
        </td>
      </tr>
      <tr>
        <td class="checkout-price__bold">Total</td>
        <td class="checkout-price_amount">
          {{ pricing.total | currency: "CAD":true }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
