<ion-header>
  <ion-toolbar>
    <ion-title>{{ videoName }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="video-content">
  <video [src]="videoUrl" controls></video>
</ion-content>
