import { debugLog } from '../../util/'

export class CliResponseQueue {
  private cliOutputQueue: string[] = []
  private partialCliOutput: string = ''
  private resolveNextCliResponse: (response: string) => void = null
  private rejectNextCliResponse: (reason?: any) => void = null
  private delimiter = '\r\n'
  private defaultTimeoutMs = 30 * 1000

  // Add a new response to the queue
  // Note: the response can be incomplete (not ending in delimiter)
  public enqueue = (response: string) => {
    const combinedResponse = this.partialCliOutput + response
    const lines = combinedResponse.split(this.delimiter)

    if (lines.length === 1) {
      // No deliminator in response
      this.partialCliOutput = combinedResponse
      return
    }

    // Add every array element except the last
    const lastItem = lines.length - 1
    for (let i = 0; i < lastItem; i++) {
      const cleanResponse = lines[i].replace('> ', '').trim()
      if (cleanResponse != '') {
        this.cliOutputQueue.push(cleanResponse)
      }
    }

    // Last array element is either:
    // '' -> response ended the delimiter (from split function)
    // non-empty string -> response did not end in the delimiter
    this.partialCliOutput = lines[lastItem]

    // Resolve dequeue promise if applicable
    if (this.resolveNextCliResponse != null && this.length() > 0) {
      const nextResponse = this.cliOutputQueue.shift()
      this.resolveNextCliResponse(nextResponse)
      this.resolveNextCliResponse = null
      this.rejectNextCliResponse = null
    }
  }

  // Remove a full response (ending in the delimiter from the queue)
  // If no response is immediately then the promise will resolve
  // when a response is available. The promise will reject if
  // the handleCLIRejection method is called (ex: mate disconnection).
  // Note: this method does not support multiple asychronous
  // invocations at the same time.
  public dequeue = (
    timeoutMs: number = this.defaultTimeoutMs
  ): Promise<string> => {
    if (this.cliOutputQueue.length > 0) {
      const response = this.cliOutputQueue.shift()
      debugLog(`CLI Queue Response: '${response}'`)
      return Promise.resolve(response)
    }

    if (this.resolveNextCliResponse != null) {
      return Promise.reject(
        new Error('Attempted dequeue multiple items asychronously')
      )
    }

    const promiseHandler = (
      resolve: (value: string) => void,
      reject: (reason?: any) => void
    ) => {
      const timeout = setTimeout(() => {
        reject(new Error('CLI Response Timeout'))
      }, timeoutMs)

      this.resolveNextCliResponse = (response: string) => {
        clearTimeout(timeout)
        debugLog(`CLI Queue Response: '${response}'`)
        resolve(response)
      }

      this.rejectNextCliResponse = (reason: any) => {
        clearTimeout(timeout)
        debugLog(`CLI Queue Response Rejected: '${reason}'`)
        reject(reason)
      }
    }

    return new Promise(promiseHandler)
  }

  // Return the number of full responses (ending in a delimiter)
  public length = (): number => {
    return this.cliOutputQueue.length
  }

  public clear = () => {
    this.cliOutputQueue = []
    this.partialCliOutput = ''
  }

  // Handle if the Nordic Read CLI characteristic errors
  public handleCLIRejection = (reason?: any) => {
    if (this.rejectNextCliResponse != null) {
      this.rejectNextCliResponse(reason)
      this.resolveNextCliResponse = null
      this.rejectNextCliResponse = null
    }

    this.clear()
  }
}
