<ng-container *ngIf="device; else spacing">
  <setup-device-box
    [iconName]="device.iconName"
    [header]="device.companyName"
    [deviceName]="device.name"
  ></setup-device-box>
</ng-container>
<ng-template #spacing>
  <div class="setup-container_device-placeholder"></div>
</ng-template>
<div class="setup-progress-container">
  <ion-grid>
    <ion-icon [name]="progressIconName"></ion-icon>
    <div class="setup-progress-header">
      {{ header }}
    </div>
    <div class="setup-container_text setup-progress_text-bar-space">
      {{ description }}
    </div>
    <div
      *ngIf="progressPercent"
      class="setup-progress_display-container setup-progress_bar-button-space"
    >
      <progress-bar [progressPercent]="progressPercent"> </progress-bar>
      <span class="setup-progress_step">
        {{ progressStep }}
      </span>
    </div>
  </ion-grid>
</div>
<div class="setup-container_btn-align setup-progress_button-top">
  <button
    *ngIf="doneButtonText"
    class="setup-container_btn-action"
    (click)="cancel.emit()"
  >
    {{ doneButtonText }}
  </button>
</div>
