import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { SensorTypes } from '../../models-shared/sensor-types'
import { WirelessSensorDisplay } from '../../models/wireless-sensor-display.model'

@Component({
  selector: 'setup-wireless-init',
  templateUrl: './setup-wireless-init.component.html',
  styleUrls: ['./setup-wireless-init.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupWirelessInitComponent {
  @Output() next = new EventEmitter()

  @Input() device: WirelessSensorDisplay
  @Input() header: string
  @Input() instructions: string
  @Input() nextButtonText: string

  sensorType: SensorTypes

  constructor() {}

  ngOnInit() {
    if (this.device.type === SensorTypes.WirelessTemp) {
      this.sensorType = SensorTypes.WirelessTemp
      this.instructions =
        'Next to the sensor indicator, hold the magnet switch in place. When the light flashes twice, remove the magnet.'
    }
  }
}
