import { Component, ViewEncapsulation } from '@angular/core'
import { NavParams } from '@ionic/angular'
import { AlertsPage } from '../../pages/alerts/alerts.page'
import { ModalController } from '@ionic/angular'
import { FilterAlertsModalComponent } from '../filter-alerts-modal/filter-alerts-modal.component'
import { HelpersProvider } from '../../services/helpers/helpers.service'

@Component({
  selector: 'alerts-popover',
  templateUrl: './alerts-popover.component.html',
  styleUrls: ['./alerts-popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertsPopoverComponent {
  alertsPageInstance: AlertsPage
  sortAscending: boolean
  currentState: string = 'menu'
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private helpers: HelpersProvider
  ) {
    this.alertsPageInstance = this.navParams.get('instance')
    this.sortAscending = this.alertsPageInstance.sortAscending
  }

  showFilters() {
    this.close(false)
    this.helpers.showModal(
      FilterAlertsModalComponent,
      undefined,
      undefined,
      undefined,
      'filter-alerts-modal'
    )
  }

  inState(state: string) {
    return state == this.currentState
  }

  saveSortAscending() {
    this.sortAscending = true
    this.close(true)
    this.alertsPageInstance.saveSortAscending()
  }

  saveSortDescending() {
    this.sortAscending = false
    this.close(true)
    this.alertsPageInstance.saveSortDescending()
  }

  clearAllAlerts() {
    this.close(false)
    this.alertsPageInstance.showClearAllAlertsDialog()
  }

  close(animate: boolean) {
    this.modalCtrl.dismiss({ animated: animate })
  }
}
