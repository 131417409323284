import firebase from 'firebase/compat/app'

import { AppDevice } from '../../models/app-device.model'

export enum PageId {
  Loading = 1,
  UserLogin,
  AddDevice,
  SelectDevice,
  DeviceStats,
  SetupWizard,
  ForceUpdate,
  Deeplink,
  AddService,
}

export const resolvePage = (
  allDevices: AppDevice[],
  user: firebase.User,
  device: AppDevice,
  deviceId: string,
  isAddDeviceRoute: boolean,
  isDeeplinkRoute: boolean,
  isAddServiceRoute: boolean,
  mustUpdate: boolean
): PageId => {
  // if the minimum version is not met return forceUpdate page
  if (mustUpdate) {
    return PageId.ForceUpdate
  }

  if (user == null) {
    // Wait for Firebase to initialize
    return PageId.Loading
  }

  if (!user.uid) {
    // Login User
    return PageId.UserLogin
  }

  // User is now logged in
  if (isAddDeviceRoute) {
    // Add Device URL Route
    return PageId.AddDevice
  }

  if (isDeeplinkRoute) {
    // Deeplink URL Route
    return PageId.Deeplink
  }

  if (isAddServiceRoute) {
    // Deeplink URL Route
    return PageId.AddService
  }

  if (allDevices == null) {
    // Wait for device list to be loaded
    return PageId.Loading
  }

  if (allDevices.length <= 0) {
    // User has no devices
    return PageId.AddDevice
  }

  if (deviceId == null) {
    // No Device Selected
    return PageId.SelectDevice
  }

  // Device Selected
  const deviceReady =
    device &&
    device.gps != null &&
    // device.stats != null &&
    device.alerts != null
  if (!deviceReady) {
    return PageId.Loading
  }

  // Device Data Loaded
  return PageId.DeviceStats
}
