<ion-header>
  <ion-toolbar>
    <ion-title>Forget Password</ion-title>
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding small>
  <form [formGroup]="emailForm" class="forget-password-modal">
    <span class="forget-password-modal_text">
      Enter your email address and we will send you a password reset link.
    </span>
    <ion-item class="forget-password-modal_input">
      <ion-label position="stacked">Email</ion-label>
      <ion-input
        type="text"
        [value]="params.get('email')"
        formControlName="email"
      ></ion-input>
    </ion-item>
    <optional-text>
      <ion-spinner small *ngIf="showSpinner"></ion-spinner>
      <span *ngIf="!showSpinner" class="forget-password-modal_text">
        {{ statusMessage }}
      </span>
    </optional-text>
    <ion-button
     
      outline
      class="forget-password-modal_button"
      [disabled]="!emailForm.valid || disableButton"
      color="primary"
      (click)="sendResetPasswordEmail()"
    >
      Reset Password
    </ion-button>
  </form>
</ion-content>
