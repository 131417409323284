import { Environment } from 'app/models/environment.model'

export const FIRE_FUNCTIONS_URL =
  process && process.env && process.env.BRNKL_ENV === 'local'
    ? '//localhost:5000'
    : 'https://us-central1-brnkl-dev.cloudfunctions.net'

export const ENV: Environment = {
  FIRE_FUNCTIONS_URL,
  FIRE_API_KEY: 'AIzaSyAh2zMOJO4BW_zIxrXuBRzmb-CPRx8q6pw',
  FIRE_AUTH_DOMAIN: 'brnkl-dev.firebaseapp.com',
  FIRE_DATABASE_URL: 'https://brnkl-dev.firebaseio.com',
  FIRE_PROJECT_ID: 'brnkl-dev',
  FIRE_STORAGE_BUCKET: 'brnkl-dev.appspot.com',
  FIRE_SENDER_ID: '1025252665438',
  GOOGLE_MAPS_KEY: 'AIzaSyAjrhht5BgpNO972kBN-fUCbiTG3iYPi6A',
  IS_PROD: false,
  SALT: '$2a$10$UybS9dysA4hx/M4D8kxfx.',
  STRIPE_PUB_KEY: 'pk_test_Hdm4xegmpu4p90CkLSbYjxYi',
  IP_LOOKUP_KEY: 'se1YKVu0tpLtjfkHkUXn',
}
