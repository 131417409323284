<ion-content class="add-wireless-sensor-content">
  <!-- Removed onBackPressed()-->
  <setup-container
    [title]="select.title"
    [iconName]="select.iconName"
    [backButton]="select.backButton"
    [exitButton]="select.exitButton"
    (exit)="onExitPressed()"
  >
    <div class="setup-select-wireless-device_container">
      <div class="setup-select-wireless_header">Select</div>
      <div class="setup-select-wireless_tagline">
        Select the device you want to add.
      </div>
      <div
        class="setup-select-wireless-device_padbox"
        *ngIf="!isMateLinked() && displayDoorWindowTile"
        (click)="deviceSelected(mateInfo)"
      >
        <setup-device-box
          [ngClass]="{ 'setup-device-box_disabled': mateInfo.disabled }"
          [iconName]="mateInfo.iconName"
          [header]="mateInfo.companyName"
          [deviceName]="mateInfo.name"
        ></setup-device-box>
      </div>
      <div
        class="setup-select-wireless-device_padbox"
        *ngIf="!isSatelliteLinked() && displayDoorWindowTile"
        (click)="deviceSelected(satelliteInfo)"
      >
        <setup-device-box
          [ngClass]="{ 'setup-device-box_disabled': satelliteInfo.disabled }"
          [iconName]="satelliteInfo.iconName"
          [header]="satelliteInfo.companyName"
          [deviceName]="satelliteInfo.name"
        ></setup-device-box>
      </div>
      <div
        class="select-wireless-door-window"
        (click)="toggleDoorWindowSensors(displayDoorWindowTile)"
      >
        <div [ngClass]="{ 'setup-device-box_disabled': !isMateLinked() }">
          <div
            [ngClass]="{
              'setup-device-box': true,
              'back-to-main-menu': !displayDoorWindowTile
            }"
          >
            <ion-grid>
              <ion-row>
                <ion-col
                  *ngIf="!displayDoorWindowTile"
                  size="auto"
                  class="setup-box-back-nav"
                >
                  <ion-icon
                    class="door-window-nav-arrow"
                    name="custom-back-navigation"
                  >
                  </ion-icon
                ></ion-col>
                <ion-col size="3" class="setup-box-icon">
                  <ion-icon [name]="'custom-door-window-sensors'"></ion-icon>
                </ion-col>
                <ion-col size="auto" class="setup-box-text">
                  <div class="setup-box-door-window-name">
                    Door and window<br />sensors
                  </div>
                </ion-col>
                <ion-col
                  *ngIf="displayDoorWindowTile"
                  size="auto"
                  float-right
                  class="setup-box-forward-nav"
                >
                  <ion-icon
                    class="door-window-nav-arrow"
                    name="custom-forward-navigation"
                  ></ion-icon>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
      </div>
      <div
        class="setup-select-wireless-device_padbox"
        *ngFor="let sensor of sensorList"
        (click)="deviceSelected(sensor)"
      >
        <setup-device-box
          [ngClass]="{ 'setup-device-box_disabled': sensor.disabled }"
          [iconName]="sensor.iconName"
          [header]="sensor.companyName"
          [deviceName]="sensor.name"
        ></setup-device-box>
      </div>
    </div>
  </setup-container>
</ion-content>
