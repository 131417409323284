<ion-header>
  <ion-toolbar>
    <ion-title>sandbox</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <panel-container>
    <top-panel> Top panel stuff </top-panel>
    <bottom-panel> Bottom panel stuff </bottom-panel>
  </panel-container>
</ion-content>
