/**
 * List of serializable strings for dispatched
 * Redux actions (only used by app and _not_ functions)
 */
export const ActionTypes = {
  SET_CURRENT_USER_SETTINGS: 'SET_CURRENT_USER_SETTINGS',
  SET_CURRENT_DEVICE_SETTINGS: 'SET_CURRENT_DEVICE_SETTINGS',
  SET_CURRENT_DEVICE_CELL_STRENGTH: 'SET_CURRENT_DEVICE_CELL_STRENGTH',
  SET_CURRENT_DEVICE_GRAPH_LOADING: 'SET_CURRENT_DEVICE_GRAPH_LOADING',
  SET_CURRENT_DEVICE_GRAPH_DATA: 'SET_CURRENT_DEVICE_GRAPH_DATA',
  SET_CURRENT_DEVICE_SENSOR_CONFIG: 'SET_CURRENT_DEVICE_SENSOR_CONFIG',
  SET_CURRENT_DEVICE_PHOTOS: 'SET_CURRENT_DEVICE_PHOTOS',
  FLUSH_TO_DEFAULT_STATE: 'FLUSH_TO_DEFAULT_STATE',
  FLUSH_CURRENT_DEVICE: 'FLUSH_CURRENT_DEVICE',
  SET_CURRENT_DEVICE_ALERTS: 'SET_CURRENT_DEVICE_ALERTS',
  USER_TRIGGERED_LOGIN: 'USER_TRIGGERED_LOGIN',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_ALL_DEVICES: 'SET_ALL_DEVICES',
  SET_CURENT_DEVICE_ANCHOR_CONFIG: 'SET_CURENT_DEVICE_ANCHOR_CONFIG',
  SET_CURRENT_DEVICE_STAT_LAST_UPDATED: 'SET_CURRENT_DEVICE_STAT_LAST_UPDATED',
  SET_CURRENT_DEVICE_GPS: 'SET_CURRENT_DEVICE_GPS',
  SET_CURRENT_DEVICE_ID: 'SET_CURRENT_DEVICE_ID',
  SET_CURRENT_DEVICE_STATUS: 'SET_CURRENT_DEVICE_STATUS',
  SET_LOADING: 'SET_LOADING'
}
