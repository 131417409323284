import { NgRedux } from '@angular-redux/store'
import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { State } from 'app/models/state.model'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class DeviceGuard implements CanActivate {
  constructor(private ngRedux: NgRedux<State>, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // will check if redux has loaded current user and user settings
    // if not then it will re-direct to layout page
    if (this.ngRedux.getState().allDevices.length > 0) return true

    return this.router.parseUrl('/add-device')
  }
}
