import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class AnalyticsProvider {
  constructor() {}
  async reportError(err: Error): Promise<void> {
    // tslint:disable-next-line
    console.error(err)
  }
}
