<ion-header class="subscription-header">
  <ion-toolbar>
    <ion-title>Subscription</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="subscription_bg">
  <ion-grid>
    <ion-col size="12" size-sm="10" class="subscription_web-container">
      <div *ngIf="isMobileDevice" class="subscription_mobile-container">
        <h2 class="subscription_header">Pay Online</h2>
        <div class="subscription_webapp-btn-container">
          <ion-button icon-end (click)="launchWebApp()">
            Open BRNKL Web App
            <ion-icon name="link"></ion-icon>
          </ion-button>
        </div>
        <p class="subscription_webapp-text">
          Payments are only supported through the BRNKL web app.
        </p>
      </div>
      <div *ngIf="!isMobileDevice">
        <card>
          <h2 header>Purchase a BRNKL Subscription</h2>
          <h3 aside class="subscription__webapp-aside">Step 1</h3>
          <subscription
            [email]="(currentUser$ | async)?.email"
            (payment)="payment$.emit($event)"
            [deviceId]="deviceId"
          >
          </subscription>
        </card>
        <card *ngIf="paid">
          <h2 header>Purchase Complete</h2>
          <h3 aside class="subscription__webapp-aside">Step 2</h3>
          <div class="subscription_webapp-btn-container">
            <ion-button icon-end (click)="goBack()">
              Return to Vessel Settings
            </ion-button>
          </div>
        </card>
        <div #scrollPoint class="subscription_bottom"></div>
      </div>
    </ion-col>
  </ion-grid>
</ion-content>
