<ion-content class="add-wireless-sensor-content" [ngSwitch]="currentStep.name">
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="back()"
    (exit)="exit()"
  >
    <setup-info
      *ngSwitchCase="'start'"
      (next)="currentStep.next()"
      header="Update"
      [description]="
        'Update Mate to version ' + modalParams.updateInfo.version + '.'
      "
      nextButtonText="Next"
    ></setup-info>
    <setup-info
      *ngSwitchCase="'warning'"
      (next)="currentStep.next()"
      header="Stay Close"
      description="Stay within 5m of the Mate during the update."
      nextButtonText="Next"
    ></setup-info>
    <setup-progress
      *ngSwitchCase="'updateProgress'"
      [header]="updateProgressHeader"
      [description]="updateProgressDescription"
      [progressIconName]="updateProgressIcon"
      [progressStep]="updateProgressText"
      [progressPercent]="updateProgressPercent"
      [doneButtonText]="updateProgressButtonText"
      (cancel)="currentStep.next()"
    ></setup-progress>
  </setup-container>
</ion-content>
