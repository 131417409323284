import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { Subscription } from 'rxjs'
import { BaseInputComponent } from '../base-input/base-input.component'

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBarComponent),
      multi: true,
    },
  ],
})
export class SearchBarComponent
  extends BaseInputComponent
  implements ControlValueAccessor
{
  @Input() isLoading$: EventEmitter<boolean> = new EventEmitter()
  @Input() ngModel: string = ''
  @Input() inputPlaceholder: string = ''
  @Input() validInput: any
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter()
  @Output() clickSearch: EventEmitter<void> = new EventEmitter()
  subscription: Subscription
}
