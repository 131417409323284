import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgRedux, select } from '@angular-redux/store'
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs'
import { NavParams, ModalController } from '@ionic/angular'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'

import { DeviceStatus } from '../../models-shared/device-status'
import { DeviceProvider } from '../../services/device/device.service'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { AppDevice } from '../../models/app-device.model'
import { AuthProvider } from '../../services/auth/auth.service'

import firebase from 'firebase/compat/app'
import { AddServiceModal } from '../../components/add-service-modal/add-service-modal.component'
import { ActivatedRoute } from '@angular/router'
import { NavDataService } from 'app/services/navigation/navigation.service'
import { State } from 'app/models/state.model'
import { isBlueDevice } from 'app/util'

export const MIN_PASSWORD_LENGTH: number = 8
export const SEARCH_DEBOUNCE_SEC: number = 1

export enum AddState {
  SEARCH = 'SEARCH',
  SUBSCRIBE = 'SUBSCRIBE',
  WEBAPP = 'WEBAPP',
  PASSWORD_CREATE = 'PASSWORD_CREATE',
  PASSWORD_CREATE_SUBSCRIBED = 'PASSWORD_CREATE_SUBSCRIBED',
  PASSWORD_LOGIN = 'PASSWORD_LOGIN',
}

const defaultDeviceStatus: DeviceStatus = {
  exists: false,
  alreadyAdded: false,
  isClaimed: false,
  subscribed: false,
}

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.page.html',
  styleUrls: ['./add-device.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddDevicePage implements OnInit {
  status$ = new BehaviorSubject<AddState>(AddState.SEARCH)
  payment$ = new Subject<boolean>()
  deviceId: string = ''
  paramDeviceId: string = ''
  deviceStatus: DeviceStatus = defaultDeviceStatus
  addNewDeviceForm: FormGroup
  addExistingDeviceForm: FormGroup
  isMobileDevice: boolean
  subscriptions: Subscription[] = []
  linkingDevice: boolean = false
  showPassword: boolean = false

  private refreshSettings: () => void

  @ViewChild('scrollPoint') scrollPoint: ElementRef

  @select(['currentUser'])
  currentUser$: Observable<firebase.User>

  @select('allDevices')
  allDevices$: Observable<AppDevice[]>

  constructor(
    public params: NavParams,
    private device: DeviceProvider,
    private formBuilder: FormBuilder,
    private helpers: HelpersProvider,
    private environment: EnvironmentProvider,
    private auth: AuthProvider,
    public viewCtrl: ModalController,
    private route: ActivatedRoute,
    private navData: NavDataService,
    private iap: InAppBrowser,
    private ngRedux: NgRedux<State>
  ) {
    // when cordova comes back from idle, we need to reload the page
    // this is because the user may have logged in and we need to update the UI
    document.addEventListener('resume', () => {
      this.onValidDeviceId(this.deviceId)
    })
  }

  ngOnInit() {
    this.addNewDeviceForm = this.buildNewDeviceForm()
    this.addExistingDeviceForm = this.buildExistingDeviceForm()
    // make sure we only compute this once
    this.isMobileDevice = this.environment.isNativeApp()
    this.refreshSettings = this.params.get('refreshSettings')
    this.subscribe()

    let deviceId = this.route.snapshot.paramMap.get('deviceId')
      ? this.route.snapshot.paramMap.get('deviceId')
      : ''
    if (deviceId) {
      this.paramDeviceId = deviceId.trim().toUpperCase()
    } else {
      let navData = this.navData.get('deviceId')
      if (navData)
        this.paramDeviceId = this.navData.get('deviceId').trim().toUpperCase()
    }
  }

  subscribe() {
    if (!this.environment.isNativeApp()) {
      const sub = this.payment$.subscribe(async (success: boolean) => {
        if (success) {
          this.deviceStatus = await this.device.deviceStatus(this.deviceId)
          this.status$.next(AddState.PASSWORD_CREATE_SUBSCRIBED)
          this.scrollPoint.nativeElement.scrollIntoView(true)
        }
      })

      this.subscriptions.push(sub)
    } else {
      const sub = this.allDevices$.subscribe((devices: AppDevice[]) => {
        for (const device of devices) {
          if (device.deviceId === this.deviceId) {
            this.pushTabs(this.deviceId)
          }
        }
      })
      this.subscriptions.push(sub)
    }
  }
  ngOnDestroy() {
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  async onValidDeviceId(deviceId: string) {
    if (!deviceId.length) {
      this.status$.next(AddState.SEARCH)
      this.deviceId = ''
      this.deviceStatus = defaultDeviceStatus
      this.addExistingDeviceForm.reset({
        password: '',
        confirmPassword: '',
        deviceName: '',
      })
      this.addNewDeviceForm.reset({
        password: '',
      })
      return
    }
    const deviceStatus = await this.device.deviceStatus(deviceId)
    if (
      deviceStatus == null ||
      !deviceStatus.exists ||
      deviceStatus.alreadyAdded
    ) {
      return
    }

    this.deviceStatus = deviceStatus
    this.deviceId = deviceId

    if (deviceStatus.isClaimed) {
      this.status$.next(AddState.PASSWORD_LOGIN)
      this.scrollPoint.nativeElement.scrollIntoView(true)
      return
    }

    // Remove Keyboard Focus
    if (document.activeElement instanceof HTMLInputElement) {
      document.activeElement.blur()
    }
    if (!deviceStatus.subscribed && !isBlueDevice(deviceId)) {
      if (this.isMobileDevice) {
        this.status$.next(AddState.WEBAPP)
      } else {
        this.status$.next(AddState.SUBSCRIBE)
      }
      this.scrollPoint.nativeElement.scrollIntoView(true)
    } else {
      this.status$.next(AddState.PASSWORD_CREATE)
      this.scrollPoint.nativeElement.scrollIntoView(true)
    }
  }

  launchWebApp() {
    if (this.isMobileDevice) {
      const url = this.environment.webUrl + `/add-device/${this.deviceId}`
      this.iap.create(url, '_system')
    }
  }

  buildNewDeviceForm(): FormGroup {
    return this.formBuilder.group(
      {
        deviceName: ['', [Validators.required]],
        password: ['', [Validators.required]],
        confirmPassword: ['', Validators.required],
      },
      { validator: (group: FormGroup) => this.validateGroup(group) }
    )
  }

  buildExistingDeviceForm(): FormGroup {
    return this.formBuilder.group({
      password: ['', [Validators.required]],
    })
  }

  disableAddExistingButton() {
    const { isClaimed, alreadyAdded, exists } = this.deviceStatus
    return (
      !(
        exists &&
        !alreadyAdded &&
        isClaimed &&
        this.addExistingDeviceForm.valid
      ) || this.linkingDevice
    )
  }

  disableAddNewButton() {
    const { exists, alreadyAdded, isClaimed, subscribed } = this.deviceStatus

    return (
      !(exists &&
      !alreadyAdded &&
      !isClaimed &&
      this.addNewDeviceForm.valid &&
      isBlueDevice(this.deviceId) // if Blue then bypass subscribed
        ? true
        : subscribed) || this.linkingDevice
    )
  }

  getAddDeviceError() {
    const errors: string[] = []
    const { password, confirmPassword, deviceName } =
      this.addNewDeviceForm.controls

    if (deviceName.dirty && this.addNewDeviceForm.hasError('noDeviceName')) {
      errors.push('Vessel must have a name')
    }

    if (password.dirty || confirmPassword.dirty) {
      if (this.addNewDeviceForm.hasError('notSame')) {
        errors.push('Passwords do not match')
      }

      if (this.addNewDeviceForm.hasError('tooShort')) {
        errors.push(`Password must be ${MIN_PASSWORD_LENGTH} characters long`)
      }
    }

    return errors
  }

  validateGroup(group: FormGroup): any {
    if (!this.status$.value.startsWith('PASSWORD_CREATE')) {
      return null
    }

    const passMatch: boolean =
      group.controls.password.value === group.controls.confirmPassword.value

    const lenMatch: boolean =
      group.controls.password.value.length >= MIN_PASSWORD_LENGTH

    const deviceNameMatch: boolean = !!group.controls.deviceName.value

    if (!passMatch || !lenMatch || !deviceNameMatch) {
      return {
        notSame: !passMatch,
        tooShort: !lenMatch,
        noDeviceName: !deviceNameMatch,
      }
    } else return null
  }

  async linkDevice(newDevice: boolean) {
    this.linkingDevice = true
    this.helpers.startLoading('Adding Vessel...')
    let didWork = false
    try {
      if (newDevice) {
        await this.linkNewDevice()
      } else {
        await this.linkExisitingDevice()
      }
      didWork = true
    } catch (err) {}
    await this.helpers.stopLoading()

    this.linkingDevice = false
    if (didWork) {
      this.pushTabs(this.deviceId)
    } else {
      this.showFailToast()
    }
  }

  async linkExisitingDevice() {
    const { password } = this.addExistingDeviceForm.controls
    await this.device.linkDevice(this.deviceId, password.value, '')
  }

  async linkNewDevice() {
    const { password, deviceName } = this.addNewDeviceForm.controls
    await this.device.linkDevice(
      this.deviceId,
      password.value,
      deviceName.value
    )
  }

  pushTabs(deviceId: string): void {
    this.device.setCurrentDeviceId(deviceId)
  }

  showFailToast(): void {
    if (this.status$.value === AddState.PASSWORD_LOGIN) {
      this.helpers.showDangerToast('Password incorrect')
    } else {
      this.helpers.showDangerToast('Failed to set password')
    }
  }

  handleShowPassword(): void {
    this.showPassword = !this.showPassword
  }

  logout(): void {
    this.auth.logout()
  }

  isRoot(): boolean {
    // TODO: find better way too check if root
    //return !this.navCtrl.canGoBack()
    //displays logout button
    return this.ngRedux.getState().allDevices.length < 1
  }

  showAddServiceModal = () => {
    this.helpers.showModal(
      AddServiceModal,
      {
        refreshSettings: this.refreshSettings,
        serviceType: 'cellular',
        newDeviceId: this.deviceId,
      },
      true,
      false,
      'setup-modal-container'
    )
  }
}
