import range from 'lodash/range'
import { Component, ViewEncapsulation } from '@angular/core'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { select } from '@angular-redux/store'
import { Subscription } from 'rxjs'
import { AppDevice } from '../../models/app-device.model'
import { DeviceProvider } from 'app/services/device/device.service'
import { offlineTimeout } from 'app/util'

@Component({
  selector: 'signal-indicator',
  templateUrl: './signal-indicator.component.html',
  styleUrls: ['./signal-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignalIndicatorComponent {
  @select('currentDevice') currentDevice$: Observable<AppDevice>
  @select(['currentDevice', 'cellstrength']) cellstrength$: Observable<number>

  lastUpdated: number
  deviceOffline: boolean
  satelliteConnection: boolean
  nBars: number[] = range(1, 5)
  signalLevel: number

  private subscription: Subscription
  constructor(private device: DeviceProvider) {}

  ngOnInit() {
    this.currentDevice$
      .pipe(
        filter((device) => device != null),
        filter((device) => device.settings != null)
      )
      .subscribe((device) => {
        this.lastUpdated = device.settings.lastUpdated
        this.satelliteConnection =
          device.settings.lastUpdatedSource === 'satellite'
        // check if lastUpdated timestamp (in seconds) was 3 or more hours ago
        this.deviceOffline = this.lastUpdated <= offlineTimeout
      })
    this.subscription = this.cellstrength$.subscribe((level) => {
      this.signalLevel = level === 5 ? 4 : level
    })
    if (this.deviceOffline) {
      this.signalLevel = null
    }
  }

  isBlueDevice() {
    return this.device.isBlueDevice()
  }

  ngOnDestory() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
