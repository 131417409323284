<ion-button
  fill="clear"
  class="toolbar-button"
  (click)="navigateToAlerts()"
  slot="end"
>
  <ion-icon name="notifications-outline" slot="icon-only"></ion-icon>
  <ion-icon
    name="ellipse"
    class="toolbar-badge"
    color="danger"
    *ngIf="newAlert"
  ></ion-icon>
</ion-button>
