<div class="text-input_container">
  <label class="text-input_label {{ labelClass }}" *ngIf="label">
    {{ label }}
    <ng-container *ngIf="required">*</ng-container>
  </label>
  <input
    class="{{ class }} text-input_input"
    [disabled]="disableInput"
    [required]="required"
    [type]="type"
    (ngModelChange)="ngModelChange.emit($event)"
    [ngModel]="ngModel"
    [placeholder]="placeholder"
  />
</div>
