import {
  Component,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core'
import { WirelessSensorDisplay } from '../../models/wireless-sensor-display.model'
import { SensorTypes } from '../../models-shared/sensor-types'
import { SetupStep } from '../../models/setup-step.model'
import { BaseSetupModalComponent } from '../../components/base-setup-modal/base-setup-modal.component'
import { ModalController, NavParams } from '@ionic/angular'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { LinkMateModal } from '../../components/link-mate-modal/link-mate-modal.component'
import { LinkSatelliteModal } from '../../components/link-satellite-modal/link-satellite-modal.component'
import { AddWirelessSensorModalComponent } from '../../components/add-wireless-sensor-modal/add-wireless-sensor-modal.component'
import { DeviceProvider } from '../../services/device/device.service'
import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import { SettingsProvider } from '../../services/settings/settings.service'
import { UpdateMateProvider } from '../../services/update-mate/update-mate.service'

@Component({
  selector: 'settings-add-device-modal',
  templateUrl: './settings-add-device-modal.component.html',
  styleUrls: ['./settings-add-device-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsAddDeviceModal extends BaseSetupModalComponent {
  @Output() deviceSelect = new EventEmitter()
  select: SetupStep = {
    name: 'select',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: true,
  }

  wirelessSensorList: WirelessSensorDisplay[] = []

  displayDoorWindowTile: boolean = true

  mateInfo: WirelessSensorDisplay = {
    companyName: 'BRNKL',
    name: 'Mate',
    iconName: 'custom-mate',
  }

  satelliteInfo: WirelessSensorDisplay = {
    companyName: 'BRNKL',
    name: 'Satellite Device',
    iconName: 'custom-satellite',
  }

  sensorList: WirelessSensorDisplay[] = []

  private refreshSettings: () => void

  constructor(
    viewCtrl: ModalController,
    private navParams: NavParams,
    private helpers: HelpersProvider,
    private device: DeviceProvider,
    private bluetoothLEProvider: BluetoothLEProvider,
    private environmentProvider: EnvironmentProvider,
    private settingsProvider: SettingsProvider,
    private updateMateProvider: UpdateMateProvider
  ) {
    super(viewCtrl)
    this.mateInfo = this.createMateInfoList()
    this.satelliteInfo = this.createSatelliteInfoList()
    this.wirelessSensorList = this.createWirelessSensorsList()
    this.sensorList = this.wirelessSensorList
    this.createWirelessSensorsList()
    this.createWirelessDoorWindowSensorsList()
    this.refreshSettings = this.navParams.get('refreshSettings')
  }

  createMateInfoList(): WirelessSensorDisplay {
    return {
      name: 'Mate',
      companyName: 'BRNKL',
      iconName: 'custom-mate',
      disabled: this.isMateLinked(),
    }
  }

  createSatelliteInfoList(): WirelessSensorDisplay {
    return {
      name: 'RockBLOCK+',
      companyName: 'Rock Seven',
      iconName: 'custom-satellite',
      disabled: this.isSatelliteLinked(),
    }
  }

  createWirelessDoorWindowSensorsList(): WirelessSensorDisplay[] {
    return [
      {
        companyName: 'BRNKL',
        name: 'Door/Window Sensor',
        iconName: 'custom-brnkl-door-sensor',
        type: SensorTypes.WirelessMulti,
        disabled: !this.isMateLinked(),
      },
      {
        companyName: 'Samsung SmartThings',
        name: 'Multipurpose Sensor',
        iconName: 'custom-multipurpose',
        type: SensorTypes.WirelessMulti,
        disabled: !this.isMateLinked(),
      },
      {
        companyName: 'Sengled',
        name: 'Smart Window and \nDoor Sensor',
        iconName: 'custom-sengled-door-sensor',
        type: SensorTypes.WirelessMulti,
        disabled: !this.isMateLinked(),
      },
    ]
  }

  createWirelessSensorsList(): WirelessSensorDisplay[] {
    return [
      {
        companyName: 'BRNKL',
        name: 'Key Fob',
        iconName: 'custom-keyfob',
        type: SensorTypes.WirelessKeyfob,
        disabled: !this.isMateLinked(),
      },
      {
        companyName: 'BRNKL',
        name: 'Keypad',
        iconName: 'custom-keypad',
        type: SensorTypes.WirelessKeypad,
        disabled: !this.isMateLinked(),
      },
      {
        companyName: 'Samsung SmartThings',
        name: 'Motion Sensor',
        iconName: 'custom-motion',
        type: SensorTypes.WirelessMotion,
        disabled: !this.isMateLinked(),
      },
      {
        companyName: 'Samsung SmartThings',
        name: 'Water Leak Sensor',
        iconName: 'custom-water',
        type: SensorTypes.WirelessWater,
        disabled: !this.isMateLinked(),
      }
      // {
      //   companyName: 'V-Mark',
      //   name: 'Temperature Tag',
      //   iconName: 'custom-temp-sensor',
      //   type: SensorTypes.WirelessTemp,
      //   disabled: !this.isMateLinked()
      // }
    ]
  }

  toggleDoorWindowSensors(displayDoorWindowTile: boolean) {
    if (displayDoorWindowTile) {
      this.sensorList = this.createWirelessDoorWindowSensorsList()
    } else {
      this.sensorList = this.wirelessSensorList
    }
    this.displayDoorWindowTile = !this.displayDoorWindowTile
  }

  async deviceSelected(sensor: WirelessSensorDisplay) {
    this.deviceSelect.emit(sensor)
    await this.updateMateProvider.checkForMateUpdate(false)
    const mateUpdateAvailable =
      this.updateMateProvider.mateUpdateAvailable$.value
    if (!this.isNativeApp()) {
      this.helpers.showDangerToast(
        'Use the iOS or Android version of the BRNKL app to add Mate/Sensors.'
      )
    } else if (sensor.name === this.mateInfo.name && !this.isMateLinked()) {
      this.openLinkMateModal()
    } else if (
      sensor.name === this.satelliteInfo.name &&
      !this.isSatelliteLinked()
    ) {
      this.openLinkSatelliteModal()
    } else if (this.isBLEConnected() && this.isMateLinked()) {
      if (!mateUpdateAvailable) {
        this.openAddWirelessSensorPage(sensor)
      } else {
        this.updateMateProvider.openUpdateMateModal()
      }
    } else if (!this.isMateLinked()) {
      this.helpers.showDangerToast(
        'Add a BRNKL Mate before adding wireless devices.'
      )
    } else if (!this.isBLEConnected()) {
      // Attempt to Connect to Mate
      this.helpers.startLoading('Connecting to Mate')

      try {
        await this.bluetoothLEProvider.connectToMate(
          this.device.currentBRNKLandMateId$.value.mateId
        )
        this.helpers.stopLoading()

        if (!mateUpdateAvailable) {
          this.openAddWirelessSensorPage(sensor)
        } else {
          this.updateMateProvider.openUpdateMateModal()
        }
      } catch (e) {
        this.helpers.stopLoading()
        this.helpers.showDangerToast(
          `Failed to find the Mate. Connecting to the Mate is required to add devices.`
        )
      }
    }
  }

  isNativeApp(): boolean {
    return this.environmentProvider.isNativeApp()
  }

  isBLEConnected(): boolean {
    return this.bluetoothLEProvider.connected.value
  }

  onExitPressed(): void {
    this.exit()
  }

  isMateLinked() {
    return this.device.isMateLinked()
  }

  isSatelliteLinked() {
    return this.device.isSatelliteLinked()
  }

  openLinkMateModal = () => {
    this.helpers.showModal(
      LinkMateModal,
      {
        refreshSettings: this.refreshSettings,
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }

  openLinkSatelliteModal = () => {
    this.helpers.showModal(
      LinkSatelliteModal,
      {
        refreshSettings: this.refreshSettings,
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }

  openAddWirelessSensorPage = (selectedSensor: WirelessSensorDisplay) => {
    if (this.settingsProvider.maxWirelessSensors) {
      this.helpers.showDangerToast(
        'Cannot add another wireless sensor. Maximum capacity has been reached!'
      )
      return
    }

    this.helpers.showModal(
      AddWirelessSensorModalComponent,
      {
        refreshSettings: this.refreshSettings,
        selectedSensor,
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }
}
