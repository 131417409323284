<ion-header>
  <ion-toolbar class="toolbar-background">
    <ion-buttons class="tabbar-tile-start" slot="start">
      <arm-disarm-button></arm-disarm-button>
    </ion-buttons>
    <ion-buttons class="tabbar-col-center">
      <top-bar-title
        class="tabbar-title-overflow toolbar-title"
      ></top-bar-title>
    </ion-buttons>
    <ion-buttons class="tabbar-tile-end" slot="end">
      <ion-button
        class="toolbar-button"
        icon-only
        (click)="presentPopover($event)"
      >
        <ion-icon class="toolbar-title" name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding *ngIf="thumbnails$ | async as thumbnails">
  <ion-list>
    <!-- No alerts message if the list is empty -->
    <ion-item *ngIf="!(visibleAlerts$ | async)?.length">No Alerts</ion-item>

    <!-- Loop through alerts -->
    <ng-container
      *ngFor="let alert of visibleAlerts$ | async; let i = index; trackBy: trackByAlertId"
    >
      <!-- Handle multiple videos in alert.videos array -->
      <ng-container
        *ngIf="alert.videos && alert.videos.length; else singleVideoTemplate"
      >
        <div *ngFor="let video of alert.videos; let j = index; trackBy: trackByVideoId">
          <ion-item
            *ngIf="video.active !== false"
            detail="false"
            button
            (click)="showDetails(alert,alert.alertId + '-' + j, thumbnails[alert.alertId + '-' + j]?.url, thumbnails[alert.alertId + '-' + j]?.cameraName)"
          >
            <div class="thumbnail">
              <!-- Spinner or thumbnail image -->
              <ng-container
                *ngIf="!thumbnails[alert.alertId + '-' + j] || thumbnails[alert.alertId + '-' + j]?.loading; else thumbnailTemplate"
              >
                <ion-spinner></ion-spinner>
              </ng-container>
              <ng-template #thumbnailTemplate>
                <img
                  [src]="thumbnails[alert.alertId + '-' + j]?.url"
                  (load)="thumbnails[alert.alertId + '-' + j].loading = false"
                />
              </ng-template>
            </div>
            <ion-label class="ion-text-wrap">
              <div class="alerts_title">{{ alert.title }}</div>
              <div class="alerts_date">
                {{ thumbnails[alert.alertId + '-' + j]?.cameraName }}
              </div>
              <div class="alerts_date">
                {{ formatAlertDate(alert.datetime) }}
              </div>
            </ion-label>
            <ion-icon slot="end" name="chevron-forward"></ion-icon>
          </ion-item>
        </div>
      </ng-container>

      <!-- Handle a single video object -->
      <ng-template #singleVideoTemplate>
        <ion-item
          *ngIf="alert.video; else noVideoTemplate"
          detail="false"
          button
          (click)="showDetails(alert, alert.alertId, thumbnails[alert.alertId]?.url, thumbnails[alert.alertId]?.cameraName)"
        >
          <div class="thumbnail">
            <!-- Spinner or thumbnail image for single video -->
            <ng-container
              *ngIf="!thumbnails[alert.alertId] || thumbnails[alert.alertId]?.loading; else singleThumbnailTemplate"
            >
              <ion-spinner></ion-spinner>
            </ng-container>
            <ng-template #singleThumbnailTemplate>
              <img
                [src]="thumbnails[alert.alertId]?.url"
                (load)="thumbnails[alert.alertId].loading = false"
              />
            </ng-template>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="alerts_title">{{ alert.title }}</div>
            <div class="alerts_date">
              {{ thumbnails[alert.alertId]?.cameraName }}
            </div>
            <div class="alerts_date">{{ formatAlertDate(alert.datetime) }}</div>
          </ion-label>
          <ion-icon slot="end" name="chevron-forward"></ion-icon>
        </ion-item>
      </ng-template>

      <!-- Handle no video case -->
      <ng-template #noVideoTemplate>
        <ion-item
          detail="false"
          button
          (click)="showDetails(alert, alert.alertId, null, null)"
        >
          <ion-icon
            *ngIf="!alert.video"
            name="alert-circle"
            class="alert-icon"
          ></ion-icon>
          <ion-label class="ion-text-wrap">
            <div class="alerts_title">{{ alert.title }}</div>
            <div class="alerts_date">{{ formatAlertDate(alert.datetime) }}</div>
          </ion-label>
          <ion-icon slot="end" name="chevron-forward"></ion-icon>
        </ion-item>
      </ng-template>
    </ng-container>
  </ion-list>

  <!-- Infinite scroll -->
  <div>
    <ion-infinite-scroll (ionInfinite)="scrollDown($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</ion-content>
