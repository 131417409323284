import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DbPathsProvider {
  public deviceSettings(deviceId: string): string {
    return `${this.deviceObject(deviceId)}/settings`
  }

  public mateSettings(mateId: string): string {
    return `${this.mateObject(mateId)}/settings`
  }

  public satModemSettings(satModemId: string): string {
    return `${this.satModemObject(satModemId)}/settings`
  }

  public deviceName(deviceId: string): string {
    return `${this.deviceSettings(deviceId)}/deviceName`
  }

  public deprecatedNotificationTokens(deviceId: string): string {
    return `${this.deviceSettings(deviceId)}/pushNotificationTokens`
  }

  public notificationTokens(deviceId: string, uid: string): string {
    return `${this.deviceObject(deviceId)}/pushNotificationTokens/${uid}`
  }

  public deviceObject(deviceId: string): string {
    let path = 'devices'

    if (deviceId.startsWith('BBLK')) {
      path = 'blacks'
    } else if (deviceId.startsWith('BBLU')) {
      path = 'blues'
    }
    return `/${path}/${deviceId}`
  }

  public deviceUsers(deviceId: string): string {
    return `${this.deviceObject(deviceId)}/uids`
  }

  public mateObject(mateId: string): string {
    return `/mates/${mateId}`
  }

  public satModemObject(satModemId: string): string {
    return `/satModems/${satModemId}`
  }

  public anchorConfig(deviceId: string): string {
    return `${this.deviceObject(deviceId)}/anchorConfig`
  }

  public alertsSortAscending(deviceId: string): string {
    return `${this.alertsConfig(deviceId)}/sortAscending`
  }

  public alertsLastAllClearedDatetime(deviceId: string): string {
    return `${this.alertsConfig(deviceId)}/lastAllClearedDatetime`
  }

  public alertsConfig(deviceId: string): string {
    return `${this.deviceSettings(deviceId)}/alertsConfig`
  }

  public sensorConfig(deviceId: string): string {
    return `${this.deviceSettings(deviceId)}/sensorConfig`
  }

  public mateSensorConfig(mateId: string): string {
    return `${this.mateSettings(mateId)}/sensorConfig`
  }

  public userSettings(uid: string): string {
    return `/users/${uid}`
  }

  public deviceList(uid: string): string {
    return `${this.userSettings(uid)}/devices`
  }

  public linkedMate(deviceId: string): string {
    return `${this.deviceObject(deviceId)}/settings/linkedMate`
  }

  public linkedSatModem(deviceId: string): string {
    return `${this.deviceObject(deviceId)}/settings/linkedSatModem`
  }

  public alertsList(deviceId: string): string {
    return `${this.deviceObject(deviceId)}/alerts`
  }

  public mateAlertsList(mateId: string): string {
    return `${this.mateObject(mateId)}/alerts`
  }

  public photosList(deviceId: string, camId: string): string {
    return `${this.deviceObject(deviceId)}/photolist/${camId}`
  }

  public videosList(deviceId: string, camId: string): string {
    return `${this.deviceObject(deviceId)}/videoList/${camId}`
  }

  public matePhotosList(mateId: string, camId: string): string {
    return `${this.mateObject(mateId)}/photolist/${camId}`
  }

  public photo(deviceId: string, camId: string): string {
    return `${this.deviceObject(deviceId)}/photos/${camId}`
  }

  public matePhoto(mateId: string, camId: string): string {
    return `${this.mateObject(mateId)}/photos/${camId}`
  }

  public sensorStatList(deviceId: string, sensorKey: string): string {
    return `${this.deviceObject(deviceId)}/status/${sensorKey}`
  }

  public mateSensorStatList(mateId: string, sensorKey: string): string {
    return `${this.mateObject(mateId)}/status/${sensorKey}`
  }

  public mapConfig(deviceId: string): string {
    return `${this.deviceSettings(deviceId)}/mapConfig`
  }

  public cameraConfig(deviceId: string): string {
    return `${this.deviceSettings(deviceId)}/cameraConfig`
  }

  public camera(deviceId: string, camId: string): string {
    return `${this.cameraConfig(deviceId)}/${camId}`
  }

  public mateCamera(mateId: string, camId: string): string {
    return `${this.mateSettings(mateId)}/cameraConfig/${camId}`
  }

  public backendSettings(deviceId: string): string {
    return `${this.deviceObject(deviceId)}/backendSettings`
  }

  public deviceLatestStatus(deviceId: string): string {
    return `${this.deviceObject(deviceId)}/latestStatus`
  }

  public latestSensorStatus(deviceId: string, sensor: string): string {
    return `${this.deviceObject(deviceId)}/latestStatus/${sensor}`
  }

  public mateLatestSensorStatus(mateId: string, sensor: string): string {
    return `${this.mateObject(mateId)}/latestStatus/${sensor}`
  }

  public deviceMateId(deviceId: string): string {
    return `${this.deviceSettings(deviceId)}/linkedMate`
  }

  public mateAlertsConfig(mateId: string): string {
    return `${this.mateSettings(mateId)}/alertsConfig`
  }

  public mateAlertsLastAllClearedDatetime(mateId: string): string {
    return `${this.mateAlertsConfig(mateId)}/lastAllClearedDatetime`
  }

  public satModemSubscribers(): string {
    return '/satModems/subscribers'
  }
}
