import { ModalController, NavController } from '@ionic/angular'

import { NgRedux } from '@angular-redux/store'
import { Component, ViewEncapsulation } from '@angular/core'
import { filter, takeUntil, mapTo } from 'rxjs/operators'
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'

import { ActionTypes } from '../../models/action-types.model'
import { ForgetPasswordModalComponent } from '../../components/forget-password-modal/forget-password-modal.component'
import { State } from '../../models/state.model'

import { AuthProvider } from '../../services/auth/auth.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { AppVersion } from '../../services/environment/environment.service'
import { ENV } from '@app/env'

import { CreateAccountPage } from '../create-account/create-account.page'

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPage {
  createPage: any = CreateAccountPage
  loginForm: FormGroup
  showPassword: boolean = false
  isProd: boolean = ENV.IS_PROD
  appVersion: string = AppVersion

  constructor(
    private auth: AuthProvider,
    private formBuilder: FormBuilder,
    private helpers: HelpersProvider,
    private ngRedux: NgRedux<State>,
    private modalCtrl: ModalController,
    private navCrtl: NavController
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    })
    this.subscribe()
  }

  subscribe(): void {
    this.subscribeToForm()
  }

  subscribeToForm(): void {
    // if the login form is invalid,
    // try to salvage it by stripping white space
    // from the email
    const email: AbstractControl = this.loginForm.controls.email
    this.loginForm.statusChanges
      .pipe(
        takeUntil(
          this.auth.user.pipe(
            filter((user) => user != null),
            filter((user) => user.uid != null),
            mapTo(true)
          )
        )
      )
      .subscribe((state) => {
        if (state === 'INVALID' && email.value) {
          this.loginForm.patchValue(
            {
              email: email.value.trim(),
            },
            { emitEvent: false }
          )
        }
      })
  }

  handleShowPassword(): void {
    this.showPassword = !this.showPassword
  }

  async openForgetPasswordModal(event: Event) {
    // Prevent the link from refreshing the page
    event.preventDefault()

    // const params = {
    //   email: this.loginForm.get('email').value,
    // } TODO: pass in params

    const modal = this.modalCtrl.create({
      component: ForgetPasswordModalComponent,
      // add email here using params
      cssClass: 'forget-password-modal',
    })
    await (await modal).present()
  }

  loginFail(provider?: string): any {
    return (e) => {
      this.helpers.showDangerToast('Failed to login', 5)
    }
  }

  loginSuccess(): any {
    return (user) => {
      this.clearCreds()
      this.helpers.dismissToast()
      // return to layout and re-route
      this.navCrtl.navigateRoot('layout')
    }
  }

  clearCreds(): void {
    this.loginForm.reset()
  }

  demoLogin(): void {
    this.loginTriggered()
    this.auth
      .loginDemoAccount()
      .then(this.loginSuccess())
      .catch(this.loginFail('Email'))
  }

  loginTriggered(): void {
    this.ngRedux.dispatch({
      type: ActionTypes.USER_TRIGGERED_LOGIN,
    })
  }

  loginWithEmail(): void {
    this.loginTriggered()
    this.auth
      .login(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value
      )
      .then(this.loginSuccess())
      .catch(this.loginFail('Email'))
  }

  loginWithFacebook(): void {
    this.auth
      .loginWithFacebook()
      .then(this.loginSuccess())
      .catch(this.loginFail('Facebook'))
  }

  loginWithTwitter(): void {
    this.auth
      .loginWithTwitter()
      .then(this.loginSuccess())
      .catch(this.loginFail('Twitter'))
  }

  loginWithGoogle(): void {
    this.auth
      .loginWithGoogle()
      .then(this.loginSuccess())
      .catch(this.loginFail('Google'))
  }

  loginFailedToast(): void {
    this.helpers.showDangerToast('Login error')
  }

  openCreatePage(): void {
    // this might work
    this.navCrtl.navigateForward('create-account')
  }
}
