import { select } from '@angular-redux/store'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, ViewEncapsulation } from '@angular/core'
import { DigitalOutput } from 'app/models-shared/digital-output.model'
import { DigitalSwitch } from 'app/models-shared/digital-switch.model'
import { SensorConfig } from 'app/models-shared/sensor-config.model'
import { Stat } from 'app/models-shared/stat.model'
import { AppDevice } from 'app/models/app-device.model'
import { HelpersProvider } from 'app/services/helpers/helpers.service'
import { SettingsProvider } from 'app/services/settings/settings.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-controls',
  templateUrl: './controls.page.html',
  styleUrls: ['./controls.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ControlsPage {
  @select('currentDevice') currentDevice$: Observable<AppDevice>
  private userInitiatedChange: boolean = false
  public sensorConfig: SensorConfig
  public controls: Stat<any>[]
  public dragging: boolean = false
  public draggingIndex: number
  public pressTimeout: any

  constructor(
    private settingsProvider: SettingsProvider,
    private helpers: HelpersProvider
  ) {
    this.sensorConfig = this.settingsProvider.deviceSettings$.value.sensorConfig
    this.controls = []
  }

  ngOnInit() {
    this.currentDevice$.subscribe((device) => {
      this.controls = this.getControls(device.stats)
    })
  }

  getControls(stats: Stat<any>[]): any {
    return stats
      ? stats
          .filter((stat) => stat.isDigitalOutput || stat.isDigitalSwitch)
          .sort((a, b) => {
            const indexA =
              this.sensorConfig[a.key]?.controlsIndex ?? Number.MAX_SAFE_INTEGER
            const indexB =
              this.sensorConfig[b.key]?.controlsIndex ?? Number.MAX_SAFE_INTEGER

            if (indexA === indexB) {
              return 0 // Keep original order for items with the same index or no index
            }

            return indexA - indexB
          })
      : []
  }

  digitalSwitches(stats: Stat<any>[]): Stat<any>[] {
    return stats ? stats.filter((stat) => stat.isDigitalSwitch) : []
  }

  getStatKey(index, stat: Stat<any>): string {
    return stat.key
  }

  onToggleClick(): void {
    this.userInitiatedChange = true
  }

  setControlState(value, stat: any): void {
    if (this.userInitiatedChange) {
      this.userInitiatedChange = false
      if (this.settingsProvider.isViewer()) {
        this.helpers.showToast('Not available as a Viewer.')
        return
      }

      const newState = value.detail.checked ? 1 : 0
      stat.toggle = newState
      stat.val = value ? 'On' : 'Off'
      if (stat.isDigitalSwitch) {
        const sensor = this.sensorConfig[stat.key] as DigitalSwitch

        this.settingsProvider.setDigitalSwitchState(
          sensor.id,
          stat.key,
          newState
        )
      } else if (stat.isDigitalOutput) {
        const sensor = this.sensorConfig[stat.key] as DigitalOutput

        this.settingsProvider.setOutputState(sensor.channel, stat.key, newState)
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.dragging = false
    this.draggingIndex = null
    moveItemInArray(this.controls, event.previousIndex, event.currentIndex)
    const deviceSettings = this.settingsProvider.deviceSettings$.value
    this.controls.forEach((control, index) => {
      this.sensorConfig[control.key].controlsIndex = index
    })
    this.settingsProvider.saveDeviceSettings({
      ...deviceSettings,
      sensorConfig: this.sensorConfig,
    })
  }

  onPressStart(index) {
    this.pressTimeout = setTimeout(() => {
      this.dragging = true
      this.draggingIndex = index
    }, 800)
  }

  onPressEnd() {
    clearTimeout(this.pressTimeout)
    this.dragging = false
  }
}
