<div class="setup-device-box">
  <ion-grid>
    <ion-row>
      <ion-col size="3" class="setup-box-icon">
        <ion-icon [name]="iconName"></ion-icon>
      </ion-col>
      <ion-col size="auto" class="setup-box-text">
        <div class="setup-box-header">{{ header }}</div>
        <div class="setup-box-deviceName">{{ deviceName }}</div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
