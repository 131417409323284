<ion-header>
  <ion-toolbar class="toolbar-background">
    <ion-buttons slot="start">
      <ion-back-button
        *ngIf="!isRoot()"
        class="toolbar-button"
      ></ion-back-button>
    </ion-buttons>
    <ion-title class="toolbar-title">Add to fleet</ion-title>
    <ion-buttons slot="primary">
      <ion-button
        *ngIf="isRoot()"
        (click)="logout()"
        class="toolbar-button"
        color="danger"
      >
        Logout
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="add-device_bg">
  <div class="add-device_container" *ngIf="(status$ | async) as status">
    <ion-card class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header">Add BRNKL</ion-card-title>
      </ion-card-header>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <search-device
              [paramDeviceId]="paramDeviceId.trim().toUpperCase()"
              (deviceId$)="onValidDeviceId($event)"
            >
            </search-device>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
    <ion-card
      *ngIf="status === 'SUBSCRIBE' || status === 'PASSWORD_CREATE_SUBSCRIBED'"
      class="ion-card-bg"
    >
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Activate BRNKL</ion-card-title
        >
      </ion-card-header>
      <ion-button class="add-service_button" (click)="showAddServiceModal()">
        Add a BRNKL subscription
      </ion-button>
    </ion-card>
    <ion-card
      *ngIf="status === 'WEBAPP' && status !== 'SUBSCRIBE'"
      class="ion-card-bg"
    >
      <ion-card-header>
        <ion-card-title class="add-device-header">Pay Online</ion-card-title>
      </ion-card-header>
      <div class="add-device_webapp-btn-container">
        <ion-button icon-end (click)="launchWebApp()">
          Open BRNKL web app
        </ion-button>
      </div>
      <p class="add-device_webapp-text">
        Payments are only supported through the BRNKL web app.
      </p>
    </ion-card>
    <ion-card *ngIf="status.startsWith('PASSWORD_CREATE')" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Set vessel name & password</ion-card-title
        >
      </ion-card-header>
      <form [formGroup]="addNewDeviceForm" class="add-device_form">
        <ion-item class="add-device-new">
          <ion-label position="stacked">Vessel Name</ion-label>
          <ion-input
            type="text"
            formControlName="deviceName"
          ></ion-input>
        </ion-item>
        <ion-item class="add-device-new">
          <ion-label position="stacked">Password</ion-label>
          <div class="add-device-show-password">
            <ion-input
              type="{{ showPassword ? 'text' : 'password' }}"
              formControlName="password"
            ></ion-input>
            <ion-button slot="end" fill="clear" (click)="handleShowPassword()" class="show-password-icon" align-self-center>
              <ion-icon
                *ngIf="showPassword==true"
                name="eye-outline"
              ></ion-icon>
              <ion-icon
                *ngIf="showPassword==false"
                name="eye-off-outline"
              ></ion-icon>
            </ion-button>
          </div>
        </ion-item>
        <ion-item class="add-device-new">
          <ion-label position="stacked">Confirm Password</ion-label>
          <ion-input
            type="{{ showPassword ? 'text' : 'password' }}"
            formControlName="confirmPassword"
          ></ion-input>
        </ion-item>
        <optional-text>
          <p>
            <span *ngFor="let error of getAddDeviceError()">
              {{error}}
              <br />
            </span>
          </p>
        </optional-text>
        <div class="add-device_btn-container">
          <ion-button
            [disabled]="disableAddNewButton()"
            (click)="linkDevice(true)"
            icon-end
          >
            Add BRNKL
            <ion-icon name="add"></ion-icon>
          </ion-button>
        </div>
      </form>
    </ion-card>
    <ion-card *ngIf="status === 'PASSWORD_LOGIN'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >BRNKL Password</ion-card-title
        >
      </ion-card-header>
      <form [formGroup]="addExistingDeviceForm" class="add-device_form">
        <ion-item class="add-device-password">
          <div class="add-device-show-password">
          <ion-input
            type="{{ showPassword ? 'text' : 'password' }}"
            formControlName="password"
            placeholder="Enter BRNKL password"
          ></ion-input>
          <ion-button
            slot="end"
            fill="clear"
            (click)="handleShowPassword()"
            class="show-password-icon"
          >
            <ion-icon *ngIf="showPassword==true" name="eye-outline"></ion-icon>
            <ion-icon
              *ngIf="showPassword==false"
              name="eye-off-outline"
            ></ion-icon>
          </ion-button>
        </div>
        </ion-item>
        <div class="add-device_btn-container">
          <ion-button
            [disabled]="disableAddExistingButton()"
            (click)="linkDevice(false)"
            slot="end"
          >
            Add BRNKL
            <ion-icon name="add"></ion-icon>
          </ion-button>
        </div>
      </form>
    </ion-card>
    <div #scrollPoint class="add-device_bottom"></div>
  </div>
</ion-content>
