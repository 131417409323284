<ion-grid>
  <ion-row>
    <ion-col size="12" size-sm="2">
      <ng-content select="[aside]"></ng-content>
    </ion-col>
    <ion-col size="12" size-sm="10" class="card_container">
      <div class="card_header">
        <ng-content select="[header]"></ng-content>
      </div>
      <div class="card_content">
        <ng-content></ng-content>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
