<ion-button
  *ngIf="!lastUpdated || !deviceOffline"
  fill="clear"
  size="small"
  class="armed-button"
  shape="round"
  (click)="setArmed()"
>
  <ion-icon
    *ngIf="isArmed"
    name="custom-armed-status-bar"
    [color]="'armed'"
  ></ion-icon>
  <ion-icon
    *ngIf="!isArmed"
    name="custom-disarmed"
    [ngClass]="'disarmed-faded disarmed-button'"
  ></ion-icon>
  <span class="armed-button-title" [ngClass]="{ 'disarmed-faded': !isArmed }">
    {{ isArmed ? 'Armed' : ' Disarmed' }}
  </span>
</ion-button>
<ion-button
  *ngIf="deviceOffline"
  fill="clear"
  size="small"
  class="armed-button"
  no-padding
  shape="round"
  (click)="offLineNotification()"
>
  <ion-icon class="offline" name="custom-offline-status-bar"></ion-icon>
  <span class="armed-button-title" [ngClass]="'disarmed-faded'"> Offline </span>
</ion-button>
