<div>
  <div [hidden]="!showPayment">
    <card-radio
      [cards]="cards"
      (selectedCardChange)="selectedPlanHandler($event)"
      [selectedCard]="selectedPlan"
      [disabled]="paid || isLoading"
    >
    </card-radio>
    <text-input
      [disabled]="paid || isLoading"
      label="Coupon (optional)"
      type="text"
      [ngModel]="coupon"
      (ngModelChange)="couponHandler($event)"
      class="subscription_coupon-textbox"
    >
    </text-input>
    <h4>Enter Payment Information</h4>
    <card-payment
      #payment
      [email]="email"
      [processingPayment]="isLoading"
      [paymentSuccessful]="paid"
      [invalidError]="error"
      (ready)="resolve($event)"
      (token)="subReqHandler($event)"
      (error)="error = $event"
      (stripeEvent)="stripeHandler($event)"
    >
      <checkout-price
        *ngIf="showPayment"
        [pricingInfo]="pricingInfo$"
      ></checkout-price>
    </card-payment>
  </div>
  <div *ngIf="!showPayment" class="subscription_spinner-container">
    <ion-spinner></ion-spinner>
  </div>
</div>
