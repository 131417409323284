import { Component, Input, ViewEncapsulation } from '@angular/core'
import { WirelessSensorPairingStatus } from '../../models/wireless-sensor-pairing-status.model'

@Component({
  selector: 'setup-wireless-activate',
  templateUrl: './setup-wireless-activate.component.html',
  styleUrls: ['./setup-wireless-activate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupWirelessActivateComponent {
  @Input() imagePath: string
  @Input() pairingStatus: number

  userInstructions(): string {
    if (this.pairingStatus === WirelessSensorPairingStatus.Finalizing) {
      return 'Finishing up, please wait.'
    } else if (this.pairingStatus === WirelessSensorPairingStatus.Done) {
      return 'Pairing Complete.'
    } else {
      return 'Setting up, please wait.'
    }
  }
}
