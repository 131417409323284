import { Injectable } from '@angular/core'
import { PAYMENT_REQUIRED, METHOD_FAILURE } from 'http-status-codes'
import { Observable } from 'rxjs'

import { CloudFunctionsProvider } from '../cloud-functions/cloud-functions.service'

import { SubscriptionRequest } from '../../models-shared/subscription-request.model'
import { SubscriptionState } from '../../models-shared/subscription-state.model'
import { PricingRequest } from '../../models-shared/pricing-req.model'
import { PricingResponse } from '../../models-shared/pricing-res.model'
import { HttpParams } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class SubscriptionProvider {
  constructor(private functions: CloudFunctionsProvider) {}

  private makeSubReq =
    <ResType>(httpCallback: (p: string, body?: any) => Promise<ResType>) =>
    async (deviceId?: string, body?: any): Promise<ResType> => {
      try {
        return await httpCallback(`subscription/${deviceId}`, body)
      } catch (err) {
        err.message = 'Payment error'

        if (err.status === PAYMENT_REQUIRED) {
          err.message = 'Payment declined'
        } else if (err.status === METHOD_FAILURE) {
          err.message =
            'Payment successful but failed to activate BRNKL. Please contact BRNKL support.'
        }
        throw err
      }
    }

  public activate: (
    deviceId: string,
    body: SubscriptionRequest
  ) => Promise<any> = this.makeSubReq((d, b) => this.functions.authedPost(d, b))

  public suspend: (deviceId: string) => Promise<any> = this.makeSubReq((d) =>
    this.functions.authedDel(d)
  )

  public getState = (): Promise<SubscriptionState> =>
    this.functions.authedGetPromise<SubscriptionState>('payments/plans')

  public getPricing = (
    pricingReq: PricingRequest
  ): Observable<PricingResponse> =>
    this.functions.authedGet<PricingResponse>(
      'payments/pricing',
      new HttpParams()
    )

  public validateCoupon = (couponCode: string): Promise<any> =>
    this.functions.authedGetPromise(`payments/coupons/${couponCode}`)

  // public changeSubscription = (
  //   deviceId: string,
  //   currentPlanName: string,
  //   newPlanId: string
  // ): Promise<any> =>
  //   this.functions.authedPost(`subscription/change/${deviceId}`, {
  //     currentPlanName,
  //     newPlanId
  //   })
}
