import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'optional-text',
  templateUrl: './optional-text.component.html',
  styleUrls: ['./optional-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OptionalTextComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
