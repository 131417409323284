import { Component } from '@angular/core'
import { NavParams, ModalController } from '@ionic/angular'

@Component({
  selector: 'filter-alerts-modal',
  templateUrl: './filter-alerts-modal.component.html',
  styleUrls: ['./filter-alerts-modal.component.scss'],
})
export class FilterAlertsModalComponent {
  today: Date = new Date()
  date1: String
  date2: String

  constructor(public params: NavParams, private viewCtrl: ModalController) {
    this.date1 = new Date(this.today.getTime()).toISOString()
    this.date2 = new Date(this.today.getTime()).toISOString()
  }

  dismiss(): void {
    this.viewCtrl.dismiss()
  }
}
