import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { ENV } from '@app/env'
import { UserSettings } from '../../models-shared/user-settings.model'

@Component({
  selector: 'card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardPaymentComponent {
  key = ENV.STRIPE_PUB_KEY // pass this to the stripe component
  @ViewChild('stripeCard') stripeCard
  @Input() userSettings: UserSettings
  @Input() invalidError: stripe.Error

  @Input() email: string = ''
  @Input() processingPayment: boolean = false
  @Input() paymentSuccessful: boolean = false

  @Output() token = new EventEmitter<string>()
  @Output() error = new EventEmitter<stripe.Error>()
  // fired after the <stripe-card> component loads
  @Output() ready = new EventEmitter<boolean>()
  @Output() submit = new EventEmitter<void>()
  @Output() stripeEvent =
    new EventEmitter<stripe.elements.ElementChangeResponse>()

  formEntries: { name: string; label: string }[]
  paymentForm: FormGroup
  cardDirty: boolean = false

  get showPay() {
    const ccValid = !this.invalidError && this.cardDirty
    const formValid = !(this.paymentForm && this.paymentForm.invalid)
    return ccValid && formValid
  }

  get disablePayments() {
    return this.processingPayment || this.paymentSuccessful
  }

  get stripeOptions(): stripe.elements.ElementsOptions {
    return {
      disabled: this.disablePayments,
    }
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.setupForm()
  }

  setupForm() {
    this.paymentForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(1)]],
      address: ['', [Validators.required, Validators.minLength(1)]],
      city: ['', [Validators.required, Validators.minLength(1)]],
      state: ['', [Validators.required, Validators.minLength(1)]],
    })
    const labels: string[] = ['Name', 'Address', 'City', 'Province/State']
    this.formEntries = Object.entries(this.paymentForm.controls).map(
      ([key, form]: [string, { value: string }], i: number) => {
        return {
          name: key,
          label: labels[i],
        }
      }
    )
  }

  errorHandler(err: stripe.Error) {
    this.invalidError = err
    this.error.emit(err)
  }

  submitHandler() {
    this.createToken()
    this.submit.emit()
  }

  stripeChangeHandler(e: stripe.elements.ElementChangeResponse) {
    this.cardDirty = true
    this.stripeEvent.emit(e)
  }

  createToken() {
    // reduce the form controller values
    // into a flat object
    const vals: { [key: string]: string } = Object.entries(
      this.paymentForm.controls
    ).reduce((acc, [key, form]) => {
      return {
        [key]: form.value,
        ...acc,
      }
    }, {})
    const {
      name,
      address: address_line1,
      city: address_city,
      state: address_state,
    } = vals // destruct and name it
    // only create the token if all these strings have length
    const isValid = [address_line1, address_city, address_state].reduce(
      (acc, k) => acc && k && k.length > 0,
      true
    )
    if (isValid) {
      this.stripeCard.createToken({
        name,
        address_line1,
        address_city,
        address_state,
      })
    }
  }

  tokenChangeHandler(sToken: stripe.Token) {
    const { id: token } = sToken
    if (token && token.length) {
      this.token.emit(token)
    }
  }
}
