import { Component, ViewEncapsulation } from '@angular/core'

import { ModalController, NavParams } from '@ionic/angular'
import { Subscription } from 'rxjs'

import {
  BarcodeScannerOptions,
  BarcodeScanner,
  BarcodeScanResult,
} from '@ionic-native/barcode-scanner/ngx'
import { Insomnia } from '@ionic-native/insomnia/ngx'

import { SetupStep } from '../../models/setup-step.model'

import { BaseSetupModalComponent } from '../../components/base-setup-modal/base-setup-modal.component'
import { SettingsAddDeviceModal } from '../../components/settings-add-device-modal/settings-add-device-modal.component'
import { AddServiceModal } from '../../components/add-service-modal/add-service-modal.component'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'
import { DeviceProvider } from '../../services/device/device.service'

import { delay } from '../../util'
import { SatelliteSubscribers } from '../../models/satellite-subscribers.model'
import { SettingsProvider } from '../../services/settings/settings.service'
import { BackendDeviceSettings } from '../../models-shared/backend-device-settings.model'

const scannerOptions: BarcodeScannerOptions = {
  preferFrontCamera: false,
  formats: 'DATA_MATRIX,QR_CODE',
  resultDisplayDuration: 0,
}

@Component({
  selector: 'link-satellite-modal',
  templateUrl: './link-satellite-modal.component.html',
  styleUrls: ['./link-satellite-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LinkSatelliteModal extends BaseSetupModalComponent {
  connect: SetupStep = {
    name: 'connect',
    title: 'Add Device',
    iconName: 'custom-add-device',
    backButton: true,
    exitButton: true,
  }

  scanSat: SetupStep = {
    name: 'scanSat',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: true,
    exitButton: true,
  }

  enterCode: SetupStep = {
    name: 'enterCode',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: true,
    exitButton: true,
  }

  find: SetupStep = {
    name: 'find',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }

  addService: SetupStep = {
    name: 'addService',
    title: 'Add service',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }
  // currently removed the test step because it forks the user experience when adding a satellite device with a mate vs without a mate

  // testSat: SetupStep = {
  //   name: 'testSat',
  //   title: 'Add device',
  //   iconName: 'custom-add-device',
  //   backButton: false,
  //   exitButton: false
  // }

  // testSuccess: SetupStep = {
  //   name: 'testSuccess',
  //   title: 'Add device',
  //   iconName: 'custom-add-device',
  //   backButton: false,
  //   exitButton: false
  // }

  // testFail: SetupStep = {
  //   name: 'testFail',
  //   title: 'Add device',
  //   iconName: 'custom-add-device',
  //   backButton: false,
  //   exitButton: false
  // }

  addDevice: SetupStep = {
    name: 'addDevice',
    title: 'Add device',
    iconName: 'custom-add-device',
    backButton: false,
    exitButton: false,
  }

  satelliteInfo = {
    name: 'RockBLOCK+',
    companyName: 'Rock Seven',
    iconName: 'custom-satellite',
  }

  scanningBarcode: boolean = false
  progressStep: string = 'Finding'
  progressPercent: number = 20
  progressDoneButton: string = ''
  progressHeader: string = 'Adding'
  progressDescription: string = 'Adding Satellite.'
  progressIconName: string = 'custom-add-device'
  satModemId: string
  satelliteSubscribers: SatelliteSubscribers
  inputValue: string
  isSatelliteSubscribed: boolean

  private refreshSettings: () => void
  private subscriptions: Subscription[] = []

  constructor(
    private barcodeScanner: BarcodeScanner,
    private helpers: HelpersProvider,
    private bluetoothProvider: BluetoothLEProvider,
    public viewCtrl: ModalController,
    private navParams: NavParams,
    private device: DeviceProvider,
    private settings: SettingsProvider,
    private insomnia: Insomnia
  ) {
    super(viewCtrl)
    this.configure()
    this.refreshSettings = this.navParams.get('refreshSettings')
  }

  subscribeToDisconnection() {
    this.subscriptions.push(
      this.bluetoothProvider.connected.subscribe((isConnected: boolean) => {
        if (!isConnected) {
          // Mate disconnected
          this.exit()
        }
      })
    )
  }

  ngOnInit() {
    this.subscribe()
    this.insomnia.keepAwake()
  }

  subscribe() {
    this.subscriptions.push(
      this.device.getSatModemSubscribers().subscribe((subscribers) => {
        this.satelliteSubscribers = subscribers
      })
    )
    this.subscriptions.push(
      this.settings
        .getBackendSettings()
        .subscribe((settings: BackendDeviceSettings) => {
          this.isSatelliteSubscribed = settings.satelliteSubscribed
        })
    )
  }

  ngOnDestroy() {
    this.insomnia.allowSleepAgain()
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  onExitPressed(): void {
    this.exit()
  }

  async onBackPressed(): Promise<void> {
    if (this.currentStep.name === 'connect') {
      await this.openAddDeviceModal(false)
    } else {
      this.back()
    }
  }

  async scanDataMatrix(): Promise<BarcodeScanResult> {
    if (this.scanningBarcode) {
      return
    }
    this.scanningBarcode = true

    try {
      const result = await this.barcodeScanner.scan(scannerOptions)
      this.scanningBarcode = false
      return result
    } catch (err) {
      this.scanningBarcode = false
      throw err
    }
  }

  handleEnterCode(input: string) {
    this.inputValue = input
  }

  onProgressCancel = async () => {
    if (this.currentStep.name === 'addDevice') {
      this.onExitPressed()
    } else if (this.progressDoneButton === 'Next') {
      if (!this.isSatelliteSubscribed) {
        this.pushStep(this.addService)
      } else {
        this.pushStep(this.addDevice)
      }
    } else {
      this.onExitPressed()
    }
  }

  setProgressStepDetails = (
    percent?: number,
    step?: string,
    iconName?: string,
    header?: string,
    description?: string,
    doneButtonText?: string
  ) => {
    this.progressPercent = percent
    this.progressStep = step
    this.progressIconName = iconName
    this.progressHeader = header
    this.progressDescription = description
    this.progressDoneButton = doneButtonText
  }

  onScanSatellite = async () => {
    let result: BarcodeScanResult
    try {
      result = await this.scanDataMatrix()
    } catch (e) {
      throw new Error(
        'BRNKL needs to use the camera on this device to scan. In the device Settings, allow BRNKL access to the camera.'
      )
    }

    if (result.cancelled) {
      return ''
    }

    return result.text
  }

  openAddDeviceModal = async (disconnectMate: boolean) => {
    if (disconnectMate && this.bluetoothProvider.connected.value) {
      try {
        await this.bluetoothProvider.disconnectFromMate(false)
      } catch {}
    }

    this.helpers.showModal(
      SettingsAddDeviceModal,
      {
        refreshSettings: this.refreshSettings,
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }

  openAddServiceModal = async () => {
    this.helpers.showModal(
      AddServiceModal,
      {
        refreshSettings: this.refreshSettings,
        serviceType: 'satellite',
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }

  // testConnectionToSatModem = async () => {
  //   const mateId = this.device.currentBRNKLandMateId$.value.mateId
  //   let response

  //   // test satellite device connection through mate
  //   this.helpers.startLoading('Testing satellite connection')
  //   if (!this.bluetoothProvider.connected.value) {
  //     await this.bluetoothProvider.connectToMate(mateId).then(async () => {
  //       response = await this.bluetoothProvider.testMateSatelliteConnection()
  //     })
  //   } else {
  //     response = await this.bluetoothProvider.testMateSatelliteConnection()
  //   }
  //   this.helpers.stopLoading()
  //   if (response === '0') {
  //     this.pushStep(this.testSuccess)
  //   } else {
  //     this.pushStep(this.testFail)
  //   }
  // }

  private findSatellite = async (satelliteId: string) => {
    this.setProgressStepDetails(
      20,
      'Finding',
      'custom-add-device',
      'Adding',
      `It may take a few minutes to add the device.`
    )

    if (this.satelliteSubscribers[satelliteId]) {
      return this.satelliteSubscribers[satelliteId]
    } else {
      throw new Error('Satellite device is not registered with BRNKL')
    }
  }

  private linkSatellite = async (
    imei: string,
    deviceId: string,
    mateId: string
  ) => {
    // if mate is not linked, select sat as the serial device for the BRNKL
    if (!mateId) {
      this.device.setSerialDevice(deviceId, 'sat')
    }

    await delay(5000)

    this.setProgressStepDetails(
      40,
      'Linking',
      'custom-add-device',
      'Adding',
      `It may take a few minutes to add the device.`
    )

    await delay(4000)

    this.setProgressStepDetails(
      60,
      'Linking',
      'custom-add-device',
      'Adding',
      `It may take a few minutes to add the device.`
    )

    await delay(5000)

    this.setProgressStepDetails(
      80,
      'Adding',
      'custom-add-device',
      'Adding',
      `It may take a few minutes to add the device.`
    )

    try {
      const linkSatellite = await this.device.linkSatModemToBrnklDB(
        imei,
        deviceId
      )
      if (!linkSatellite) {
        throw new Error('Error adding the satellite device.')
      }
      this.setProgressStepDetails(
        undefined,
        undefined,
        'md-checkmark',
        'Added',
        `Satellite device added to BRNKL.`,
        'Next'
      )
      await delay(1000)
      this.refreshSettings()
      await delay(2000)
    } catch (err) {
      this.helpers.showInfiniteDangerToast(
        `An error when adding the satellite device occured. Please try again.`
      )
      await this.openAddDeviceModal(true)
    }
  }

  private configure() {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    const mateId = this.device.currentBRNKLandMateId$.value.mateId
    let satelliteId: string
    let imei: string

    //Define Transitions
    this.connect.next = () => {
      this.pushStep(this.scanSat)
    }
    this.scanSat.option = () => {
      this.pushStep(this.enterCode)
    }

    this.addService.next = () => {
      this.pushStep(this.addDevice)
    }

    // this.testSat.next = () => {
    //   this.pushStep(this.addDevice)
    // }

    // this.testSuccess.next = () => {
    //   this.pushStep(this.addDevice)
    // }

    // this.testFail.next = () => {
    //   this.pushStep(this.addDevice)
    // }

    this.enterCode.next = async () => {
      //check if input is empty or not a number
      if (+this.inputValue === 0 || isNaN(+this.inputValue)) {
        this.helpers.showInfiniteToast(
          `Invalid registration code. Please try again.`
        )
        return
      } else {
        this.pushStep(this.find)
      }

      // look up registration ID in db to get IMEI
      try {
        imei = await this.findSatellite(this.inputValue)
      } catch (err) {
        this.helpers.showInfiniteToast(
          `Device not found. Please contact BRNKL support at ahoy@brnkl.io or 1-877-552-7655 to register your satellite device.`
        )
        await this.openAddDeviceModal(false)
        return
      }

      // start satellite linking process
      try {
        this.linkSatellite(imei, deviceId, mateId)
      } catch (err) {
        this.helpers.showInfiniteDangerToast(
          `An error occured when adding the satellite device: ${err.message}`
        )
        await this.openAddDeviceModal(true)
      }
    }

    this.scanSat.next = async () => {
      let imei: string
      try {
        satelliteId = await this.onScanSatellite()
        if (satelliteId == '') {
          // User cancelled
          return
        } else {
          this.pushStep(this.find)
        }
      } catch (err) {
        this.helpers.showInfiniteDangerToast(
          `An error occured when scanning: ${err.message}`
        )
        // reset process
        await this.openAddDeviceModal(false)
        return
      }
      // look up registration ID in db to get IMEI
      try {
        imei = await this.findSatellite(satelliteId)
      } catch (err) {
        this.helpers.showInfiniteToast(
          `Device not found. Please contact BRNKL support at ahoy@brnkl.io or 1-877-552-7655 to register your satellite device.`
        )
        await this.openAddDeviceModal(false)
        return
      }

      // start satellite linking process
      try {
        this.linkSatellite(
          imei,

          deviceId,

          mateId
        )
      } catch (err) {
        this.helpers.showInfiniteDangerToast(
          `An error occured when adding the satellite device: ${err.message}`
        )
        await this.openAddDeviceModal(true)
      }
    }

    //Set first step
    this.pushStep(this.connect)
  }
}
