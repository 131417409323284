<ion-header>
  <ion-toolbar>
    <ion-title>Filter Alerts</ion-title>
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding small>
  <!-- TODO: incomplete alert filtering feature-->
  <!-- <ion-list>
    <ion-item>
      <ion-label>Date 1</ion-label>
      <ion-datetime
        displayFormat="MMM DD, YYYY HH:mm"
        [(ngModel)]="Start"
      ></ion-datetime>
    </ion-item>

    <ion-item>
      <ion-label>Date 2</ion-label>
      <ion-datetime
        displayFormat="MMM DD, YYYY HH:mm"
        [(ngModel)]="End"
      ></ion-datetime>
    </ion-item>
  </ion-list> -->
</ion-content>
