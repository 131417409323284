import { AppDevice } from './app-device.model'
import { UserSettings } from '../models-shared/user-settings.model'

import firebase from 'firebase/compat/app'

/**
 * Interface for main Redux state tree
 *
 * allDevices property will be used
 * when multiple devices can be viewed
 * at once (i.e fleet mode)
 */
export interface State {
  hasUserTriggeredLogin: boolean
  currentDevice?: AppDevice
  allDevices?: AppDevice[]
  currentUser?: firebase.User
  currentUserSettings?: UserSettings
}

export const DEFAULT_STATE: State = {
  hasUserTriggeredLogin: false,
  currentDevice: {
    settings: {
      cameraConfig: {
        cam1: {
          hourSchedule: {},
        },
      },
    },
  },
}
