import { Injectable } from '@angular/core'
import {
  AlertController,
  AlertButton,
  AlertInput,
  LoadingController,
  ToastController,
  ModalController,
  PopoverController,
} from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class HelpersProvider {
  private toastDuration: number = 5
  private loading: any
  private toast: any
  private alert: any
  private modal: any
  private popover: any
  constructor(
    private loadCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController
  ) {}

  public showPopover(
    component: any,
    data?: {},
    event?: Event,
    cssClass?: string,
    showBackdrop?: boolean,
    enableBackdropDismiss?: boolean
  ) {
    this.popover = this.createPopover(
      component,
      data,
      event,
      cssClass,
      showBackdrop,
      enableBackdropDismiss
    )
  }

  private async createPopover(
    component: any,
    data?: {},
    event?: Event,
    cssClass?: string,
    showBackdrop?: boolean,
    enableBackdropDismiss?: boolean
  ) {
    const popover = await this.popoverCtrl.create({
      event: event,
      component: component,
      cssClass: cssClass,
      showBackdrop: showBackdrop,
      backdropDismiss: enableBackdropDismiss,
      componentProps: data,
      mode: "md",
    })
    return await popover.present()
  }

  public dismissPopover(): void {
    try {
      if (this.popover) this.popover.dismiss()
    } catch (err) {}
  }

  public showModal(
    component: any,
    data?: any,
    showBackdrop?: boolean,
    enableBackdropDismiss?: boolean,
    cssClass: string = 'modal__primary'
  ) {
    this.modal = this.createModal(
      component,
      data,
      showBackdrop,
      enableBackdropDismiss,
      cssClass
    )
  }

  private createModal(
    component: any,
    data?: any,
    showBackdrop?: boolean,
    enableBackdropDismiss?: boolean,
    cssClass?: string
  ) {
    const modal = this.modalCtrl
      .create({
        component: component,
        componentProps: data,
        showBackdrop: showBackdrop,
        backdropDismiss: enableBackdropDismiss,
        cssClass: cssClass,
      })
      .then((mod) => mod.present())
    return modal
  }

  public dismissModal(): void {
    try {
      if (this.modal) this.modal.dismiss()
    } catch (err) {}
  }

  // To show an Input Prompt box taking input from the user
  public showInputPrompt(
    title: string,
    subTitle?: string,
    inputs?: AlertInput[],
    message?: string,
    cssClass?: string,
    buttons?: (AlertButton | string)[],
    handlerFunction?: any,
    enableBackdropDismiss?: boolean
  ): any {
    const alertButtons: (AlertButton | string)[] = [
      {
        text: 'Cancel',
        role: 'cancel',
      },
      {
        text: 'Save',
        handler: (data) => {
          handlerFunction(data)
        },
      },
    ]
    const alertInput = this.alertCtrl
      .create({
        header: title,
        subHeader: subTitle,
        inputs: inputs,
        message: message,
        cssClass: cssClass,
        buttons: alertButtons,
        backdropDismiss: false,
      })
      .then((alert) => alert.present())

    return alertInput
  }

  public showSimpleAlert(
    title: string,
    subTitle?: string,
    message?: string,
    cssClass: string = 'alert__primary',
    handlerFunction?: any,
    enableBackdropDismiss?: boolean
  ) {
    const alertButtons: (AlertButton | string)[] = [
      {
        text: 'Confirm',
        handler: () => {
          handlerFunction()
        },
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]
    this.alert = this.createSimpleAlert(
      title,
      subTitle,
      message,
      cssClass,
      alertButtons,
      enableBackdropDismiss
    )
  }

  public createSimpleAlert(
    title: string,
    subTitle: string,
    message?: string,
    cssClass?: string,
    buttons?: (AlertButton | string)[],
    enableBackdropDismiss?: boolean
  ): any {
    const alert = this.alertCtrl
      .create({
        header: title,
        subHeader: subTitle,
        message: message,
        cssClass: cssClass,
        buttons: buttons,
        backdropDismiss: enableBackdropDismiss,
      })
      .then((alert) => alert.present())
    return alert
  }

  public dismissAlert(): void {
    try {
      if (this.alert) this.alert.dismiss()
    } catch (err) {}
  }

  public showToast(
    msg: string,
    duration?: number,
    cssClass: string = 'toast__primary',
    position?: 'middle' | 'top' | 'bottom'
  ) {
    this.toast = this.createToast(
      msg,
      duration || this.toastDuration,
      cssClass,
      position
    )
  }

  public showInfiniteToast(
    msg: string,
    cssClass: string = 'toast__primary',
    position?: 'middle' | 'top' | 'bottom'
  ) {
    this.toast = this.createToast(msg, undefined, cssClass, position)
  }

  public showDangerToast(msg: string, duration?: number) {
    this.showToast(msg, duration, 'toast__danger')
  }

  public showInfiniteDangerToast(msg: string) {
    this.showInfiniteToast(msg, 'toast__danger')
  }

  private async createToast(
    msg: string,
    dur?: number,
    cssClass?: string,
    position: 'middle' | 'top' | 'bottom' = 'middle'
  ): Promise<any> {
    const toast = await this.toastCtrl.create({
      message: msg,
      position: position,
      cssClass: cssClass,
      duration: dur ? dur * 1000 : undefined,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    })

    return await toast.present()
  }

  public dismissToast(): void {
    try {
      if (this.toast) this.toast.dismiss()
    } catch (err) {}
  }

  public async stopLoading(): Promise<any> {
    try {
      if (this.loading) await this.loading.dismiss()
    } catch (err) {}
    return Promise.resolve()
  }

  public async startLoading(msg: string): Promise<void> {
    this.dismissToast()
    this.loading = await this.loadCtrl.create({
      message: msg,
    })

    await this.loading.present()
  }
}
