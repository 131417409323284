<ion-content class="add-wireless-sensor-content" [ngSwitch]="currentStep.name">
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <setup-info
      *ngSwitchCase="'connect'"
      (next)="currentStep.next()"
      header="Install and connect"
      description="Install the device and connect all wiring before the next step."
      nextButtonText="Next"
      [device]="satelliteInfo"
      topIcon="custom-wired-connection"
    ></setup-info>
    <setup-info
      *ngSwitchCase="'scanSat'"
      (next)="currentStep.next()"
      (option)="currentStep.option()"
      header="Scan code"
      description="Scan the QR code printed on the Quickstart guide provided with the device."
      nextButtonText="Scan"
      optionButtonText="Enter code"
      [device]="satelliteInfo"
      topIcon="custom-scan-code"
    ></setup-info>
    <setup-info
      *ngSwitchCase="'enterCode'"
      (next)="currentStep.next()"
      (option)="onBackPressed()"
      (inputValue)="handleEnterCode($event)"
      header="Enter code"
      description="Enter the registration code on the Quickstart guide provided with the device."
      nextButtonText="Enter"
      optionButtonText="Scan code"
      [device]="satelliteInfo"
      topIcon="custom-scan-code"
    ></setup-info>
    <setup-progress
      *ngSwitchCase="'find'"
      (next)="currentStep.next()"
      [description]="progressDescription"
      [progressStep]="progressStep"
      [progressPercent]="progressPercent"
      [doneButtonText]="progressDoneButton"
      (cancel)="onProgressCancel()"
      [device]="satelliteInfo"
      [progressIconName]="progressIconName"
      [header]="progressHeader"
    ></setup-progress>
    <setup-info
      *ngSwitchCase="'addService'"
      (next)="openAddServiceModal()"
      (option)="currentStep.next()"
      header="Add service"
      description="Add a satellite plan?"
      nextButtonText="Add"
      optionButtonText="Later"
      [device]="satelliteInfo"
      topIcon="custom-satellite-service"
    ></setup-info>
    <!-- <setup-info
      *ngSwitchCase="'testSat'"
      (next)="testConnectionToSatModem()"
      (option)="currentStep.next()"
      header="Test"
      description="Test satellite cable connection?"
      nextButtonText="Test"
      optionButtonText="Later"
      [device]="satelliteInfo"
      topIcon="custom-satellite-service"
    ></setup-info>
    <setup-info
      *ngSwitchCase="'testSuccess'"
      (next)="currentStep.next()"
      header="Satellite connected"
      description="Satellite device is successfully connected."
      nextButtonText="Next"
      [device]="satelliteInfo"
      topIcon="checkmark"
    ></setup-info>
    <setup-info
      *ngSwitchCase="'testFail'"
      (next)="testConnectionToSatModem()"
      (option)="currentStep.next()"
      header="Connection error"
      description="Satellite device is not connected properly. Check wiring and retest."
      nextButtonText="Retest"
      optionButtonText="Later"
      [device]="satelliteInfo"
      topIcon="alert-circle"
    ></setup-info> -->
    <setup-info
      *ngSwitchCase="'addDevice'"
      (next)="openAddDeviceModal()"
      (option)="onProgressCancel()"
      header="Next"
      description="Add another device?"
      nextButtonText="Add"
      optionButtonText="Done"
      [device]="satelliteInfo"
      topIcon="custom-add-device"
    ></setup-info>
  </setup-container>
</ion-content>
