<ion-content *ngIf="isNativeApp">
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <setup-info
      (next)="launchWebApp()"
      header="Open"
      description="Open BRNKL in the browser to continue."
      nextButtonText="Open"
    ></setup-info>
  </setup-container>
</ion-content>
<ion-content
  *ngIf="!isNativeApp"
  class="add-service-content"
  [ngSwitch]="currentStep.name"
>
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <div class="setup-add-service_container" *ngSwitchCase="'plans'">
      <div class="setup-add-service_header">Choose</div>
      <div class="setup-add-service_tagline">Choose a plan.</div>
      <div
        class="setup-add-service_padbox"
        *ngFor="let plan of planList"
        (click)="serviceSelected(plan)"
      >
        <setup-service-box
          [ngClass]="{ 'setup-add-service-box_disabled': plan.disabled }"
          [iconName]="plan.iconName"
          [header]="plan.companyName"
          [title]="plan.title"
          [currency]="plan.currency"
          [amount]="plan.amount"
          [interval]="plan.interval"
        ></setup-service-box>
      </div>
    </div>
    <setup-payment
      *ngSwitchCase="'planSelected'"
      (next)="currentStep.next()"
      (couponCode)="handleEnterCoupon($event)"
      [plan]="plan"
      header="Payment"
      nextButtonText="Pay"
      [serviceType]="serviceType"
    ></setup-payment>
  </setup-container>
</ion-content>
