import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'orderGraphs',
})
export class OrderGraphsPipe implements PipeTransform {
  transform(graphs: any[]): any[] {
    if (!graphs) return []

    // Place graphs with data at the top
    return graphs.sort((a, b) => {
      const aHasDataInRange = a.data && a.datetimes && !a.noData
      const bHasDataInRange = b.data && b.datetimes && !b.noData
      return (bHasDataInRange ? 1 : 0) - (aHasDataInRange ? 1 : 0)
    })
  }
}
