import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
import { NgRedux, select } from '@angular-redux/store'
import { Observable, Subscription } from 'rxjs'
import { NavParams, ModalController } from '@ionic/angular'
import { DeviceProvider } from '../../services/device/device.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { AppDevice } from '../../models/app-device.model'
import { AuthProvider } from '../../services/auth/auth.service'

import firebase from 'firebase/compat/app'
import { ActivatedRoute } from '@angular/router'
import { NavDataService } from 'app/services/navigation/navigation.service'
import { State } from 'app/models/state.model'
import { ShareTokenStatus } from 'app/models-shared/share-token-status'

export const MIN_PASSWORD_LENGTH: number = 8
export const SEARCH_DEBOUNCE_SEC: number = 1

@Component({
  selector: 'app-share-device',
  templateUrl: './share-device.page.html',
  styleUrls: ['./share-device.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShareDevicePage implements OnInit {
  deviceId: string = ''
  deviceName: string = ''
  ownerName: string = ''
  currentUser: firebase.User = null
  paramDeviceId: string = ''
  paramShareToken: string = ''
  status: ShareTokenStatus = 0
  subscriptions: Subscription[] = []
  refreshSettings: () => void

  @ViewChild('scrollPoint') scrollPoint: ElementRef

  @select(['currentUser'])
  currentUser$: Observable<firebase.User>

  @select('allDevices')
  allDevices$: Observable<AppDevice[]>

  constructor(
    public params: NavParams,
    private device: DeviceProvider,
    private helpers: HelpersProvider,
    private auth: AuthProvider,
   
    public viewCtrl: ModalController,
    private route: ActivatedRoute,
    private navData: NavDataService,
    private ngRedux: NgRedux<State>
  ) {

  }

  ngOnDestroy() {
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  async ngOnInit() {
    this.subscriptions.push(
      this.currentUser$.subscribe((user: firebase.User) => {
        this.currentUser = user
      })
    )

    this.subscriptions.push(
      this.allDevices$.subscribe((devices: AppDevice[]) => {  
        devices.map((device) => {
          console.log("Device ID: ", device)
          if (device.deviceId === this.paramDeviceId) {
            this.deviceName = device.settings.deviceName
            console.log("Device Name: ", this.deviceName)
          }
        })
      })
    )

    // make sure we only compute this once
    this.refreshSettings = this.params.get('refreshSettings')

    let deviceId = this.route.snapshot.paramMap.get('deviceId')
      ? this.route.snapshot.paramMap.get('deviceId')
      : ''
    this.paramShareToken = this.navData.get('token')
    this.status = await this.device.validateShareToken(this.paramShareToken, this.currentUser.email)
    console.log("Status: ", this.status)
    if (deviceId) {
      this.paramDeviceId = deviceId.trim().toUpperCase()
    } else {
      let navData = this.navData.get('deviceId')
      if (navData)
        this.paramDeviceId = this.navData.get('deviceId').trim().toUpperCase()
    }
  }

  async linkDevice() {
    this.helpers.startLoading('Adding Vessel...')
    let didWork = false
    try {
     await this.device.linkSharedUserToDevice(this.deviceId)
      didWork = true
    } catch (err) {}
    await this.helpers.stopLoading()

    if (didWork) {
      this.pushTabs(this.deviceId)
    } else {
      this.showFailToast()
    }
  }

  pushTabs(deviceId: string): void {
    this.device.setCurrentDeviceId(deviceId)
  }

  showFailToast(): void {
    this.helpers.showDangerToast('Failed to add device')
  }

  logout(): void {
    this.auth.logout()
  }

  isRoot(): boolean {
    // TODO: find better way too check if root
    //return !this.navCtrl.canGoBack()
    //displays logout button
    return this.ngRedux.getState().allDevices.length < 1
  }
}
