import { select } from '@angular-redux/store'
import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ModalController, NavParams } from '@ionic/angular'
import { AppDevice } from 'app/models/app-device.model'
import { SetupStep } from 'app/models/setup-step.model'
import { AuthProvider } from 'app/services/auth/auth.service'
import { CloudFunctionsProvider } from 'app/services/cloud-functions/cloud-functions.service'
import { HelpersProvider } from 'app/services/helpers/helpers.service'
import { Observable } from 'rxjs'
import { BaseSetupModalComponent } from '../base-setup-modal/base-setup-modal.component'
import { SetPasswordComponent } from '../set-password/set-password.component'

@Component({
  selector: 'delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteAccountModalComponent extends BaseSetupModalComponent {
  @Output() saveClick: EventEmitter<string> = new EventEmitter()
  @ViewChild(SetPasswordComponent) setPassword: SetPasswordComponent
  @select('allDevices') allDevices$: Observable<AppDevice[]>

  passwordForm: FormGroup

  hasSubscription: any
  hasSatelliteSubscription: any
  subscribedDevices: []
  validateInput: string
  validate: boolean
  validatePattern: string = ''
  inputForm: FormGroup
  userPassword: string

  validateChecked: boolean

  loadingPage: SetupStep = {
    name: 'loading',
    title: 'Checking for subscriptions...',
    iconName: '',
    backButton: false,
    exitButton: true,
  }

  deletePage: SetupStep = {
    name: 'delete',
    title: '',
    iconName: '',
    backButton: true,
    exitButton: false,
  }

  subscriptionPage: SetupStep = {
    name: 'subscription',
    title: 'You have one or more BRNKL subscriptions:',
    iconName: '',
    backButton: false,
    exitButton: true,
  }

  noSubscriptionPage: SetupStep = {
    name: 'delete-no-subscription',
    title: '',
    iconName: '',
    backButton: false,
    exitButton: true,
  }

  inputPage: SetupStep = {
    name: 'input',
    title: 'Enter name of device',
    iconName: '',
    backButton: true,
    exitButton: false,
  }

  authPage: SetupStep = {
    name: 'auth',
    title: '',
    iconName: '',
    backButton: true,
    exitButton: false,
  }

  finishPage: SetupStep = {
    name: 'finish',
    title: '',
    iconName: '',
    backButton: false,
    exitButton: false,
  }

  constructor(
    private navParams: NavParams,
    private viewCtrl: ModalController,
    private formBuilder: FormBuilder,
    private helpers: HelpersProvider,
    private auth: AuthProvider,
    private cloudFunctions: CloudFunctionsProvider
  ) {
    super(viewCtrl)
    this.pushStep(this.loadingPage)
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
    })
    // check if user has subscription
    const uid = this.navParams.get('uid')
    const userEmail = this.navParams.get('userEmail')
    this.userHasSubscription(uid, userEmail).then(() => {
      // match with any device user has subscription on
      if (this.hasSubscription) {
        // this is code for text validation of device
        // we dont not have this implemented but leaving this here incase we ever do
        // for (let i = 0; i < this.hasSubscription.length; i++) {
        //   if (i != 0) this.validatePattern += '|'
        //   this.validatePattern += `${this.hasSubscription[i]}`
        // }
        this.pushStep(this.subscriptionPage)
      } else {
        this.pushStep(this.noSubscriptionPage)
      }
    })
    this.userHasSatelliteSubscription(uid)

    // form for input
    this.inputForm = this.formBuilder.group({
      input: ['', [Validators.required]],
    })
  }

  async delete(): Promise<void> {
    // this call will delete the users account
    // if has subscription it will unsubscribe
    // if has satellite subscription it will unsubscribe too
    // remove this call when testing
    this.pushStep(this.finishPage)
    await this.deleteAccount()
    setTimeout(() => {
      this.dismiss()
    }, 5000)
  }

  dismiss(): Promise<any> {
    return this.viewCtrl.dismiss()
  }

  save(): void {
    this.helpers.startLoading('Saving password...')
  }

  onExitPressed(): void {
    this.exit()
  }

  onBackPressed(): void {
    this.back()
  }

  async userHasSubscription(uid, userEmail): Promise<void> {
    await this.cloudFunctions
      .authedGetPromise<any>(`userHasSubscription/${uid}/${userEmail}`)
      .then((res) => {
        // no subscription
        if (res['success'] === false) this.hasSubscription = null
        else {
          this.hasSubscription = res
        }
      })
  }

  async userHasSatelliteSubscription(uid): Promise<void> {
    await this.cloudFunctions
      .authedGetPromise<any>(`userHasSatelliteSubscription/${uid}`)
      .then((res) => {
        // no subscription
        if (res['success'] === false) this.hasSatelliteSubscription = null
        else {
          this.hasSatelliteSubscription = res
        }
      })
      .catch((err) => console.log(err))
  }

  async deleteAccount(): Promise<void> {
    const uid = this.navParams.get('uid')

    // Delete customer from stripe which will cancel all subscriptions
    if (this.hasSubscription) {
      try {
        console.log("MY DEVICE ID'S TO REMOVE: \n", this.hasSubscription)
        for (let deviceId of this.hasSubscription) {
          await this.cloudFunctions
            .authedDel<any>(`subscription/${deviceId}`)
            .catch((err) => console.log(err))
        }
      } catch (err) {
        console.log(err)
      }
    }
    // remove user from all devices and delete all user data
    await this.cloudFunctions
      .authedPost<any>(`deleteUserAccount/${uid}`)
      .catch((err) => console.log(err))
    await this.auth.deleteCurrentUser()
    this.auth.logout()
  }

  async reauthAndDelete(): Promise<void> {
    await this.auth
      .reauthWithPassword(this.passwordForm.value.password)
      .then(() => {
        this.delete()
      })
      .catch((err) => {
        this.helpers.showDangerToast('Incorrect Password. Please try again.', 5)
      })
  }
}
