<setup-device-box
  [iconName]="device.iconName"
  [header]="device.companyName"
  [deviceName]="device.name"
></setup-device-box>
<div class="setup-container_header setup-wireless-init_top-space">
  <ion-icon
    *ngIf="sensorType === 17"
    class="setup-wireless-init_magnet-switch"
    name="custom-magnet-switch"
  ></ion-icon>
  {{ header }}
</div>
<div class="setup-container_text setup-container_bottom-space">
  {{ instructions }}
</div>
<ion-icon
  *ngIf="sensorType !== 17"
  class="setup-wireless-init_icon"
  name="custom-sensor-remove-tag"
></ion-icon>
<div class="setup-container_btn-align setup-wireless-init_bottom-space">
  <button class="setup-container_btn-action" (click)="next.emit()">
    {{ nextButtonText }}
  </button>
</div>
<div class="text-container">
  <div class="text-bold">Tab already removed?</div>
  <div>
    Locate the reset button on the sensor. Using a paper clip or similar tool
    hold down the button for five seconds until lights flash on the sensor.
  </div>
</div>
