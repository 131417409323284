import { Component, ViewEncapsulation } from '@angular/core'
import { NavParams, ModalController, AlertController } from '@ionic/angular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthProvider } from '../../services/auth/auth.service'

@Component({
  selector: 'auth-modal',
  templateUrl: './reauth-modal.component.html',
  styleUrls: ['./reauth-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReauthModalComponent {
  pwForm: FormGroup
  showSpinner: boolean = false
  disableButton = false
  statusMessage = ''

  constructor(
    public params: NavParams,
    private viewCtrl: ModalController,
    private alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    private auth: AuthProvider
  ) {
    this.buildForm()
  }

  buildForm() {
    this.pwForm = this.formBuilder.group({
      pw: ['', [Validators.required]],
    })
  }

  async reauth() {
    if (this.pwForm.valid) {
      const pw = this.pwForm.controls.pw.value

      this.showSpinner = true
      this.disableButton = true
      try {
        await this.auth.reauthWithPassword(pw)
        this.viewCtrl.dismiss(true)
      } catch (err) {
        console.log(err)
        this.disableButton = false
        this.statusMessage = `Incorrect password.`
      }

      this.showSpinner = false
    }
  }

  dismiss(): void {
    this.viewCtrl.dismiss()
  }

  async forgetPassword(event: Event) {
    event.preventDefault()

    this.alertCtrl
      .create({
        header: 'Reset password?',
        message: `Send reset password email to: ${this.params.get(
          'userEmail'
        )}`,
        cssClass: 'photo-modal_confirm-hide',
        buttons: [
          {
            text: 'Confirm',
            handler: () => {
              const { params } = this
              const email = params.get('userEmail')
              this.auth.sendPasswordResetEmail(email)
              this.statusMessage = `Success! An email was sent to ${email}`
            },
          },
          {
            text: 'Cancel',
          },
        ],
      })
      .then((confirmAlert) => confirmAlert.present())
  }
}
