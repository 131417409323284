import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
} from '@angular/core'

import { FormGroup, FormBuilder } from '@angular/forms'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import {
  BarcodeScanner,
  BarcodeScannerOptions,
} from '@ionic-native/barcode-scanner/ngx'

import { HelpersProvider } from '../../services/helpers/helpers.service'
import { DeviceProvider } from '../../services/device/device.service'
import { EnvironmentProvider } from '../../services/environment/environment.service'

import { DeviceStatus } from '../../models-shared/device-status'

const scannerOptions: BarcodeScannerOptions = {
  preferFrontCamera: false,
  formats: 'QR_CODE',
  resultDisplayDuration: 0,
}

@Component({
  selector: 'search-device',
  templateUrl: './search-device.component.html',
  styleUrls: ['./search-device.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchDeviceComponent implements OnInit {
  @Input() paramDeviceId: string = ''
  @Input() deviceTransfer: boolean = false
  @Output() deviceId$ = new EventEmitter<string>()
  isLoading$ = new EventEmitter<boolean>()

  subscriptions: Subscription[] = []
  deviceSearch: Subject<string> = new Subject<string>()
  errorMessage: EventEmitter<string> = new EventEmitter<string>()

  showScanButton: boolean = false
  isScannerOpen: boolean = false
  searchDeviceForm: FormGroup
  validInput: boolean
  loadedParam = false

  constructor(
    private environment: EnvironmentProvider,
    private helpers: HelpersProvider,
    private barcodeScanner: BarcodeScanner,
    private device: DeviceProvider,
    private formBuilder: FormBuilder
  ) {
    this.showScanButton = this.hasCamera
    this.buildForm()
  }

  ngOnInit(): void {
    this.subscribe()
  }

  ngAfterViewChecked() {
    if (this.paramDeviceId !== '' && !this.loadedParam) {
      this.patchSearchValue(this.paramDeviceId)
      this.loadedParam = true
    }
  }

  buildForm() {
    this.searchDeviceForm = this.formBuilder.group({
      deviceId: ['', []],
    })
  }

  subscribe(): void {
    this.subscriptions.push(
      this.deviceSearch.subscribe((deviceId) => {
        this.deviceId$.emit('')
        this.errorMessage.next('')
        this.isLoading$.emit(true)
      })
    )
    this.subscriptions.push(
      this.deviceSearch.pipe(debounceTime(1000)).subscribe((deviceId) => {
        if (deviceId.length) {
          deviceId = deviceId.toUpperCase()
          this.device
            .deviceStatus(deviceId)
            .then(async (deviceStatus: DeviceStatus) => {
              const hasUsers = await this.device
                .deviceHasUsers(deviceId)
                .catch((err) => {
                  console.log(err)
                  return false
                })
              if (!deviceStatus.exists) {
                this.errorMessage.emit('BRNKL serial number not found')
                this.validInput = false
                console.log('Not exist')
              } else if (deviceStatus.alreadyAdded) {
                this.errorMessage.emit('BRNKL already added to account')
                this.validInput = false
              } else if (this.deviceTransfer && hasUsers) {
                console.log('device has users')
                this.validInput = false
                this.errorMessage.emit(
                  'Serial number assigned to another device. Check the serial number and try again.'
                )
              } else {
                this.deviceId$.emit(deviceId)
                this.errorMessage.emit('')
                this.validInput = true
              }
              this.isLoading$.emit(false)
            })
            .catch((err) => {
              this.isLoading$.emit(false)
            })
        } else {
          this.isLoading$.emit(false)
        }
      })
    )
  }

  unsubscribe(): void {
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  ngOnDestroy(): void {
    this.unsubscribe()
  }

  get hasCamera(): boolean {
    return this.environment.isNativeApp()
  }

  async scanSerialNum(): Promise<void> {
    if (this.showScanButton && !this.isScannerOpen) {
      this.isScannerOpen = true

      try {
        const barcode = await this.barcodeScanner.scan(scannerOptions)

        if (!barcode.cancelled) {
          this.patchSearchValue(barcode.text)
        }
      } catch (e) {
        this.helpers.showToast(
          'BRNKL needs to use the camera on this device to scan. In the device Settings, allow BRNKL access to the camera.'
        )
      }

      this.isScannerOpen = false
    }
  }

  patchSearchValue(deviceId: string) {
    // Blue QR Codes link to a URL not just the serial number
    const bluePattern = /http:\/\/(.*?).local/
    const match = bluePattern.exec(deviceId)

    if (match) {
      deviceId = match[1]
      console.log(deviceId)
    }
    this.searchDeviceForm.patchValue({ deviceId })
    this.searchDeviceForm.controls.deviceId.markAsDirty()
    this.deviceSearch.next(deviceId)
  }
}
