import { Component, ViewEncapsulation } from '@angular/core'
import { NavParams, ModalController, AlertController } from '@ionic/angular'
import { PhotosProvider } from '../../services/photos/photos.service'
import { AuthProvider } from '../../services/auth/auth.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { SettingsProvider } from '../../services/settings/settings.service'

@Component({
  selector: 'photo-modal',
  templateUrl: './photo-modal.component.html',
  styleUrls: ['./photo-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PhotoModalComponent {
  constructor(
    public params: NavParams,
    private viewCtrl: ModalController,
    private alertCtrl: AlertController,
    private photosProvider: PhotosProvider,
    private settingsProvider: SettingsProvider,
    private auth: AuthProvider,
    private helpers: HelpersProvider
  ) {}

  dismiss(): void {
    this.viewCtrl.dismiss()
  }

  hidePhoto(): void {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Hiding photos is not available in demo mode.')
      return
    }

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    const deviceId: string | null = this.params.get('deviceId')
    const mateId: string | null = this.params.get('mateId')
    const camId: string | null = this.params.get('camId')
    const photoId: string | null = this.params.get('photoId')

    if (
      (deviceId == null && mateId == null) ||
      camId == null ||
      photoId == null
    ) {
      this.helpers.showDangerToast(
        'Failed to hide photo. Contact BRNKL support.'
      )
      return
    }

    this.alertCtrl
      .create({
        header: 'Hide Photo?',
        message: 'Hidden photos cannot be restored in this version of the app.',
        cssClass: 'photo-modal_confirm-hide',
        buttons: [
          {
            text: 'Confirm',
            handler: () => {
              this.photosProvider.hidePhoto(deviceId, mateId, camId, photoId)
              this.viewCtrl.dismiss()
            },
          },
          {
            text: 'Cancel',
          },
        ],
      })
      .then((confirmAlert) => confirmAlert.present())
  }
}
