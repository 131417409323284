import { Stat } from '../../models-shared/stat.model'

import { GraphInfo } from '../../models/graph-info.model'
import { GraphSlide } from '../../models/graph-slide.model'
import { ChartConfiguration } from 'chart.js'
import {
  DATE_FORMATTER,
  parseDate,
} from 'app/reducers/root-reducer/datetime-reducer'
import { StatSources } from 'app/models-shared/stat-sources'

const graphLineTension: number = 0.1

function statSourceToName(statSource: StatSources) {
  let name = StatSources[statSource]
  if (!name) {
    name = 'Cellular'
  }
  return name
}

function buildDataSets(
  transformedData: Stat<any>[],
  name: string,
  steppedLine: boolean
): ChartConfiguration['data'] {
  const finalData = transformedData.map((stat) => stat.val)
  const dateTime = transformedData.map((stat: Stat<any>) => stat.datetime)
  const mainDataSet = {
    label: name,
    data: finalData,
    backgroundColor: '#4BC0C0',
    borderColor: '#3D9C9C',
    pointBackgroundColor: '#4BC0C0',
    pointBorderColor: '#3D9C9C',
    pointHoverBackgroundColor: '#4BC0C0',
    pointHoverBorderColor: '#3D9C9C',
    stepped: steppedLine,
    lineTension: graphLineTension,
    fill: false,
  }

  return {
    datasets: [mainDataSet],
    labels: dateTime,
  }
}

function buildChartTooltips(
  stats: Stat<any>[],
  isHighLow: boolean,
  highLowCallback
) {
  const sourceCallback = (toolTip) => {
    const statSource = stats[toolTip.dataIndex]?.source
      ? stats[toolTip.dataIndex].source
      : 0
    return 'Source: ' + statSourceToName(statSource)
  }

  const toolTipCallbacks: Chart.ChartTooltipCallback = {
    afterLabel: (toolTip) => sourceCallback(toolTip),
  }
  if (isHighLow) {
    toolTipCallbacks.label = (toolTip) => highLowCallback(toolTip.yLabel)
  }

  return {
    intersect: false,
    callbacks: toolTipCallbacks,
  }
}

function buildChartPlugins(
  stats: Stat<any>[],
  isHighLow: boolean,
  highLowCallback
) {
  const tooltips = buildChartTooltips(stats, isHighLow, highLowCallback)

  return {
    tooltip: tooltips,
    legend: {
      display: false,
    },
  }
}

export function buildGraphSlide(info: GraphInfo): GraphSlide {
  const data: Stat<any>[] = info.stats

  const noData: boolean =
    data.filter((k: Stat<any>) => k.dontTransform).length > 0 ||
    data.length <= 0

  // Data to be built into graph
  const dataSets: ChartConfiguration['data'] = buildDataSets(
    data,
    info.name,
    info.steppedLine
  )

  // Tick Options for Y-Axis
  const isHighLow = !!info.lowName && !!info.highName
  const highLowCallback = (label) => {
    if (!isHighLow) return label
    const value: number = +label
    return value === 1 || value === 0
      ? value === 1
        ? info.highName
        : info.lowName
      : undefined
  }

  const tickOptions: Chart.TickOptions = {}

  if (isHighLow) tickOptions.callback = highLowCallback

  // Plugins for the graph
  const chartPlugins = buildChartPlugins(data, isHighLow, highLowCallback)

  // Options for the graph
  // Compacts all of the options into a single JSON format
  // so ChartJS can build the graph
  const options = {
    elements: {
      line: {
        tension: graphLineTension,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        ticks: {
          maxTicksLimit: 4,
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
        time: {
          displayFormats: {
            day: 'ddd',
            hour: 'ddd H:mm',
          },
          parser: parseDate,
          tooltipFormat: DATE_FORMATTER,
        },
      },
      y: {
        position: 'left',
        type: 'linear',
        ticks: tickOptions,
        beginAtZero: isHighLow,
      },
    },
    hover: {
      intersect: false,
    },
    plugins: chartPlugins,
  }

  return {
    datetimes: data.map((stat: Stat<any>) => stat.datetime),
    data: dataSets,
    name: info.name,
    unit: info.unit,
    noData: noData,
    chartConfig: options,
  }
}
