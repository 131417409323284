import { Injectable } from '@angular/core'
import { select } from '@angular-redux/store'
import { AppDevice } from 'app/models/app-device.model'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class HlsService {
  streams: { [src: string]: any } = {}
  tunnelUrl: string
  @select('currentDevice') currentDevice$: Observable<AppDevice>
  constructor() {
    this.currentDevice$.subscribe((device) => {
      this.tunnelUrl = device.settings.tunnelUrl
    })
  }

  async loadStream(camId: string) {
    const url = `https://${this.tunnelUrl}/hls/${camId}/`
    this.streams[camId] = url
  }

  async getStream(camId: string): Promise<string> {
    let stream = this.streams[camId]

    // If the stream is not found, create a new one, add it to the streams array, and wait for it to connect
    if (!stream) {
      await this.loadStream(camId)
      stream = this.streams[camId]
    }
    console.log("here's the stream", stream)
    return stream
  }

  async closeStream(camId: string) {
    const streamClient = this.streams[camId]
    if (streamClient) {
      // Close the PeerConnection
      if (streamClient.pc) {
        // Close any media tracks associated with the PeerConnection
        const streams = streamClient.pc
          .getLocalStreams()
          .concat(streamClient.pc.getRemoteStreams())
        streams.forEach((stream) => {
          stream.getTracks().forEach((track) => {
            track.stop() // Stop each track
          })
        })

        streamClient.pc.close()
      }

      // Delete the stream client reference from the streams map
      delete this.streams[camId]
    }
  }
}

// const iceServers = [
//   {
//     urls: 'stun:stun.relay.metered.ca:80',
//   },
//   {
//     urls: 'turn:a.relay.metered.ca:80',
//     username: '2726ca25c461f8ac939e8550',
//     credential: 'I532JkO9TI9EoPoM',
//   },
//   {
//     urls: 'turn:a.relay.metered.ca:80?transport=tcp',
//     username: '2726ca25c461f8ac939e8550',
//     credential: 'I532JkO9TI9EoPoM',
//   },
//   {
//     urls: 'turn:a.relay.metered.ca:443',
//     username: '2726ca25c461f8ac939e8550',
//     credential: 'I532JkO9TI9EoPoM',
//   },
//   {
//     urls: 'turn:a.relay.metered.ca:443?transport=tcp',
//     username: '2726ca25c461f8ac939e8550',
//     credential: 'I532JkO9TI9EoPoM',
//   },
// ]

// const restartPause = 2000

// const unquoteCredential = (v) => JSON.parse(`"${v}"`)

// const linkToIceServers = (links) =>
//   links !== null
//     ? links.split(', ').map((link) => {
//         const m = link.match(
//           /^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i
//         )
//         const ret: any = {
//           urls: [m[1]],
//         }

//         if (m[3] !== undefined) {
//           ret.username = unquoteCredential(m[3])
//           ret.credential = unquoteCredential(m[4])
//           ret.credentialType = 'password'
//         }

//         return ret
//       })
//     : []

// const parseOffer = (offer) => {
//   const ret: any = {
//     iceUfrag: '',
//     icePwd: '',
//     medias: [],
//   }

//   for (const line of offer.split('\r\n')) {
//     if (line.startsWith('m=')) {
//       ret.medias.push(line.slice('m='.length))
//     } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
//       ret.iceUfrag = line.slice('a=ice-ufrag:'.length)
//     } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
//       ret.icePwd = line.slice('a=ice-pwd:'.length)
//     }
//   }

//   return ret
// }

// const generateSdpFragment = (offerData, candidates) => {
//   const candidatesByMedia = {}
//   for (const candidate of candidates) {
//     const mid = candidate.sdpMLineIndex
//     if (candidatesByMedia[mid] === undefined) {
//       candidatesByMedia[mid] = []
//     }
//     candidatesByMedia[mid].push(candidate)
//   }

//   let frag =
//     'a=ice-ufrag:' +
//     offerData.iceUfrag +
//     '\r\n' +
//     'a=ice-pwd:' +
//     offerData.icePwd +
//     '\r\n'

//   let mid = 0

//   for (const media of offerData.medias) {
//     if (candidatesByMedia[mid] !== undefined) {
//       frag += 'm=' + media + '\r\n' + 'a=mid:' + mid + '\r\n'

//       for (const candidate of candidatesByMedia[mid]) {
//         frag += 'a=' + candidate.candidate + '\r\n'
//       }
//     }
//     mid++
//   }

//   return frag
// }

// class WHEPClient {
//   src: string
//   video: any
//   pc: any
//   restartTimeout: any
//   eTag: any
//   queuedCandidates: any
//   offerData: any

//   connected: boolean
//   connectionPromise: Promise<void>
//   resolveConnection: any

//   constructor(src: string) {
//     this.src = src
//     this.video = document.createElement('video')
//     this.pc = null
//     this.restartTimeout = null
//     this.eTag = ''
//     this.queuedCandidates = []
//     this.connected = false

//     // Create a promise that will resolve when the connection is established
//     this.connectionPromise = new Promise((resolve) => {
//       this.resolveConnection = resolve
//     })

//     this.start()
//   }

//   start() {
//     console.log('requesting ICE servers', this.src)

//     fetch(new URL('whep', this.src) + window.location.search, {
//       method: 'OPTIONS',
//     })
//       .then((res) => this.onIceServers(res))
//       .catch((err) => {
//         console.log('error: ' + err)
//         this.scheduleRestart()
//       })
//   }

//   onIceServers(res) {
//     const fetchedIceServers = linkToIceServers(res.headers.get('Link'))
//     const combinedIceServers = [...fetchedIceServers, ...iceServers]
//     this.pc = new RTCPeerConnection({
//       iceServers: combinedIceServers,
//     })

//     const direction = 'sendrecv'
//     this.pc.addTransceiver('video', { direction })
//     this.pc.addTransceiver('audio', { direction })

//     this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt)
//     this.pc.oniceconnectionstatechange = () => this.onConnectionState()

//     this.pc.ontrack = (evt) => {
//       console.log('new track:', evt.track.kind)
//       this.video.srcObject = evt.streams[0]
//     }

//     this.pc.createOffer().then((offer) => this.onLocalOffer(offer))
//   }

//   onLocalOffer(offer) {
//     this.offerData = parseOffer(offer.sdp)
//     this.pc.setLocalDescription(offer)

//     console.log('sending offer')

//     fetch(new URL('whep', this.src) + window.location.search, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/sdp',
//       },
//       body: offer.sdp,
//     })
//       .then((res) => {
//         if (res.status !== 201) {
//           throw new Error('bad status code')
//         }
//         this.eTag = res.headers.get('ETag')
//         return res.text()
//       })
//       .then((sdp) =>
//         this.onRemoteAnswer(
//           new RTCSessionDescription({
//             type: 'answer',
//             sdp,
//           })
//         )
//       )
//       .catch((err) => {
//         console.log('error: ' + err)
//         this.scheduleRestart()
//       })
//   }

//   resetConnectionPromise() {
//     this.connected = false
//     this.connectionPromise = new Promise((resolve) => {
//       this.resolveConnection = resolve
//     })
//   }

//   onConnectionState() {
//     if (this.restartTimeout !== null) {
//       return
//     }

//     console.log('peer connection state:', this.pc.iceConnectionState)

//     if (
//       this.pc.iceConnectionState === 'connected' ||
//       this.pc.iceConnectionState === 'completed'
//     ) {
//       this.connected = true
//       this.resolveConnection() // Resolve the promise
//     } else if (
//       this.pc.iceConnectionState === 'disconnected' ||
//       this.pc.iceConnectionState === 'failed'
//     ) {
//       this.resetConnectionPromise()
//       this.scheduleRestart()
//     }
//   }

//   onRemoteAnswer(answer) {
//     if (this.restartTimeout !== null) {
//       return
//     }

//     this.pc.setRemoteDescription(new RTCSessionDescription(answer))

//     if (this.queuedCandidates.length !== 0) {
//       this.sendLocalCandidates(this.queuedCandidates)
//       this.queuedCandidates = []
//     }
//   }

//   onLocalCandidate(evt) {
//     if (this.restartTimeout !== null) {
//       return
//     }

//     if (evt.candidate !== null) {
//       if (this.eTag === '') {
//         this.queuedCandidates.push(evt.candidate)
//       } else {
//         this.sendLocalCandidates([evt.candidate])
//       }
//     }
//   }

//   sendLocalCandidates(candidates) {
//     fetch(new URL('whep', this.src) + window.location.search, {
//       method: 'PATCH',
//       headers: {
//         'Content-Type': 'application/trickle-ice-sdpfrag',
//         'If-Match': this.eTag,
//       },
//       body: generateSdpFragment(this.offerData, candidates),
//     })
//       .then((res) => {
//         if (res.status !== 204) {
//           throw new Error('bad status code')
//         }
//       })
//       .catch((err) => {
//         console.log('error: ' + err)
//         this.scheduleRestart()
//       })
//   }

//   scheduleRestart() {
//     if (this.restartTimeout !== null) {
//       return
//     }

//     if (this.pc !== null) {
//       this.pc.close()
//       this.pc = null
//     }

//     this.resetConnectionPromise()

//     this.restartTimeout = window.setTimeout(() => {
//       this.restartTimeout = null
//       this.start()
//     }, restartPause)

//     this.eTag = ''
//     this.queuedCandidates = []
//   }
// }
