import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core'
import { Observable, combineLatest, merge, timer, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { StripePlan } from '../../models/stripe-plan.model'

import { SubscriptionProvider } from '../../services/subscription/subscription.service'
import { AnalyticsProvider } from '../../services/analytics/analytics.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { CardPaymentComponent } from '../card-payment/card-payment.component'

import { SubscriptionState } from '../../models-shared/subscription-state.model'
import { PricingRequest } from '../../models-shared/pricing-req.model'
import { CardRadio } from '../../models/card-radio.model'

export const subscriptionStateToCards = (
  s: SubscriptionState
): [CardRadio[], string] => {
  const cards = s.plans
    .filter(
      (plan) =>
        plan.product === 'prod_EbvTaE1kjrmAok' ||
        plan.product === 'prod_CrXdfwzDT6kx2E'
    )
    .map(
      ({
        nickname: title,
        interval,
        amount,
        id: key,
      }: StripePlan): CardRadio => {
        const rawAmount = (amount / 100).toFixed(2)
        return {
          title,
          interval,
          amount: rawAmount,
          key,
        }
      }
    )
  return [cards, cards[0].key]
}

@Component({
  selector: 'subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent {
  @ViewChild('payment') paymentComp: CardPaymentComponent

  @Input() deviceId: string = ''
  @Input() email: string = ''

  @Output() payment = new EventEmitter<boolean>()

  error?: stripe.Error
  showPayment: boolean = false
  cards: CardRadio[] = []

  coupon: string = ''
  coupon$ = new EventEmitter<string>()

  lastStripeEvent: stripe.elements.ElementChangeResponse
  postalCode$ = new EventEmitter<string>()

  _subscriptionState: SubscriptionState = {
    plans: [],
  }

  selectedPlan: string = ''
  selectedPlan$ = new EventEmitter<string>()
  paid = false
  isLoading = false

  get subscriptionState(): SubscriptionState {
    return this._subscriptionState
  }

  set subscriptionState(s) {
    this._subscriptionState = s
    const [cards, key] = subscriptionStateToCards(s)
    this.cards = cards
    this.selectedPlan = key
  }

  resolve: (event: any) => void

  ready = new Promise<void>((resolve) => (this.resolve = resolve))

  pricingInfo$: Observable<PricingRequest>
  constructor(
    private subscription: SubscriptionProvider,
    private analytics: AnalyticsProvider,
    private helpers: HelpersProvider
  ) {}

  ngOnInit() {
    this.init()
  }

  async init() {
    try {
      // be sure to fetch the plans before
      // we make a pricing request
      this.subscriptionState = await this.subscription.getState()
      this.pricingInfo$ = this.getPricingInfo()
      await this.ready // wait for this components' resolve method to be called
      this.showPayment = true
      // TODO report a proper error here (after monorepo)
    } catch (err) {
      this.analytics.reportError(new Error(''))
    }
  }

  getPricingInfo(): Observable<PricingRequest> {
    // All three of these need to emit once on
    // init (merging with `timer` or `of`),
    // and then map into the single data stream that we need

    return combineLatest([
      merge(this.coupon$, timer(0)),
      merge(this.postalCode$, timer(0)),
      merge(this.selectedPlan$, of(this.selectedPlan)),
    ]).pipe(
      map(([coupon, postalCode, plan]: [string, string, string]) => {
        return { postalCode, plan, coupon } as PricingRequest
      })
    )
  }

  selectedPlanHandler(plan: string) {
    this.selectedPlan = plan
    this.selectedPlan$.emit(plan)
  }

  couponHandler(coupon: string) {
    this.coupon = coupon
    this.coupon$.emit(coupon)
  }

  stripeHandler(event: stripe.elements.ElementChangeResponse) {
    this.lastStripeEvent = event
    // this.postalCode$.emit(event.value.postalCode.toString())
  }

  submit() {
    this.paymentComp.createToken()
  }

  async subReqHandler(paymentToken: string) {
    try {
      if (this.selectedPlan && paymentToken) {
        this.isLoading = true
        await this.subscription.activate(this.deviceId, {
          paymentToken,
          coupon: this.coupon,
          plan: this.selectedPlan,
          postalCode: '123455',
        })
        this.paid = true
        this.payment.emit(true)
        this.helpers.showToast('Payment Successful!', 10)
      }
    } catch (err) {
      this.error = err
      this.payment.emit(false)
      this.helpers.showDangerToast('Payment Failed!', 10)
    }

    this.isLoading = false
  }
}
