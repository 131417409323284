// TODO rename this to some time util

import moment from 'moment'

// export this stuff here
// such that we can inject it into a
// chartjs config
export const DATE_FORMATTER: string = 'HH[:]mm[:]ss DD MMM[,] YYYY'

export const parseDate = (datetime: number, tz = moment.tz.guess()) =>
  moment.unix(datetime).tz(tz)

export const formatDate = (datetime: number, tz?): string =>
  parseDate(datetime, tz).format(DATE_FORMATTER)
