<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-title>Change Vessel Password</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="save()">Save</ion-button>
    </ion-buttons>
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <set-password></set-password>
</ion-content>
