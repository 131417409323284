<div
  class="stats-grid-open-btn"
  [class.stats-grid_container--open]="expanded"
  (click)="toggleOpen()"
>
  <div class="stats-grid-open-btn-icon">
    <ng-container *ngIf="!expanded; else closed">
      <ion-icon class="arrow-icon" icon="chevron-up-outline"></ion-icon>
    </ng-container>
    <ng-template #closed>
      <ion-icon class="arrow-icon" icon="chevron-down-outline"></ion-icon>
    </ng-template>
  </div>
</div>

<ng-container>
  <div
    class="stats-grid_container"
    [class.stats-grid_container--closed]="!expanded"
    [class.stats-grid_container--open]="expanded"
  >
    <ion-list class="ion-no-padding">
      <ion-item
        button
        detail="false"
        (click)="editOpen ? null : pushTabsPage(device)"
        *ngFor="let device of allDevices$ | async; trackBy: trackById"
      >
        <ion-icon
          *ngIf="device?.settings?.lastUpdated <= offlineTimer"
          slot="start"
          class="offline-icon"
          name="custom-offline-status-bar"
        ></ion-icon>
        <ion-icon
          *ngIf="
            device?.settings?.isArmed &&
            !(device?.settings?.lastUpdated <= offlineTimer)
          "
          slot="start"
          name="custom-armed"
          [color]="'armed'"
        ></ion-icon>
        <ion-icon
          *ngIf="
            !device?.settings?.isArmed &&
            !(device?.settings?.lastUpdated <= offlineTimer)
          "
          slot="start"
          name="custom-disarmed"
          [ngClass]="'disarmed-faded'"
        ></ion-icon>
        <span>{{ device?.settings?.deviceName }}</span>
        <ion-icon
          *ngIf="editOpen"
          slot="end"
          name="trash-outline"
          (click)="unlink(device.deviceId)"
        ></ion-icon>
        <ion-icon
          *ngIf="!editOpen"
          class="arrow-icon"
          slot="end"
          name="arrow-forward-outline"
        ></ion-icon>
      </ion-item>
    </ion-list>
  </div>
  <div class="stats-grid-container" *ngIf="expanded"></div>
</ng-container>
