import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { ModalController } from '@ionic/angular'
import { ReauthModalComponent } from 'app/components/reauth-modal/reauth-modal.component'
import firebase from 'firebase/compat/app'
import { Observable } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import { select } from '@angular-redux/store'
import { AppDevice } from 'app/models/app-device.model'
import { FirebaseProvider } from '../firebase/firebase.service'
import { DbPathsProvider } from '../db-paths/db-paths.service'
import { EnvironmentProvider } from '../environment/environment.service'

@Injectable({
  providedIn: 'root',
})
export class AuthProvider {
  public user: Observable<firebase.User>
  public logouts$: Observable<any>
  public authState: firebase.User

  @select('allDevices') allDevices$: Observable<AppDevice[]>

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private modalCtrl: ModalController,
    private fb: FirebaseProvider,
    private paths: DbPathsProvider,
    private envionrment: EnvironmentProvider
  ) {
    this.user = afAuth.authState
    // create auth state object
    afAuth.authState.subscribe((authState) => {
      this.authState = authState
    })
    // logouts happen when a user is
    // emitted without a uid
    this.logouts$ = this.user.pipe(
      filter((user, index) => user == null && index > 0)
    )
  }

  public async createAccount(
    email: string,
    password: string,
    firstName?: string,
    lastName?: string,
    phone?: string
  ): Promise<any> {
    const userCredentals: firebase.auth.UserCredential =
      await this.afAuth.createUserWithEmailAndPassword(email, password)
    const user: firebase.User = userCredentals.user

    return await this.db.object(`/users/${user.uid}`).update({
      ...(firstName ? { firstName: firstName } : undefined),
      ...(lastName ? { lastName: lastName } : undefined),
      ...(phone ? { phone: phone } : undefined),
      preferredTempUnit: 'C',
    })
  }

  public async currentUserUID() {
    let currentUser: firebase.User
    await this.afAuth.currentUser.then((user) => (currentUser = user))
    return currentUser.uid
  }

  public login(email: string, password: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, password)
  }

  public loginDemoAccount(): Promise<any> {
    return Promise.resolve()
  }

  public isDemoAccount(): boolean {
    return false
  }

  public logout(): void {
    this.allDevices$
      .pipe(
        take(1), // Make sure to unsubscribe after receiving the first value
        map((devices) => devices.map((device) => device.deviceId)) // Map each device to its id
      )
      .subscribe((deviceIds) => {
        console.log('IDS: ', deviceIds)
        this.unregisterAppDevice(deviceIds).then(() => {
          // Then sign the user out
          this.afAuth.signOut()
        })
      })
  }

  private loginWithProvider(provider: any): Promise<any> {
    return this.afAuth.signInWithPopup(provider)
  }

  public loginWithFacebook(): Promise<any> {
    return this.loginWithProvider(new firebase.auth.FacebookAuthProvider())
  }

  public loginWithTwitter(): Promise<any> {
    return this.loginWithProvider(new firebase.auth.TwitterAuthProvider())
  }

  public loginWithGoogle(): Promise<any> {
    return this.loginWithProvider(new firebase.auth.GoogleAuthProvider())
  }

  public sendPasswordResetEmail(email: string) {
    return this.afAuth.sendPasswordResetEmail(email)
  }

  public reauthWithPassword(pw: string) {
    const credential = firebase.auth.EmailAuthProvider.credential(
      this.authState.email,
      pw
    )
    return this.authState.reauthenticateWithCredential(credential)
  }

  public async deleteCurrentUser(): Promise<any> {
    return this.authState.delete()
  }

  public async reauthUser(): Promise<any> {
    return this.openReauthModal(this.authState.email)
  }

  async openReauthModal(currentEmail: string) {
    const callback = new Promise<void>(async (resolve, reject) => {
      console.log(currentEmail)
      const params = {
        resolve,
        reject,
        modalCtrl: this.modalCtrl,
        userEmail: currentEmail,
      }
      // CHANGE -- This might not work
      const modal = await this.modalCtrl.create({
        component: ReauthModalComponent,
        componentProps: params,
        cssClass: 'reauth-modal-long',
      })
      await modal.present()
      const { data } = await modal.onDidDismiss()
      if (data) {
        resolve()
      } else {
        reject(new Error('Authentication failed.'))
      }

      modal.present()
    })

    return callback
  }

  public async unregisterAppDevice(deviceIds: string[]): Promise<any> {
    if (this.envionrment.isNativeApp() && !this.isDemoAccount()) {
      const deviceToken: string = await this.fb.getToken()
      const uid = await this.currentUserUID()
      const unregistrations: Promise<void>[] = deviceIds.map((id) => {
        return this.unregisterAppDeviceWithUnit(id, uid, deviceToken)
      })
      return Promise.all(unregistrations)
    }
  }

  private async unregisterAppDeviceWithUnit(
    deviceId: string,
    uid: string,
    token: string
  ): Promise<void> {
    const ref = this.db.list(this.paths.notificationTokens(deviceId, uid))

    const tokens: { [key: string]: any } = await ref
      .snapshotChanges()
      .pipe(take(1))
      .toPromise()
      .then((snapshot) => {
        return snapshot.reduce((acc, item) => {
          acc[item.key] = item.payload.val()
          return acc
        }, {})
      })

    const tokenKey = Object.keys(tokens).filter(
      (key) => tokens[key] === token
    )[0]

    if (tokenKey) {
      await ref.remove(tokenKey)
    }
  }
}
