<ion-grid>
  <ion-row>
    <ion-col *ngFor="let card of cards" class="card-radio_card-container">
      <button
        class="card-radio_card"
        [disabled]="disabled"
        [class.card-radio_card__selected]="card.key === selectedCard"
        (click)="selectCard(card.key)"
      >
        <p class="card-radio_title">{{ card.title }}</p>
        <p class="card-radio_subtitle">{{ card.amount }}/{{ card.interval }}</p>
      </button>
    </ion-col>
  </ion-row>
</ion-grid>
