import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { SetupOption } from '../../models/setup-option.model'

@Component({
  selector: 'setup-options',
  templateUrl: './setup-options.component.html',
  styleUrls: ['./setup-options.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupOptionsComponent {
  selectedOption: SetupOption = {
    name: '',
    nextButtonText: '',
  }

  @Output() option = new EventEmitter()

  @Input() header: string
  @Input() description: string
  @Input() options: SetupOption[]

  optionPressed(option: SetupOption) {
    //If you click the same button again it deactivates
    if (this.selectedOption.name === option.name) {
      this.selectedOption = {
        name: '',
        nextButtonText: '',
      }
      return
    }

    this.selectedOption = option
  }

  nextButtonPressed() {
    if (this.selectedOption.name === '') return
    this.option.emit(this.selectedOption.name)
  }
}
