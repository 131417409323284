<div
  class="signal-indicator signal-indicator__{{ signalLevel }}"
  *ngIf="!satelliteConnection && signalLevel && !isBlueDevice()"
>
  <div
    *ngFor="let bar of nBars"
    class="signal-indicator_bar signal-indicator_bar__{{ bar }}"
  ></div>
</div>
<div
  *ngIf="!satelliteConnection && signalLevel == null && !isBlueDevice()"
  class="no-connection"
>
  <ion-icon name="custom-no-mobile-connection"></ion-icon>
</div>
<div class="satellite-indicator" *ngIf="satelliteConnection && !isBlueDevice()">
  <ion-icon name="custom-satellite-connection"></ion-icon>
</div>
