import { Component } from '@angular/core'
import { NavController, NavParams } from '@ionic/angular'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import { AppUpdateProvider } from '../../services/app-update/app-update.service'

@Component({
  selector: 'app-force-update',
  templateUrl: './force-update.page.html',
  styleUrls: ['./force-update.page.scss'],
})
export class ForceUpdatePage {
  public updateLink: string

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private environmentProvider: EnvironmentProvider,
    private appUpdateProvider: AppUpdateProvider
  ) {
    if (this.environmentProvider.isIOSApp()) {
      this.updateLink = 'https://apps.apple.com/us/app/brnkl/id1268970446'
    } else if (this.environmentProvider.isAndroidApp()) {
      this.updateLink =
        'https://play.google.com/store/apps/details?id=com.brnkl.app'
    } else {
      this.updateLink = 'https://www.brnkl.io'
    }
  }

  public skipUpdate() {
    this.appUpdateProvider.ignore()
  }
}
