import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { BaseInputComponent } from '../base-input/base-input.component'

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent
  extends BaseInputComponent
  implements ControlValueAccessor
{
  @Input() labelClass: string = ''
  @Input() required: boolean = false
  @Input() class: string = ''
  @Input() type: string = 'text'
  @Input() placeholder: string = ''
  @Input() label?: string
  @Input() ngModel: string = ''
  @Input() disableInput: boolean = false
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter()
}
