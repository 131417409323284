<ng-container *ngIf="plan; else spacing">
  <setup-device-box
    [iconName]="plan.iconName"
    [header]="plan.companyName"
    [deviceName]="plan.title"
  ></setup-device-box>
</ng-container>
<ng-template #spacing>
  <div class="setup-payment_device-placeholder"></div>
</ng-template>

<div class="setup-payment_header-icon-container">
  <ion-icon name="card" class="setup-payment_icon"> </ion-icon>
  <div class="setup-payment_title">{{ header }}</div>
</div>
<div class="setup-payment_text setup-payment_bottom-space">
  {{ description }}
</div>
<div class="setup-payment_payment-items">
  <ion-grid>
    <ion-row *ngIf="addActivationFee()">
      <ion-col
        size="9"
        class="setup-payment_text setup-box-item setup-service-activation"
      >
        <div class="setup-payment_text setup-box-item">Activation (1x)</div>
      </ion-col>
      <ion-col size="3" float-right class="setup-box-pricing">
        <div class="setup-box-service-currency">{{ plan.currency }}</div>
        <div class="setup-box-service-amount">${{ activationFee }}</div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="9" class="setup-box-text">
        <div class="setup-payment_text setup-box-item">
          {{ plan.interval === 'Monthly' ? 'Monthly' : 'Annual' }} fee
        </div>
        <div class="setup-payment_subtext">
          {{ validInput === 'valid' ? 'Discount applied' : 'Recurring charge' }}
        </div>
      </ion-col>
      <ion-col size="3" float-right class="setup-box-pricing">
        <div class="setup-box-service-currency">{{ plan.currency }}</div>
        <div class="setup-box-service-amount">
          ${{ discount ? plan.amount - discount : plan.amount }}
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<div class="setup-payment_coupon">
  <search-bar
    *ngIf="hasCoupon"
    class="search-coupon_input"
    [validInput]="validInput"
    [isLoading$]="isLoading$"
    inputPlaceholder="Coupon Code"
    [ngModel]="coupon"
    (ngModelChange)="setCouponCode($event)"
    (clickSearch)="handleCouponCode()"
  >
  </search-bar>
</div>
<button
  item-end
  class="setup-payment_toggle-coupon"
  (click)="toggleCouponField()"
>
  Discount Code?
</button>
<div class="setup-payment_action-buttons">
  <div class="setup-payment_btn-align">
    <div>
      <div class="setup-payment_subtext">Plus applicable taxes.</div>
      <a
        class="setup-payment_subtext setup-payment_t-and-c"
        href="https://www.brnkl.io/terms/"
        target="_blank"
      >
        Terms and conditions.
      </a>
    </div>

    <button class="setup-payment_btn-action" (click)="next.emit()">
      {{ nextButtonText }}
    </button>
  </div>
</div>
