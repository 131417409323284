import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-video-player-modal',
  templateUrl: './video-player-modal.component.html',
  styleUrls: ['./video-player-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerModalComponent implements OnInit {
  @Input() videoUrl: string
  @Input() videoName: any
  constructor(private modalController: ModalController) {}

  dismiss(): void {
    this.modalController.dismiss()
  }

  ngOnInit(): void {}
}
