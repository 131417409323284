import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { WirelessSensorDisplay } from '../../models/wireless-sensor-display.model'

@Component({
  selector: 'setup-progress',
  templateUrl: './setup-progress.component.html',
  styleUrls: ['./setup-progress.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupProgressComponent {
  @Output() cancel = new EventEmitter()

  @Input() header: string
  @Input() description: string
  @Input() progressStep: string
  @Input() progressPercent: number
  @Input() progressIconName: string
  @Input() doneButtonText: string
  @Input() device: WirelessSensorDisplay
}
