<ion-header>
  <ion-toolbar>
    <ion-title>Re-authenticate User</ion-title>
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding small>
  <span class="reauth-modal_text">
    This action requires authentication. Enter your password to continue.
  </span>
  <form [formGroup]="pwForm" class="reauth-modal">
    <ion-item class="reauth-modal_input" no-padding>
      <ion-label position="stacked">Password</ion-label>
      <ion-input type="password" formControlName="pw"></ion-input>
    </ion-item>
    <optional-text>
      <ion-spinner small *ngIf="showSpinner"></ion-spinner>
      <span *ngIf="!showSpinner" class="reauth-modal_text">
        {{ statusMessage }}
      </span>
    </optional-text>
    <ion-button
     
      outline
      class="reauth-modal_button"
      [disabled]="!pwForm.valid || disableButton"
      color="light"
      (click)="reauth()"
    >
      Submit
    </ion-button>
    <a
      href=""
      role="button"
      (click)="forgetPassword($event)"
      class="login_create-link"
      padding-top
    >
      Forgot Password
    </a>
  </form>
</ion-content>
