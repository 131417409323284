import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { convertLongDecimalToDegress as convertLongDecimalToDegrees } from '../../util'

@Pipe({
  name: 'convertLongitudeToDegrees'
})
@Injectable()
export class ConvertLongitudePipe implements PipeTransform {
  constructor() {}

  public transform(value: string, ...args): string {
    if (value && args[0] === 'Deg Min Sec') {
      return convertLongDecimalToDegrees(value)
    } else {
      return value
    }
  }
}
