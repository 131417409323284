import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import { DeviceSettings } from 'app/models-shared/device-settings.model'
import { CloudFunctionsProvider } from 'app/services/cloud-functions/cloud-functions.service'
import { DeviceProvider } from 'app/services/device/device.service'
import { SettingsProvider } from 'app/services/settings/settings.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationButtonComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = []
  newAlert: boolean = true

  constructor(
    private router: Router,
    private settingsProvider: SettingsProvider,
    private cloudFunctions: CloudFunctionsProvider,
    private device: DeviceProvider
  ) {}

  ngOnInit(): void {
    this.subscribe()
  }

  ngOnDestroy(): void {
    this.unsubscribe()
  }

  subscribe(): void {
    this.subscriptions.push(
      this.settingsProvider.deviceSettings$.subscribe(
        (deviceSettings: DeviceSettings) => {
          this.newAlert = deviceSettings.alertsConfig
            ? deviceSettings.alertsConfig.newAlert
            : false
        }
      )
    )
  }

  unsubscribe(): void {
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  navigateToAlerts() {
    this.router.navigate(['/tabs/tabs/alerts'])
    this.cloudFunctions.authedPost(
      `alerts/clearNewAlert/${this.device.currentBRNKLandMateId$.value.deviceId}`
    )
  }

  // placeholder function for now
  numNotifications(): number {
    return 10
  }
}
