import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core'
import { CardRadio } from '../../models/card-radio.model'

@Component({
  selector: 'card-radio',
  templateUrl: './card-radio.component.html',
  styleUrls: ['./card-radio.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardRadioComponent {
  @Input() cards: CardRadio[] = []
  @Input() selectedCard: string = ''
  @Input() disabled: boolean = false
  @Output() selectedCardChange: EventEmitter<string> = new EventEmitter()

  selectCard(key: string) {
    this.selectedCardChange.emit((this.selectedCard = key))
  }
}
