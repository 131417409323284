import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'

import { WirelessSensorDisplay } from '../../models/wireless-sensor-display.model'

@Component({
  selector: 'setup-info',
  templateUrl: './setup-info.component.html',
  styleUrls: ['./setup-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupInfoComponent {
  @Output() next = new EventEmitter()
  @Output() option = new EventEmitter()
  @Output() inputValue = new EventEmitter<string>()

  @Input() header: string
  @Input() description: string
  @Input() nextButtonText: string
  @Input() optionButtonText: string
  @Input() device: WirelessSensorDisplay
  @Input() topIcon: string
  @Input() bottomIcon: string

  input: string = ''

  handleInputValue(input: string) {
    this.inputValue.emit(input)
  }
}
