<ion-content padding>
  <div class="force-update_container">
    <div class="force-update_container-inner">
      <div class="force-update_header-container">
        <h1>Update BRNKL</h1>
        <div class="force-update_icon-container">
          <ion-icon name="md-information-circle"></ion-icon>
        </div>
      </div>
      <p class="force-update_warning-text">
        This version of BRNKL is no longer supported.
      </p>
      <p>Update the app for new features and security updates.</p>
      <a [href]="updateLink" class="force-update_update-button">Update</a>
      <a class="force-update_continue-button" (click)="skipUpdate()"> Skip </a>
    </div>
  </div>
</ion-content>
