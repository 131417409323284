<ion-header>
  <ion-toolbar class="toolbar-background">
    <ion-buttons slot="start">
      <ion-back-button class="toolbar-button"></ion-back-button>
    </ion-buttons>
    <ion-title class="toolbar-title">Alert Details</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <div class="alert-details-container">
    <div class="alert-details_date">{{alertDate}}</div>
    <div class="alert-details_title">{{alert.title}}</div>
    <div class="alert-details_desc" *ngIf="alert.desc">
      {{alert.desc}}
    </div>
    <div class="alert-details_desc" *ngIf="cameraName">
      {{cameraName}}
    </div>
  </div>
  <div *ngIf="alert.video" class="video-content">
    <div class="video-overlay" *ngIf="!isVideoUrlLoaded">
      <img [src]="thumbnail" class="thumbnail" />
      <ion-spinner name="crescent" color="primary"></ion-spinner>
    </div>
    <video
      [src]="videoUrl"
      autoplay="autoplay"
      muted
      playsinline
      controls
      (canplaythrough)="isVideoUrlLoaded=true"
    ></video>
  </div>
  <div class="alert-details_button-container">
    <ion-button size="large" (click)="showClearAlertDialog(alert)">
      Clear Alert
    </ion-button>
  </div>
</ion-content>
