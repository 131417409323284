import { Component } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'

import { AuthProvider } from '../../services/auth/auth.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'

import { Option } from '../../models/option.model'
import { Router } from '@angular/router'

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.page.html',
  styleUrls: ['./create-account.page.scss'],
})
export class CreateAccountPage {
  createAccountForm: FormGroup
  minPasswordLen: number = 8
  showPassword: boolean = false
  accountFields: Option<any>[] = [
    {
      label: 'First Name',
      type: 'text',
      formControlName: 'firstName',
    },
    {
      label: 'Last Name',
      type: 'text',
      formControlName: 'lastName',
    },
    {
      label: 'Phone Number',
      type: 'tel',
      formControlName: 'phone',
    },
    {
      label: 'Email',
      type: 'email',
      formControlName: 'email',
    },
    {
      label: 'Password',
      type: 'password',
      formControlName: 'password',
    },
    {
      label: 'Confirm Password',
      type: 'password',
      formControlName: 'confirmPassword',
    },
  ]
  constructor(
    private auth: AuthProvider,
    private formBuilder: FormBuilder,
    private helpers: HelpersProvider,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.createAccountForm = this.formBuilder.group(
      {
        firstName: ['', Validators.minLength(1)],
        lastName: ['', Validators.minLength(1)],
        phone: ['', Validators.minLength(1)],
        email: [
          '',
          [Validators.required, Validators.email, this.emailValidator],
        ],
        password: [
          '',
          [Validators.required, Validators.minLength(this.minPasswordLen)],
        ],
        confirmPassword: ['', Validators.required],
      },
      { validator: (group: FormGroup) => this.checkPasswords(group) }
    )
  }

  emailValidator(control: FormControl): { [key: string]: any } | null {
    const emailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = emailRegEx.test(control.value)
    return valid ? null : { invalidEmail: true }
  }

  checkPasswords(group: FormGroup): any {
    return group.controls.password.value ===
      group.controls.confirmPassword.value
      ? null
      : { notSame: true }
  }

  showPasswordTooShort(): boolean {
    return (
      this.createAccountForm.controls.password.dirty &&
      !this.createAccountForm.controls.password.valid
    )
  }

  showInvalidEmail(): boolean {
    return (
      this.createAccountForm.controls.email.dirty &&
      this.createAccountForm.controls.email.hasError('invalidEmail')
    )
  }

  showPasswordNoMatch(): boolean {
    return (
      this.createAccountForm.hasError('notSame') &&
      this.createAccountForm.controls.confirmPassword.dirty &&
      this.createAccountForm.controls.password.dirty
    )
  }

  handleShowPassword(): void {
    this.showPassword = !this.showPassword
    this.accountFields[4].type = this.showPassword ? 'text' : 'password'
    this.accountFields[5].type = this.showPassword ? 'text' : 'password'
  }

  async createAccount(): Promise<void> {
    this.helpers.startLoading('Creating Account...')
    const controls = this.createAccountForm.controls
    try {
      const { email, password, firstName, lastName, phone } = controls
      await this.auth.createAccount(
        email.value,
        password.value,
        firstName.value,
        lastName.value,
        phone.value
      )
      this.helpers.stopLoading()
    } catch (err) {
      this.helpers.stopLoading()
      const handlerFunction = () => this.router.navigate(['login'])
      this.helpers.showSimpleAlert(
        'Invalid e-mail address',
        'An account already exists for this e-mail address',
        'Login instead?',
        undefined,
        handlerFunction,
        true
      )
    }
  }
}
