<div>
  <form [formGroup]="searchDeviceForm" class="search-device_container">
    <search-bar
      class="search-device_input"
      [validInput]="validInput"
      [isLoading$]="isLoading$"
      inputPlaceholder="Enter BRNKL Serial Number"
      (ngModelChange)="deviceSearch.next($event)"
      formControlName="deviceId"
    >
    </search-bar>
    <ion-button
      *ngIf="showScanButton"
      class="search-device_scan-btn"
      size="small"
      [disabled]="isScannerOpen"
      (click)="scanSerialNum()"
    >
      Scan
      <ion-icon name="qr-code-outline" slot="end" color="black"></ion-icon>
    </ion-button>
  </form>
  <optional-text>{{ errorMessage | async }}</optional-text>
</div>
