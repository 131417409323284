<div class="setup-container_header">
  {{ header }}
</div>
<div class="setup-container_text setup-container_bottom-space">
  {{ description }}
</div>
<button
  *ngFor="let option of options"
  (click)="optionPressed(option)"
  [ngClass]="{
    'setup-container_btn-space': true,
    'setup-options_block': true,
    'setup-options_btn-selected': selectedOption.name === option.name,
    'setup-container_btn-option': selectedOption.name !== option.name
  }"
>
  {{ option.name }}
</button>
<div
  *ngIf="selectedOption.name !== ''"
  class="setup-container_btn-align setup-options_next-space"
>
  <button class="setup-container_btn-action" (click)="nextButtonPressed()">
    {{ selectedOption.nextButtonText }}
  </button>
</div>
