import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core'
import { ServicePlanDisplay } from '../../models/service-plan-display.model'
import { Subject, Subscription } from 'rxjs'
import { SubscriptionProvider } from '../../services/subscription/subscription.service'

@Component({
  selector: 'setup-payment',
  templateUrl: './setup-payment.component.html',
  styleUrls: ['./setup-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupPaymentComponent {
  @Output() next = new EventEmitter()
  @Output() couponCode = new EventEmitter<string>()

  @Input() plan: ServicePlanDisplay
  @Input() header: string
  @Input() iconName: string
  @Input() nextButtonText: string
  @Input() serviceType: string
  @Input() activeService?: boolean

  subscriptions: Subscription[] = []
  deviceSearch: Subject<string> = new Subject<string>()
  errorMessage: EventEmitter<string> = new EventEmitter<string>()
  isLoading$ = new EventEmitter<boolean>()
  description: string
  discount: number
  coupon: string
  activationFee: number = 35
  validInput: 'enter' | 'valid' | 'invalid' = 'enter'
  hasCoupon: boolean = false

  constructor(private subscription: SubscriptionProvider) {}

  addActivationFee(): boolean {
    return this.serviceType === 'satellite' && !this.activeService
  }

  getDescription(): string {
    if (this.addActivationFee()) {
      return 'Activation fee and monthly fees starting now.'
    } else {
      return `Fees for this subscription.`
    }
  }

  ngOnInit() {
    this.description = this.getDescription()
  }

  toggleCouponField() {
    this.hasCoupon = !this.hasCoupon
  }

  async validateCoupon(coupon: string) {
    await this.subscription.validateCoupon(coupon).then((res) => {
      if (res.success) {
        this.validInput = 'valid'
        this.discount = (this.plan.amount * res.discount) / 100
        this.couponCode.emit(coupon)
        this.isLoading$.emit(false)
      } else {
        this.validInput = 'invalid'
        this.discount = 0
        this.isLoading$.emit(false)
        setTimeout(() => (this.validInput = 'enter'), 3000)
      }
    })
  }
  setCouponCode(coupon: string) {
    this.coupon = coupon
  }

  handleCouponCode() {
    if (!this.coupon) {
      this.isLoading$.emit(false)
      this.validInput = 'enter'
      this.discount = 0
      return
    }
    this.isLoading$.emit(true)
    setTimeout(() => this.validateCoupon(this.coupon.trim()), 2000)
  }
}
