import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core'

import { ModalController, NavParams } from '@ionic/angular'

import { BaseSetupModalComponent } from '../../components/base-setup-modal/base-setup-modal.component'

import { SetupStep } from '../../models/setup-step.model'
import { SubscriptionState } from '../../models-shared/subscription-state.model'
import { DeviceProvider } from '../../services/device/device.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import { SubscriptionProvider } from '../../services/subscription/subscription.service'

import { ServicePlanDisplay } from '../../models/service-plan-display.model'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'

@Component({
  selector: 'change-service-modal',
  templateUrl: './change-service-modal.component.html',
  styleUrls: ['./change-service-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangeServiceModal extends BaseSetupModalComponent {
  @Output() planSelect = new EventEmitter()
  serviceType: 'cellular' | 'satellite'
  deviceId: string
  currentPlan: string
  currentPlanInfo: {}
  isMobileDevice: boolean
  newPlan: ServicePlanDisplay
  planOptions: ServicePlanDisplay[]
  _subscriptionState: SubscriptionState = {
    plans: [],
  }

  private refreshSettings: () => void

  changeService: SetupStep = {
    name: 'changeService',
    title: 'Change service',
    iconName: 'custom-change-plan',
    backButton: true,
    exitButton: false,
  }

  switchService: SetupStep = {
    name: 'switchService',
    title: 'Change service',
    iconName: 'custom-change-plan',
    backButton: false,
    exitButton: true,
  }

  planSelected: SetupStep = {
    name: 'planSelected',
    title: 'Change service',
    iconName: 'custom-change-plan',
    backButton: false,
    exitButton: true,
  }

  constructor(
    // private auth: AuthProvider,
    public viewCtrl: ModalController,
    private navParams: NavParams,
    private helpers: HelpersProvider,
    private device: DeviceProvider,
    private subscription: SubscriptionProvider,
    private environment: EnvironmentProvider,
    private iap: InAppBrowser
  ) {
    super(viewCtrl)
    this.isMobileDevice = this.environment.isNativeApp()
    this.deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    this.refreshSettings = this.navParams.get('refreshSettings')
    this.serviceType = this.navParams.get('serviceType')
    this.currentPlan = this.navParams.get('currentPlan')
    this.currentPlanInfo = {
      name: this.currentPlan,
      companyName: 'BRNKL',
      iconName: 'custom-subscribe',
    }
  }

  ngOnInit() {
    this.init()
    this.configure()
  }

  async init() {
    try {
      this._subscriptionState = await this.subscription.getState()
      //get plan options based on service type and current plan
      this.planOptions = this.getPlanOptions()
    } catch (err) {
      throw new Error('Error fetching BRNKL subscriptions')
    }
  }

  openChangeServiceModal = async () => {
    this.helpers.showModal(
      ChangeServiceModal,
      {
        refreshSettings: this.refreshSettings,
        serviceType: this.serviceType,
        currentPlan: this.currentPlan,
      },
      true,
      false,
      'setup-modal-container'
    )
    this.exit()
  }

  switchPlans(plan: ServicePlanDisplay) {
    this.planSelect.emit(plan)
    this.newPlan = plan
    this.pushStep(this.planSelected)
  }

  getPlanOptions() {
    try {
      const planOptions = this._subscriptionState.plans
        .filter(
          (plan) =>
            plan.nickname &&
            plan.nickname.toLowerCase().includes(this.serviceType)
        )
        .filter((plan) => plan.nickname && plan.nickname != this.currentPlan)
        .map(({ nickname: title, interval, amount, currency, id }) => {
          const rawAmount = amount / 100
          const prettyInterval = interval === 'month' ? 'Monthly' : 'Annually'
          const prettyCurrency = currency.toUpperCase()
          const iconName =
            this.serviceType === 'satellite'
              ? 'custom-satellite-service'
              : 'custom-subscribe'
          return {
            companyName: 'BRNKL',
            iconName,
            id,
            title,
            amount: rawAmount,
            interval: prettyInterval,
            currency: prettyCurrency,
          }
        })
      return planOptions
    } catch {
      return null
    }
  }

  launchWebApp() {
    if (this.isMobileDevice) {
      const url = this.environment.webUrl
      this.iap.create(url, '_system')
    }
  }

  onExitPressed(): void {
    this.exit()
  }

  async onBackPressed(): Promise<void> {
    if (this.currentStep.name === 'changeService') {
      this.onExitPressed()
    } else {
      this.back()
    }
  }

  private configure() {
    this.changeService.next = () => {
      this.pushStep(this.switchService)
    }

    this.planSelected.next = () => {}
    // Set first step
    this.pushStep(this.changeService)
  }
}
