import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CloudFunctionsProvider } from '../../services/cloud-functions/cloud-functions.service'
import { AppVersion } from '../../services/environment/environment.service'
import { MinimumAppVersion } from '../../models-shared/minimum-app-version.model'
import { BehaviorSubject, combineLatest, of, catchError } from 'rxjs'
import { map } from 'rxjs/operators'
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class AppUpdateProvider {
  currentVersion: string = AppVersion

  public mustUpdate$: Observable<boolean>
  private ignored$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  private versionIncompatible$: Observable<boolean>

  constructor(
    private cloudFunctionProvider: CloudFunctionsProvider,
    private platform: Platform
  ) {
    this.versionIncompatible$ = this.needsUpdate()

    this.mustUpdate$ = combineLatest(
      this.versionIncompatible$,
      this.ignored$,
      (versionIncompatible: boolean, ignored: boolean) =>
        versionIncompatible && !ignored
    )

    this.platform.resume.subscribe(async () => {
      this.ignored$.next(false)
      this.versionIncompatible$ = this.needsUpdate()
    })
  }

  public ignore(): void {
    this.ignored$.next(true)
  }

  private getMinimumVersion(): Observable<string> {
    const response = this.cloudFunctionProvider.get('minappversion')
    return response.pipe(
      map((minVersion: MinimumAppVersion) => minVersion.version)
    )
  }

  private needsUpdate(): Observable<boolean> {
    const minVersion = this.getMinimumVersion()
    //BRNKL version numbers follow npm semantic versioning guide
    //ie. they are of the form x.x.x, where x is a real number
    const semanticVersionRegExp = /^\d+\.\d+\.\d+$/

    const toVersionNumbers = (v: string): number[] => {
      return v.split('.').map((num: string) => parseInt(num))
    }

    return minVersion.pipe(
      map((minVersion: string) => {
        if (
          !(
            semanticVersionRegExp.test(minVersion) &&
            semanticVersionRegExp.test(this.currentVersion)
          )
        ) {
          return false
        }

        const [minMajor, minMinor, minHotfix] = toVersionNumbers(minVersion)
        const [currMajor, currMinor, currHotfix] = toVersionNumbers(
          this.currentVersion
        )

        let needsUpdate: boolean = false

        if (currMajor !== minMajor) {
          needsUpdate = currMajor < minMajor
        } else if (currMinor !== minMinor) {
          needsUpdate = currMinor < minMinor
        } else if (currHotfix !== minHotfix) {
          needsUpdate = currHotfix < minHotfix
        }

        return needsUpdate
      }),
      catchError((err) => {
        return of(false)
      })
    )
  }
}
