import { select } from '@angular-redux/store'
import { filter, take } from 'rxjs/operators'
import {
  Component,
  // EventEmitter,
  // Output,
  ViewEncapsulation,
} from '@angular/core'
import { ModalController, NavParams } from '@ionic/angular'
import { BaseSetupModalComponent } from '../../components/base-setup-modal/base-setup-modal.component'
import { SetupStep } from '../../models/setup-step.model'
import { Observable } from 'rxjs'
import { AppDevice } from 'app/models/app-device.model'
import { DeviceProvider } from 'app/services/device/device.service'
import { CloudFunctionsProvider } from 'app/services/cloud-functions/cloud-functions.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { DeviceSettings } from 'app/models-shared/device-settings.model'

@Component({
  selector: 'device-transfer-modal',
  templateUrl: './device-transfer-modal.component.html',
  styleUrls: ['./device-transfer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeviceTransferModal extends BaseSetupModalComponent {
  @select('currentDevice') currentDevice$: Observable<AppDevice>

  deviceId: string
  deviceName: string
  deviceSettings: DeviceSettings
  newDeviceId: string = ''
  validate: boolean
  validateChecked: boolean
  newDeviceExists: boolean = false

  newDevice: SetupStep = {
    name: 'newDevice',
    title: '',
    iconName: '',
    backButton: false,
    exitButton: true,
  }

  review: SetupStep = {
    name: 'review',
    title: '',
    iconName: '',
    backButton: true,
    exitButton: false,
  }

  confirmation: SetupStep = {
    name: 'confirmation',
    title: ``,
    iconName: '',
    backButton: false,
    exitButton: false,
  }

  constructor(
    public params: NavParams,
    private device: DeviceProvider,
    private viewCtrl: ModalController,
    private cloudFunctions: CloudFunctionsProvider,
    private helpers: HelpersProvider
  ) {
    super(viewCtrl)
    // const uid = this.navParams.get('uid')
    this.deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    this.pushStep(this.newDevice)
  }

  ngOnInit() {
    const paramDeviceId = this.params.get('deviceId')
    if (paramDeviceId) {
      this.newDeviceId = paramDeviceId
    }
    this.currentDevice$
      .pipe(
        filter((device) => device != null),
        filter((device) => device.settings != null),
        take(1)
      )
      .subscribe((device) => {
        this.deviceName = device.settings.deviceName
      })
  }

  dismiss(): Promise<any> {
    return this.viewCtrl.dismiss()
  }

  onExitPressed(): void {
    this.exit()
  }

  onBackPressed(): void {
    this.back()
  }

  onValidDeviceId(deviceId: string): void {
    if (!deviceId.length) {
      this.deviceId = ''
      this.newDeviceExists = false
      return
    } else {
      this.newDeviceId = deviceId
      this.newDeviceExists = true
      console.log(this.newDeviceId, this.newDeviceExists)
    }
  }

  async deviceTransfer() {
    const oldDeviceId = this.device.currentBRNKLandMateId$.value.deviceId
    console.log('old device:', oldDeviceId)
    console.log('new device:', this.newDeviceId)
    this.device.setCurrentDeviceId(null)
    await this.cloudFunctions
      .authedPut<any>(`link/${this.newDeviceId}`, {
        oldDeviceId,
      })
      .then((res) => {
        console.log(res)
        if (res.success) {
          // console.log('device transfer success', this.newDeviceId)
          // this.navData.set('settings-tab', 'vessel')
          //this.router.navigate([''])
          this.device.setCurrentDeviceId(this.newDeviceId)
          this.pushStep(this.confirmation)
        } else {
          this.helpers.showDangerToast('Failed to transfer device settings.')
        }
      })
  }
}
