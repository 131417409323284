import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { convertLatDecimalToDegrees } from '../../util'

@Pipe({
  name: 'convertLatitudeToDegrees'
})
@Injectable()
export class ConvertLatitudePipe implements PipeTransform {
  constructor() {}

  public transform(value: string, ...args): string {
    if (value && args[0] === 'Deg Min Sec') {
      return convertLatDecimalToDegrees(value)
    } else {
      return value
    }
  }
}
