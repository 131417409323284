import { Component, ViewEncapsulation } from '@angular/core'
import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { DeviceProvider } from '../../services/device/device.service'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import { delay } from '../../util/util'

@Component({
  selector: 'bluetooth-button',
  templateUrl: './bluetooth-button.component.html',
  styleUrls: ['./bluetooth-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BluetoothButtonComponent {
  constructor(
    private bluetoothLEProvider: BluetoothLEProvider,
    private helpers: HelpersProvider,
    private device: DeviceProvider,
    private environmentProvider: EnvironmentProvider
  ) {}

  get isBLEConnected(): boolean {
    return this.bluetoothLEProvider.connected.value
  }

  get isNativeApp(): boolean {
    return this.environmentProvider.isNativeApp()
  }

  get isMateLinked(): boolean {
    return this.device.currentBRNKLandMateId$.value.mateId != null
  }

  async connectBLE(): Promise<void> {
    this.helpers.startLoading('Connecting')

    try {
      await this.bluetoothLEProvider.connectToMate(
        this.device.currentBRNKLandMateId$.value.mateId
      )
      this.helpers.stopLoading()
      this.helpers.showToast('Connected')
    } catch (e) {
      this.helpers.stopLoading()
      this.helpers.showDangerToast(e.message)
    }
  }
  async disconnectBLE(): Promise<void> {
    this.helpers.startLoading('Disconnecting')
    // Wait 1 second so user can read the message
    await delay(1000)

    try {
      await this.bluetoothLEProvider.disconnectFromMate(false)
      this.helpers.stopLoading()
      this.helpers.showToast('Disconnected')
    } catch (e) {
      this.helpers.stopLoading()
      this.helpers.showDangerToast(e.message)
    }
  }
}
