<ion-list *ngIf="pageReady" mode="md">
  <ng-container *ngIf="settings?.cameraConfig">
    <ion-list-header class="settings_list-header">
      <ion-label class="text-label">Camera</ion-label>
    </ion-list-header>
    <ion-item *ngFor="let n of range(1, 4)">
      <ion-label class="text-label">Daily Photo (hh)</ion-label>
      <ion-select
        [(ngModel)]="settings.cameraConfig.cam1.hourSchedule[n - 1]"
        (ngModelChange)="
          settings.cameraConfig.cam1.hourSchedule[n - 1] = parseFloat($event)
        "
      >
        <ng-container *ngFor="let hour of range(0, 24)">
          <ion-select-option [value]="+hour">{{ hour }}:00</ion-select-option>
        </ng-container>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label class="text-label" *ngIf="!settings.cameraConfig.cam2"
        >Rotation (deg)</ion-label
      >
      <ion-label class="text-label" *ngIf="settings.cameraConfig.cam2"
        >Camera 1 Rotation (deg)</ion-label
      >
      <ion-select [(ngModel)]="settings.cameraConfig.cam1.rotation">
        <ng-container *ngFor="let unit of rotationOptions">
          <ion-select-option [value]="unit">{{ unit }}</ion-select-option>
        </ng-container>
      </ion-select>
    </ion-item>
    <ion-item *ngIf="settings.cameraConfig.cam2">
      <ion-label class="text-label">Camera 2 Rotation (deg)</ion-label>
      <ion-select [(ngModel)]="settings.cameraConfig.cam2.rotation">
        <ng-container *ngFor="let unit of rotationOptions">
          <ion-select-option value="{{ unit }}">{{ unit }}</ion-select-option>
        </ng-container>
      </ion-select>
    </ion-item>
  </ng-container>
  <ng-container *ngIf="settings?.mapConfig">
    <ion-list-header class="settings_list-header">
      <ion-label class="text-label">Map</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-label class="text-label">Max GPS Points</ion-label>
      <ion-input
        class="ion-text-right"
        type="number"
        [ngModel]="settings.mapConfig.maxGpsPoints"
        (ngModelChange)="settings.mapConfig.maxGpsPoints = parseFloat($event)"
        max="1500"
        min="1"
        size="10px"
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="settings?.mapConfig">
      <ion-label class="text-label">GPS History Start</ion-label>
      <ion-datetime
        displayFormat="H:m MM/DD/YYYY"
        [(ngModel)]="settings.mapConfig.lastGpsResetDatetimeIso"
      >
      </ion-datetime>
    </ion-item>
    <ion-item>
      <ion-label class="text-label">GPS Coordinates</ion-label>
      <ion-select [(ngModel)]="settings.mapConfig.gpsUnits">
        <ng-container *ngFor="let unit of unitOptions[sensorTypes.GPS]">
          <ion-select-option value="{{ unit }}">{{ unit }}</ion-select-option>
        </ng-container>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label class="text-label">Show Location Tracks</ion-label>
      <ion-toggle
        [(ngModel)]="settings.mapConfig.showTracks"
        name="show-location-tracks"
      >
      </ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label class="text-label">Preferred Unit</ion-label>
      <ion-select [(ngModel)]="settings.anchorConfig.preferredUnit">
        <ng-container *ngFor="let unit of unitOptions[sensorTypes.Geofence]">
          <ion-select-option value="{{ unit }}">{{ unit }}</ion-select-option>
        </ng-container>
      </ion-select>
    </ion-item>
  </ng-container>

  <ng-container *ngFor="let sensor of sensorConfigList">
    <form #sensorForm="ngForm">
      <ion-list-header class="settings_list-header" mode="md">
        <ion-label class="text-label"
          >{{ sensor.name }}
          <span *ngIf="sensor.slotName">({{ sensor.slotName }})</span>
        </ion-label>
        <ion-icon
          *ngIf="isWirelessSensor(sensor.type)"
          class="icon-close"
          name="close"
          (click)="removeWirelessSensor.emit(sensor)"
          item-right
        ></ion-icon>
      </ion-list-header>
      <ion-item
        *ngIf="sensor.analogType !== undefined && sensor.key !== 'analog1'"
      >
        <ion-label class="text-label">Device Type</ion-label>
        <ion-select
          [(ngModel)]="sensor.analogType"
          name="n-analogType"
          (ionChange)="switchAnalogType(sensor.key, $event.target.value)"
        >
          <ng-container
            *ngFor="
              let analogSensorName of analogSensorNames;
              index as analogSensorType
            "
          >
            <ion-select-option [value]="analogSensorType">{{
              analogSensorName
            }}</ion-select-option>
          </ng-container>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="hasShowOnGridButtonButton(sensor.type)">
        <ion-label class="text-label">Monitor</ion-label>
        <ion-toggle
          [(ngModel)]="sensor.showOnGrid"
          name="show-on-grid"
        ></ion-toggle>
      </ion-item>
      <ion-item *ngIf="hasAlertsEnabledButton(sensor.type)">
        <ion-label class="text-label">Alert</ion-label>
        <ion-toggle [(ngModel)]="sensor.enabled" name="enabled"></ion-toggle>
      </ion-item>
      <ion-item>
        <ion-label class="text-label">Name</ion-label>
        <ion-input
          class="ion-text-right"
          type="text"
          [(ngModel)]="sensor.name"
          name="sensor-name"
        ></ion-input>
      </ion-item>
      <ion-item
        *ngIf="sensor.type === sensorTypes.WirelessKeypad"
        (click)="changeWirelessKeypadPinPrompt()"
        detail="false"
      >
        Change Pin
        <ion-icon slot="end" name="chevron-forward"></ion-icon>
      </ion-item>
      <ion-item *ngIf="unitOptions[sensor.type]">
        <ion-label class="text-label">Preferred Unit</ion-label>
        <ion-select [(ngModel)]="sensor.preferredUnit" name="preferred-unit">
          <ng-container *ngFor="let unit of unitOptions[sensor.type]">
            <ion-select-option value="{{ unit }}">{{ unit }}</ion-select-option>
          </ng-container>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="isValidValue(sensor.nDecimals)">
        <ion-label class="text-label">Decimal Places</ion-label>
        <ion-select [(ngModel)]="sensor.nDecimals" name="n-decimals">
          <ng-container *ngFor="let n of decimalOptions">
            <ion-select-option [value]="+n">{{ n }}</ion-select-option>
          </ng-container>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="isValidValue(sensor.offset)">
        <ion-label class="text-label"
          >{{
            sensor?.analogType === AnalogSensorTypes.Battery
              ? 'Offset voltage'
              : 'Offset'
          }}
          ({{ sensor?.preferredUnit || sensor?.unit }})</ion-label
        >
        <ion-input
          class="ion-text-right"
          type="number"
          name="offset"
          max="10"
          min="-10"
          [ngModel]="sensor.offset"
          (ngModelChange)="sensor.offset = parseFloat($event)"
          [pattern]="floatPattern"
          required
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="isValidValue(sensor.triggerValue)">
        <ion-label class="text-label"
          >Trigger Value ({{
            sensor?.preferredUnit || sensor?.unit
          }})</ion-label
        >
        <ion-input
          type="number"
          required
          name="trigger-value"
          [ngModel]="sensor.triggerValue"
          (ngModelChange)="sensor.triggerValue = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="isValidValue(sensor.highValue)">
        <ion-label class="text-label"
          >{{
            sensor?.analogType === AnalogSensorTypes.Battery
              ? 'High voltage
          alert'
              : sensor?.type === sensorTypes.Temp ||
                sensor?.type === sensorTypes.WirelessTemp
              ? 'Maximum Temperature'
              : 'Maximum Value'
          }}
          ({{ sensor?.preferredUnit || sensor?.unit }})</ion-label
        >
        <ion-input
          class="ion-text-right"
          type="number"
          required
          name="high-value"
          [ngModel]="sensor.highValue"
          (ngModelChange)="sensor.highValue = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="isValidValue(sensor.lowValue)">
        <ion-label class="text-label"
          >{{
            sensor?.analogType === AnalogSensorTypes.Battery
              ? 'Low voltage
          alert'
              : sensor?.type === sensorTypes.Temp ||
                sensor?.type === sensorTypes.WirelessTemp
              ? 'Minimum Temperature'
              : 'Minimum Value'
          }}
          ({{ sensor?.preferredUnit || sensor?.unit }})</ion-label
        >
        <ion-input
          class="ion-text-right"
          type="number"
          name="low-value"
          [ngModel]="sensor.lowValue"
          (ngModelChange)="sensor.lowValue = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="sensor.longPeriodAlert !== undefined">
        <ion-label class="text-label">Long Period Alert</ion-label>
        <ion-input
          class="ion-text-right"
          type="number"
          name="long-period-alert"
          [ngModel]="sensor.longPeriodAlert"
          (ngModelChange)="sensor.longPeriodAlert = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
        <ion-select
          [(ngModel)]="sensor.longPeriodAlertUnit"
          name="long-period-time-unit"
        >
          <ng-container *ngFor="let unit of bilgeTimeUnitOptions">
            <ion-select-option value="{{ unit }}">{{ unit }}</ion-select-option>
          </ng-container>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="sensor.longPeriodAlertLimit !== undefined">
        <ion-label class="text-label">Long Period Alert Limit</ion-label>
        <ion-input
          class="ion-text-right"
          type="number"
          name="long-period-alert-limit"
          [ngModel]="sensor.longPeriodAlertLimit"
          (ngModelChange)="sensor.longPeriodAlertLimit = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="sensor.shortPeriodAlert !== undefined">
        <ion-label class="text-label">Short Period Alert</ion-label>
        <ion-input
          class="ion-text-right"
          type="number"
          name="short-period-alert"
          [ngModel]="sensor.shortPeriodAlert"
          (ngModelChange)="sensor.shortPeriodAlert = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
        <ion-select
          [(ngModel)]="sensor.shortPeriodAlertUnit"
          name="short-period-time-unit"
        >
          <ng-container *ngFor="let unit of bilgeTimeUnitOptions">
            <ion-select-option value="{{ unit }}">{{ unit }}</ion-select-option>
          </ng-container>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="sensor.shortPeriodAlertLimit !== undefined">
        <ion-label class="text-label">Short Period Alert Limit</ion-label>
        <ion-input
          class="ion-text-right"
          type="number"
          name="short-period-alert-limit"
          [ngModel]="sensor.shortPeriodAlertLimit"
          (ngModelChange)="sensor.shortPeriodAlertLimit = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="sensor.onTimeLimit !== undefined">
        <ion-label class="text-label">Bilge On Time Limit</ion-label>
        <ion-input
          class="ion-text-right"
          type="number"
          name="on-time-limit"
          [ngModel]="sensor.onTimeLimit"
          (ngModelChange)="sensor.onTimeLimit = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
        <ion-select
          [(ngModel)]="sensor.onTimeLimitUnit"
          name="on-time-limit-unit"
        >
          <ng-container *ngFor="let unit of bilgeTimeUnitOptions">
            <ion-select-option value="{{ unit }}">{{ unit }}</ion-select-option>
          </ng-container>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="sensor.alertsDuration !== undefined">
        <ion-label class="text-label">Duration Between Alerts</ion-label>
        <ion-input
          class="ion-text-right"
          type="number"
          name="alerts-duration"
          [ngModel]="sensor.alertsDuration"
          (ngModelChange)="sensor.alertsDuration = parseFloat($event)"
          [pattern]="floatPattern"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="isValidValue(sensor.highName)">
        <ion-label class="text-label">High Name</ion-label>
        <ion-input
          class="ion-text-right"
          type="text"
          name="high-name"
          [(ngModel)]="sensor.highName"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="isValidValue(sensor.lowName)">
        <ion-label class="text-label">Low Name</ion-label>
        <ion-input
          class="ion-text-right"
          type="text"
          name="low-name"
          [(ngModel)]="sensor.lowName"
        ></ion-input>
      </ion-item>
      <ion-item
        *ngIf="
          isValidValue(sensor.alertOnHigh) &&
          sensor.analogType != AnalogSensorTypes.Ignition &&
          !isWirelessSensor(sensor.type)
        "
      >
        <ion-label class="text-label">Alert On</ion-label>
        <ion-select
          [ngModel]="sensor.alertOnHigh"
          (ngModelChange)="sensor.alertOnHigh = $event"
          name="alert-on"
        >
          <ion-select-option [value]="true">High</ion-select-option>
          <ion-select-option [value]="false">Low</ion-select-option>
        </ion-select>
      </ion-item>
      <ng-container
        *ngIf="
          isValidValue(sensor.analogType) &&
          sensor.analogType === AnalogSensorTypes.Ignition
        "
      >
        <ion-item>
          <ion-label class="text-label">Current Engine Hours</ion-label>
          <ion-input
            class="ion-text-right"
            type="number"
            name="engine-hours"
            [ngModel]="sensor.engineHours"
            (ngModelChange)="sensor.engineHours = parseFloat($event)"
            [pattern]="floatPattern"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label class="text-label">Alert On</ion-label>
          <ion-select
            [ngModel]="sensor.alertOnHigh"
            (ngModelChange)="sensor.alertOnHigh = $event"
            name="alert-on"
          >
            <ion-select-option [value]="true">Ignition On</ion-select-option>
            <ion-select-option [value]="false">Ignition Off</ion-select-option>
          </ion-select>
        </ion-item>
      </ng-container>
      <ng-container
        *ngIf="
          sensor.type === sensorTypes.Digital ||
          sensor.type === sensorTypes.Motion ||
          sensor.type === sensorTypes.BilgeActivity
        "
      >
        <ion-item>
          <ion-label class="text-label">Delay Time (s)</ion-label>
          <ion-input
            class="ion-text-right"
            type="number"
            name="delay-time"
            [ngModel]="sensor.debounceTimeMs ? sensor.debounceTimeMs / 1000 : 2"
            (ngModelChange)="sensor.debounceTimeMs = parseFloat($event) * 1000"
            [pattern]="floatPattern"
            max="5"
            min="0.04"
            step="0.01"
          ></ion-input>
        </ion-item>
        <ion-item *ngIf="sensor.type !== sensorTypes.BilgeActivity">
          <ion-label class="text-label">Photo on Alert</ion-label>
          <ion-toggle
            [(ngModel)]="sensor.takePhotoOnAlert"
            name="photo-on-alert"
          >
          </ion-toggle>
        </ion-item>
      </ng-container>
      <ion-item *ngIf="showOutputOnAlert(sensor.type)">
        <ion-label class="text-label">Output on Alert</ion-label>
        <ion-select
          [(ngModel)]="sensor.outputKeys"
          name="output-keys"
          multiple="true"
        >
          <ion-select-option value="digout1" selected="true"
            >Output 1</ion-select-option
          >
          <ion-select-option value="digout2">Output 2</ion-select-option>
        </ion-select>
      </ion-item>
      <!-- <ng-container *ngIf="sensor?.wirelessConfig?.hasTempSensor">
        <ion-item>
          <ion-label class="text-label"
            >Maximum Temperature ({{ userSettings.value?.preferredTempUnit  }})</ion-label
          >
          <ion-input
            type="number"
            required
            name="high-temp-value"
            [ngModel]="sensor.tempConfig.highValue"
            (ngModelChange)="sensor.tempConfig.highValue = parseFloat($event)"
            [pattern]="floatPattern"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label class="text-label"
            >Minimum Temperature ({{ sensor?.tempConfig?.preferredUnit ||
            sensor?.tempConfig?.unit }})</ion-label
          >
          <ion-input
            type="number"
            name="low-temp-value"
            [ngModel]="sensor.tempConfig.lowValue"
            (ngModelChange)="sensor.tempConfig.lowValue = parseFloat($event)"
            [pattern]="floatPattern"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label class="text-label">Preferred Unit</ion-label>
          <ion-select
            [(ngModel)]="sensor.tempConfig.preferredUnit"
            name="preferred-temp-unit"
          >
            <ng-container *ngFor="let unit of unitOptions[sensorTypes.Temp]">
              <ion-select-option value="{{ unit }}">{{ unit }}</ion-select-option>
            </ng-container>
          </ion-select>
        </ion-item>
      </ng-container> -->
    </form>
  </ng-container>
  <ng-container *ngIf="settings.sensorConfig?.pitchangle">
    <ion-list-header class="settings_list-header">
      <ion-label class="text-label">Pitch and Heel Angle</ion-label>
    </ion-list-header>
    <ion-item (click)="calibrate()">
      Calibrate
      <ion-icon slot="end" name="locate"></ion-icon>
    </ion-item>
  </ng-container>
  <ng-container *ngIf="wirelessKeypadExists()">
    <ion-list-header class="settings_list-header">
      <ion-label class="text-label">Keypad Global Settings</ion-label>
    </ion-list-header>
    <ion-item (click)="checkBluetoothConnection()">
      <ion-label class="text-label">Entry/Exit Delay</ion-label>
      <ion-select
        [(ngModel)]="settings.entryExitDelay"
        name="entry-exit-delay"
        [disabled]="disableEntryExitDelayPrompt()"
      >
        <ng-container *ngFor="let delay of delayOptions">
          <ion-select-option value="{{ delay }}"
            >{{ delay }}s</ion-select-option
          >
        </ng-container>
      </ion-select>
    </ion-item>
  </ng-container>
</ion-list>
