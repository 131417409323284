import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { Observable } from 'rxjs'
import { AppDevice } from '../../models/app-device.model'
import { SideEffectsProvider } from '../../services/side-effects/side-effects.service'
import { AgmMap } from '@agm/core'
import { offlineTimeout } from 'app/util'

@Component({
  selector: 'device-grid',
  templateUrl: './device-grid.component.html',
  styleUrls: ['./device-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeviceGridComponent {
  @Input() allDevices$: Observable<AppDevice>
  @Input() expanded: boolean = false
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter()
  @Input() editOpen: boolean
  @Output() unlinkDevice: EventEmitter<string> = new EventEmitter()

  // check if lastUpdated timestamp (in seconds) was 3 or more hours ago
  offlineTimer: number = offlineTimeout

  @ViewChild('map') map: AgmMap

  constructor(private sideEffects: SideEffectsProvider) {}

  toggleOpen(): void {
    this.expanded = !this.expanded
    this.expandedChange.emit(this.expanded)
  }

  unlink(deviceId: string) {
    this.unlinkDevice.emit(deviceId)
  }

  pushTabsPage(device: AppDevice): void {
    this.sideEffects.setCurrentDeviceId(device)
  }

  trackById(index: number, device: AppDevice): string {
    return device.deviceId
  }
}
