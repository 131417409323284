import { Component } from '@angular/core'
import { ModalController, NavParams } from '@ionic/angular'

import { Insomnia } from '@ionic-native/insomnia/ngx'
import { File, Entry, FileWriter } from '@ionic-native/file/ngx'

import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { DeviceProvider } from '../../services/device/device.service'

import { SetupStep } from '../../models/setup-step.model'
import { UpdateMateModalParams } from '../../models/update-mate-modal-params.model'

import { BaseSetupModalComponent } from '../../components/base-setup-modal/base-setup-modal.component'

import { delay } from '../../util/util'
import { HTTP } from '@ionic-native/http/ngx'
import { EnvironmentProvider } from 'app/services/environment/environment.service'

@Component({
  selector: 'update-mate-modal',
  templateUrl: './update-mate-modal.component.html',
  styleUrls: ['./update-mate-modal.component.scss'],
})
export class UpdateMateModalComponent extends BaseSetupModalComponent {
  private title = 'Update Mate'
  start: SetupStep = {
    name: 'start',
    title: this.title,
    iconName: 'custom-update',
    backButton: false,
    exitButton: true,
  }

  warning: SetupStep = {
    name: 'warning',
    title: this.title,
    iconName: 'custom-update',
    backButton: false,
    exitButton: true,
  }

  updateProgress: SetupStep = {
    name: 'updateProgress',
    title: this.title,
    iconName: 'custom-update',
    backButton: false,
    exitButton: false,
  }

  updateProgressText: string | null
  updateProgressPercent: number | null
  updateComplete: boolean = false

  findingText: string = 'Finding Mate'
  downloadingText: string = 'Downloading'
  updatingText: string = 'Updating'
  finalizingText: string = 'Finishing'

  private modalParams: UpdateMateModalParams

  constructor(
    viewCtrl: ModalController,
    private bluetoothLEProvider: BluetoothLEProvider,
    private helpers: HelpersProvider,
    private file: File,
    private device: DeviceProvider,
    private insomnia: Insomnia,
    private http: HTTP,
    private environment: EnvironmentProvider,
    params: NavParams
  ) {
    super(viewCtrl)
    this.modalParams = params.get('data')
    this.setProgress(0, this.findingText)
    this.configure()
  }

  ngOnInit() {
    this.insomnia.keepAwake()
  }

  ngOnDestroy() {
    this.insomnia.allowSleepAgain()
    this.modalParams.updateMateModalClosed()
  }

  async findMate(): Promise<string> {
    const address = await this.bluetoothLEProvider.prepareForDFU(
      this.device.currentBRNKLandMateId$.value.mateId
    )
    return address
  }

  async downloadFirmware(url: string): Promise<Entry> {
    if (this.environment.isAndroidApp()) {
      try {
        // Download the file as a blob
        const blobResponse = await this.http.downloadFile(
          url,
          {},
          {},
          `${this.file.cacheDirectory}update.zip`
        )

        if (blobResponse) {
          const updateFile = await this.file.createFile(
            this.file.cacheDirectory,
            'update.zip',
            true
          )

          const fileWriter = await new Promise<FileWriter>(
            (resolve, reject) => {
              updateFile.createWriter(
                (writer) => resolve(writer),
                (err) => reject(err)
              )
            }
          )

          fileWriter.write(blobResponse)

          return updateFile
        } else {
          console.log('Failing here: ', JSON.stringify(blobResponse))
          throw new Error('Failed to download firmware.')
        }
      } catch (error) {
        console.error('Download error:', error)
        throw new Error(
          `Failed to download firmware. Error: ${
            error.message || 'Unknown error'
          }`
        )
      }
    } else {
      const response = await window.fetch(url, { method: 'GET' })

      if (!response.ok) {
        throw new Error(
          `Failed to download firmware, server returned error: ${response.statusText}`
        )
      }
      const updateFile = await this.file.createFile(
        this.file.cacheDirectory,
        'update.zip',
        true
      )

      const fileWriter = await new Promise<FileWriter>((resolve, reject) => {
        updateFile.createWriter(
          (writer) => resolve(writer),
          (err) => reject(err)
        )
      })

      const arrayBuffer = await response.arrayBuffer()
      fileWriter.write(arrayBuffer)

      return updateFile
    }
  }

  async performUpdate(firmware: Entry, mateAddress: string) {
    const initialProgress = this.updateProgressPercent
    const targetProgress = 75
    const multiplier = (targetProgress - initialProgress) / 100

    const progressHook = (percentCompleteDFU: number) => {
      this.setProgress(initialProgress + percentCompleteDFU * multiplier)
    }

    console.log('Performing DFU')

    try {
      await this.bluetoothLEProvider.performDFU(
        firmware.toURL(),
        mateAddress,
        progressHook
      )
    } catch (err) {
      console.log('Error: ', err)
    }
  }

  async waitAndProgress(time: number, progress: number) {
    if (time <= 0 || progress <= 0) {
      throw new Error('Time and progress must be zero or above')
    }

    const tickLength = 500
    const numberOfTicks = time / tickLength
    const progressPerTick = progress / numberOfTicks

    for (let i = 0; i < numberOfTicks; i++) {
      this.setProgress(this.updateProgressPercent + progressPerTick)
      await delay(tickLength)
    }
  }

  async connectToUpdatedMate(initialWaitTime: number): Promise<void> {
    if (initialWaitTime <= 0) {
      throw new Error('Initial wait time must be greater than 0')
    }

    const maxTries = 3
    let currentTries = 0
    let waitTime = initialWaitTime

    while (currentTries < maxTries) {
      await this.waitAndProgress(waitTime, 10)

      try {
        await this.bluetoothLEProvider.connectToMate(
          this.device.currentBRNKLandMateId$.value.mateId
        )
        this.updateProgressPercent = 100
        return
      } catch {
        currentTries++
        waitTime *= 2
      }
    }

    throw new Error('Could not find the updated Mate')
  }

  update = async () => {
    // Step 1: Find Mate
    this.setProgress(10, this.findingText)

    let mateAddress: string
    try {
      mateAddress = await this.findMate()
    } catch (err) {
      this.helpers.showInfiniteDangerToast('Can’t find Mate. Try again.')
      this.reset()
      return
    }

    // Step 2: Download Firmware
    this.setProgress(25, this.downloadingText)

    let firmwareEntry: Entry
    try {
      const { url } = this.modalParams.updateInfo
      firmwareEntry = await this.downloadFirmware(url)
    } catch (err) {
      this.helpers.showInfiniteDangerToast(`Can’t download update. Try again.`)
      this.reset()
      return
    }

    const removeFile = async () => {
      try {
        await new Promise<void>((resolve, reject) =>
          firmwareEntry.remove(resolve, reject)
        )
      } catch (err) {
        this.helpers.showInfiniteDangerToast('Can’t clean up update. Try again')
        this.reset()
      }
    }

    // Step 3: Update Mate
    this.setProgress(40, this.updatingText)
    try {
      const success = await this.modalParams.notifyStartMateUpdate()
      if (!success) {
        throw new Error('Request was not successful')
      }
    } catch (err) {
      this.helpers.showInfiniteDangerToast('Can’t start update. Try again')
      this.reset()
      return
    }

    this.setProgress(45)
    try {
      console.log('Performing update')
      await this.performUpdate(firmwareEntry, mateAddress)
      await removeFile()
    } catch (err) {
      let message = err
      if (err.errorMessage) {
        message = err.errorMessage
      }

      this.helpers.showInfiniteDangerToast(
        `Can’t update. Try again. (Error: ${message})`
      )

      await removeFile()
      this.reset()
      return
    }

    // Step 4: Reconnect to Mate
    this.setProgress(this.updateProgressPercent, this.finalizingText)
    try {
      await this.connectToUpdatedMate(10000)
      await delay(500)
    } catch (err) {
      this.reset()
      this.helpers.showInfiniteDangerToast(
        'Can’t reconnect to Mate. Try again.'
      )
      return
    }

    try {
      const success = await this.modalParams.reportMateUpdate(
        this.modalParams.updateInfo.version
      )
      if (!success) {
        throw new Error('Request was not successful')
      }
    } catch (err) {
      this.helpers.showInfiniteDangerToast(
        `Can’t record update. Contact BRNKL support at ahoy@brnkl.io or 1-877-552-7655.`
      )
      this.reset()
      return
    }

    try {
      await this.modalParams.checkForMateUpdate(true)
    } catch (err) {
      // tslint:disable-next-line
      console.log(`checkForMateUpdate error ${err.message}`)
    }

    this.updateComplete = true
    this.setProgress(null, null)
  }

  configure() {
    this.start.next = () => {
      this.pushStep(this.warning)
    }

    this.warning.next = async () => {
      this.pushStep(this.updateProgress)
      await this.update()
    }

    this.updateProgress.next = () => {
      this.exit()
    }

    this.pushStep(this.start)
  }

  private setProgress(percent: number, text?: string) {
    if (text !== undefined) {
      this.updateProgressText = text
    }

    this.updateProgressPercent = percent
  }

  get updateProgressHeader() {
    return !this.updateComplete ? 'Updating' : 'Updated'
  }

  get updateProgressDescription() {
    return !this.updateComplete
      ? 'Keep the BRNKL app open on this device.'
      : `Mate updated to version ${this.modalParams.updateInfo.version}.`
  }

  get updateProgressIcon() {
    return !this.updateComplete ? 'custom-update' : 'md-checkmark'
  }

  get updateProgressButtonText() {
    return !this.updateComplete ? '' : 'Done'
  }
}
