/**
 * Model errors that want the app to report back to us.
 */
export class AppError extends Error {
  public severity: AppErrorSeverity
  constructor(
    public code: AppErrorCode,
    public uid: string = 'n/a',
    public err?: Error,
    public runTime?: number
  ) {
    super(`App: (${uid}): ${code} - ${lookupMessage(code, err, runTime)}`)
    this.severity = lookupPriority(code)
  }
}

export enum AppErrorCode {
  UNCAUGHT_FAILURE = 0,
  STRIPE_FAILURE,
}

export enum AppErrorSeverity {
  WARNING = 0,
  ERROR,
  FATAL,
}
const s = AppErrorSeverity
const e = AppErrorCode

export const lookupPriority = (code: AppErrorCode) => {
  switch (code) {
    case e.STRIPE_FAILURE:
    case e.UNCAUGHT_FAILURE:
    default:
      return s.FATAL
  }
}

export const lookupMessage = (
  code: AppErrorCode,
  err: Error,
  runTime: number
) => {
  switch (code) {
    case e.STRIPE_FAILURE:
      return 'Failed to inject Stripe.js script'
    case e.UNCAUGHT_FAILURE:
    default:
      return 'UNCAUGHT FAILURE'
  }
}
