<ion-content class="device-transfer-content" [ngSwitch]="currentStep.name">
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <div *ngSwitchCase="'newDevice'" class="device-transfer-search">
      <div class="device-transfer-title">
        Transfer settings to new BRNKL device
      </div>
      <div class="device-transfer-label">
        Enter or scan the new BRNKL device serial number.
      </div>
      <search-device
        [newDeviceId]="paramDeviceId"
        [deviceTransfer]="true"
        (deviceId$)="onValidDeviceId($event)"
      >
      </search-device>
      <ion-button
        *ngIf="!newDeviceExists"
        disabled
        class="action-button"
        color="light"
        slot="end"
        >Continue</ion-button
      >
      <ion-button
        *ngIf="newDeviceExists"
        class="action-button"
        color="light"
        slot="end"
        (click)="pushStep(review)"
        >Continue</ion-button
      >
    </div>
    <!-- OVERVIEW OF WHAT THE TRANSFER WILL DO -->
    <div *ngSwitchCase="'review'">
      <div class="device-transfer-title">
        Transfer settings to new BRNKL device
      </div>
      <div class="device-transfer-label">
        Transferring {{ deviceName }} settings will do the following:
      </div>
      <ion-list class="transfer-list" lines="none">
        <ion-item>
          <ion-icon slot="start" name="checkmark-circle-outline"></ion-icon>
          Transfer settings, preferences and passwords to the new device.
        </ion-item>
        <ion-item>
          <ion-icon slot="start" name="close-circle"></ion-icon>
          Remove all photos and activity history.
        </ion-item>
      </ion-list>
      <ion-item lines="none">
        I understand that this action can't be undone.
        <ion-checkbox
          class="checkbox"
          mode="md"
          slot="start"
          [(ngModel)]="validateChecked"
        ></ion-checkbox>
      </ion-item>
      <ion-button
        *ngIf="!validateChecked"
        disabled
        class="action-button"
        color="light"
        slot="end"
        >Transfer</ion-button
      >
      <ion-button
        *ngIf="validateChecked"
        class="action-button btn-valid"
        color="light"
        slot="end"
        (click)="deviceTransfer()"
        >Transfer</ion-button
      >
    </div>

    <!-- CONFIRMATION PAGE-->
    <div *ngSwitchCase="'confirmation'">
      <ion-icon
        class="device-transfer-icon"
        slot="start"
        name="checkmark-circle"
      ></ion-icon>
      <div class="device-transfer-title">
        {{ deviceName }} settings transferred
      </div>
      <div class="device-transfer-label">New BRNKL serial number:</div>
      <div class="device-transfer-newDevice">
        {{ newDeviceId }}
      </div>
      <ion-button
        *ngIf="validateChecked"
        class="action-button btn-close"
        slot="end"
        (click)="onExitPressed()"
        >Close</ion-button
      >
    </div>
  </setup-container>
</ion-content>
