<ng-container *ngIf="device; else spacing">
  <setup-device-box
    [iconName]="device.iconName"
    [header]="device.companyName"
    [deviceName]="device.name"
  ></setup-device-box>
</ng-container>
<ng-template #spacing>
  <div class="setup-container_device-placeholder"></div>
</ng-template>
<div class="setup-container_header setup-info_header">
  <ion-icon
    *ngIf="topIcon != null"
    [class]="topIcon + ' topIonIcon'"
  ></ion-icon>
  {{ header }}
</div>
<div class="setup-container_text setup-container_bottom-space">
  {{ description }}
</div>
<ion-icon
  *ngIf="bottomIcon != null"
  [class]="bottomIcon + ' bottomIonIcon'"
></ion-icon>
<text-input
  *ngIf="header === 'Enter code' || header === 'Enter serial number'"
  [(ngModel)]="input"
  (ngModelChange)="handleInputValue($event)"
  [placeholder]="device.name === 'Mate' ? 'M8-XXXXXXXXXXXXXXXX' : ''"
></text-input>
<div class="setup-container_action-buttons">
  <div class="setup-container_btn-align">
    <ion-button
      fill="clear"
      class="setup-container_btn-action"
      (click)="next.emit()"
    >
      {{ nextButtonText }}
    </ion-button>
  </div>
  <div class="setup-container_btn-align">
    <ion-button
      *ngIf="optionButtonText"
      fill="clear"
      class="setup-container_btn-second-action"
      (click)="option.emit()"
    >
      {{ optionButtonText }}
    </ion-button>
  </div>
</div>
<div #scrollPoint class="setup-info_bottom"></div>
