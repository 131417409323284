import { Component, EventEmitter, ViewEncapsulation } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'base-input',
  templateUrl: './base-input.component.html',
  styleUrls: ['./base-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseInputComponent {
  class: string
  placeholder: string
  label?: string
  ngModel: string
  ngModelChange: EventEmitter<string>
  subscription: Subscription
  writeValue(obj: any): void {
    this.ngModel = obj
  }

  registerOnChange(fn: any): void {
    this.subscription = this.ngModelChange.subscribe(fn)
  }

  registerOnTouched() {}

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe()
  }
}
